import {Observable} from "rxjs";
import {ContentCoverage, ContentCoverageCrew} from "../models/coverage.model";
import {AppSettings} from "../app.settings";
import {Injectable} from "@angular/core";
import {CoreService} from "./core.service";
import {AuthenticationService} from "./authentication.service";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class CoverageService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('CoverageService Ready...');
  }

  getContentsCoverage(): Observable<ContentCoverage[]> {
    return this.http.get<ContentCoverage[]>(AppSettings.API_ENDPOINT + 'content/' + 'coverage', this.options);
  }

  getContentCoverage(contentID: any): Observable<ContentCoverage> {
    return this.http.get<ContentCoverage>(AppSettings.API_ENDPOINT + 'content/' + contentID + '/coverage', this.options);
  }

  getContentCoverageCrew(contentID: any): Observable<ContentCoverageCrew[]> {
    return this.http.get<ContentCoverageCrew[]>(AppSettings.API_ENDPOINT + 'content/' + contentID + '/coverage_by_crew', this.options);
  }

  getCoverageShift(shiftDate: any, shiftDefinitionID: number): Observable<ContentCoverageCrew[]> {
    const body = JSON.stringify({
      'shift_date': shiftDate,
      'shift_definition_id': shiftDefinitionID
    });
    return this.http.post<ContentCoverageCrew[]>(AppSettings.API_ENDPOINT + 'content/coverage_by_shift', body, this.options);
  }
}
