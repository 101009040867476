import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import { Session, SessionShiftRequest } from '../models/session.model';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class SessionService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('SessionService Ready...');
  }

  getSessions(): Observable<Session[]> {
    return this.http.get<Session[]>(AppSettings.API_ENDPOINT + 'session', this.options)
  }

  getSession(sessionID: number, groupID: number): Observable<Session> {
    return this.http.get<Session>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/' + groupID, this.options);
  }

  createSession(session: Session): Observable<Session> {
    return this.http.post<Session>(AppSettings.API_ENDPOINT + 'session', JSON.stringify(session), this.options);
  }

  updateSession(session: Session): Observable<Session> {
    return this.http.put<Session>(AppSettings.API_ENDPOINT + 'session/' + session.id, JSON.stringify(session), this.options);
  }

  updateSessionGroup(session: Session, group: number): Observable<Session> {
    return this.http.put<Session>(AppSettings.API_ENDPOINT + 'session/' + session.id, JSON.stringify(session), this.options);
  }

  deleteSession(sessionID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'session/' + sessionID, this.options);
  }

  checkSessionValidShift(request: SessionShiftRequest): Observable<any> {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'session/valid_shift', request);
  }

  getSessionAttendancePermission(sessionID: number, group: number): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/' + group + '/attendance_permission', this.options);
  }
}
