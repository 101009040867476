import {
    Component,
    ElementRef,
    HostListener, OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {Content, ContentPage} from '../../../models/content.model';
import {ContentService} from '../../../services/content.service';
import {SessionContentService} from '../../../services/session_content.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../services/session.service';
import {Session} from '../../../models/session.model';
import {DialogsService} from '../../../services/dialogs.service';
import {ContentFileService} from '../../../services/content_file.service';
import {ContentHidden} from '../../../models/content_hidden.model';
import {ContentLink} from '../../../models/content_link.model';
import {Link} from '../../../models/link.model';
import {ContentHiddenService} from '../../../services/content_hidden.service';
import {ContentLinkService} from '../../../services/content_link.service';
import {LinkService} from '../../../services/link.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SessionMetricService} from '../../../services/session_metric.service';
import {SessionMetric} from '../../../models/session_metric.model';
import {DatePipe} from '@angular/common';
import {Titlepage} from '../../../models/titlepage.model';
import {TitlepageService} from '../../../services/titlepage.service';
import {SessionWorkerService} from "../../../services/session_worker.service";
import {forkJoin, Observable, Subject} from "rxjs";
import {distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {SessionContent} from "../../../models/session_content.model";

@Component({
    selector: 'app-session-view',
    templateUrl: './session-view.component.html',
    styleUrls: ['./session-view.component.scss']
})
export class SessionViewComponent implements OnInit, OnDestroy {

    sessionID: number;
    session: Session;
    sessionMetric: SessionMetric = new SessionMetric();
    sessionGroup: number = null;
    content: Content;
    contentHiddens: ContentHidden[] = [];
    contentLinks: ContentLink[] = [];
    links: Link[] = [];
    pages: ContentPage[] = [];
    totalPageCount = 0;
    pageIdx = 0;
    aspectRatio = 16 / 9 - 1;
    pageWidth = 1280;
    fullscreenWidth = 1280;
    fullscreenHeight = 720;
    lowestPageIdx = 0;
    imgLoadComplete = false;
    imgPreloadComplete = false;
    hideFirstPage = true;   // Hides content until size of first slide determined in DOM
    viewEnd = false;
    resizeId: any;
    prevIdx = null;
    titlepage: Titlepage;
    loadingSlides: boolean;
    imageUnsubscribe: Subject<void> = new Subject<void>();

    @ViewChild('fullscreenIdentifier', {read: ElementRef}) fullscreenIdentifier: ElementRef;
    @ViewChild('slideIdentifier', {read: ElementRef}) slideIdentifier: ElementRef;

    @HostListener('document:keyup', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.handleKeyEvent(event);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        clearTimeout(this.resizeId);
        // Use timeout to prevent spamming the image generation algorithm (as no image already cached) on the server
        // when user slowly dragging window larger or smaller.
        this.resizeId = setTimeout(() => {
            this.getPageImages()
        }, 250);
    }

    constructor(private sessionContentService: SessionContentService,
                private sessionMetricService: SessionMetricService,
                private sessionWorkerService: SessionWorkerService,
                private contentService: ContentService,
                private contentFileService: ContentFileService,
                private contentHiddenService: ContentHiddenService,
                private contentLinkService: ContentLinkService,
                private linkService: LinkService,
                private sessionService: SessionService,
                private route: ActivatedRoute,
                private router: Router,
                private dialogsService: DialogsService,
                private domSanitizer: DomSanitizer,
                private datePipe: DatePipe,
                private titlepageService: TitlepageService
    ) {
    }

    ngOnInit() {
        this.loadingSlides = true;

        this.titlepageService.getTitlepages().subscribe(titlepages => {
            this.titlepage = titlepages.filter(t => t.state === 'Active')[0];
        });
        this.route.params.subscribe(params => {
            this.sessionID = params['id'];
            if (params.hasOwnProperty('group')) {
                this.sessionGroup = +params['group'];  // converts to number
            } else {
                this.sessionContentService.getSessionContentsBySession(this.sessionID).subscribe(sessionContent => {
                    this.sessionGroup = sessionContent[0].group;
                });
            }
            this.sessionMetric.is_preview = (<string>params['type']).toLocaleString().localeCompare('preview') > -1;
            this.sessionWorkerService.getSessionWorkersBySessionID(this.sessionID).subscribe(results => {
                if (results.length < 1 || this.sessionMetric.is_preview) {
                    this.sortSessionContents();
                } else if (results.length > 0) {
                    // Re-direct if attendance already recorded or session not viewed
                    this.router.navigate(['dashboard']).then();
                }
            });
        });
    }

    ngOnDestroy() {
        this.imageUnsubscribe.next(); // cancel requests
        this.imageUnsubscribe.complete(); // close subscription
    }

    sortSessionContents() {
        const sessionGroup = this.sessionGroup ? this.sessionGroup : 0
        this.sessionService.getSession(this.sessionID, sessionGroup).subscribe(session => {
            let sessionContentType: Observable<SessionContent[]>
            if (this.sessionGroup === null) {
                sessionContentType = this.sessionContentService.getSessionContentsBySession(this.sessionID)
            } else {
                sessionContentType = this.sessionContentService.getSessionContentsBySessionGroup(this.sessionID, this.sessionGroup)
            }
            sessionContentType.subscribe((sessionContents: SessionContent[]) => {
                if (sessionContents.length > 1) {
                    sessionContents.sort((a: SessionContent, b: SessionContent) => a.ordinal - b.ordinal);
                }
                // Find size of DOM and adjust content display size accordingly
                this.getPageSize();

                // Put all pages for all contents in this session into pages in order
                let pages: ContentPage[][] = [];
                let contentCount = 0;

                const contentObservables: Observable<Content>[] = [];
                // TODO Get hiddenContents and contentLinks by their content_id
                const hiddenContentObservables: Observable<ContentHidden[]> = this.contentHiddenService.getContentHiddens();
                const contentLinksObservables: Observable<ContentLink[]> = this.contentLinkService.getContentLinks();
                for (const sessionContent of sessionContents) {
                    if (sessionContent.group === this.sessionGroup) {
                        contentObservables.push(this.contentService.getContent(sessionContent.content_id))
                    }
                }

                forkJoin({
                    hiddenContent: hiddenContentObservables,
                    contentLinks: contentLinksObservables
                }).subscribe(contentData => {
                    const hiddenContent = contentData.hiddenContent
                    const contentLinks = contentData.contentLinks
                    forkJoin(contentObservables).subscribe(content => {
                        content.forEach(c => {
                            contentCount = contentCount + 1;
                            this.contentLinks = contentLinks.filter(cl => cl.content_id === c.id);
                            this.contentHiddens = hiddenContent.filter(ch => ch.content_id === c.id);

                            this.contentLinks.forEach((cl) => {
                                this.linkService.getLink(cl.link_id).subscribe(link => {
                                    this.links.push(link);
                                });
                            });

                            pages.push(ContentPage.getPages(this.contentLinks, c.id, c.content_file, this.pageWidth, this.contentHiddens));
                            pages.forEach((p) => {
                                p.forEach((i) => {
                                    this.pages.push(i);
                                });
                            });
                            pages = [];
                        });
                        this.initPageImageRequests();
                        this.setCurrentPageIdx(this.lowestPageIdx);
                        this.totalPageCount = this.pages.length;
                    })
                });

                if (session.titlepage_id) {
                    this.lowestPageIdx = -1;
                } else {
                    this.lowestPageIdx = 0;
                }
                this.prevIdx = this.lowestPageIdx - 1;
                this.session = session;

                // Session metrics
                // TODO: Find better way to assign actual user id to session metric
                this.sessionMetric.user_id = 1;
                this.sessionMetric.session_id = session.id;
                this.sessionMetric.group = this.sessionGroup;
                this.sessionMetric.sequence_number = 1;
                this.sessionMetric.start_time = new Date(Date.now());
                this.sessionMetric.run_count = 1;  // Default
                // Run count
                this.sessionMetricService.getSessionMetricLastRunCount(session.id, this.sessionGroup).subscribe(res => {
                    if (res) {
                        this.sessionMetric.run_count = res.last_run_count + 1;
                    }
                });
            });
        });
    }

    initPageImageRequests() {
        let pageNo = 1;
        for (const page of this.pages) {
            if (page.linkID === null) { // don't need to request image on page with link
                this.pageURL(pageNo - 1);
                if (pageNo === 1 && this.loadingSlides) {
                    // get first image so can get aspect ratio
                    this.contentFileService.getContentFilePageBlob(page.contentFile.id, pageNo, 'webp', this.pageWidth).pipe(
                        takeUntil(this.imageUnsubscribe)
                    ).subscribe({
                        next: (image) => this.createImageFromBlob(image, 0)
                    });
                }
                pageNo += 1;
            }
        }

        if (pageNo === 1) {
            // there is no images to be requested
            this.loadingSlides = false;
        }
    }

    getPageImages() {
        // Find size of DOM and adjust content display size accordingly
        this.getPageSize();
        if (this.pages) {
            for (const page of this.pages) {
                page.imageSubject.next(this.pageWidth);
            }
        }
    }

    // Set the URL used in [SRC]
    pageURL(pageIdx: number) {

        if (this.pages[pageIdx] === undefined) {
            return;
        }

        // The content is links only, dont render the link.
        if (this.pages[pageIdx].contentFile.id === null) {
            return;
        }

        if (this.isLink(pageIdx)) {
            return;
        }

        let contentFileID: number;
        let pageNumber: number;
        let imageFormat = 'webp';

        if (this.titlepage && this.session.titlepage_id && pageIdx < 0) {
            contentFileID = this.titlepage.content_file.id;
            pageNumber = 0;
        } else {
            contentFileID = this.pages[pageIdx].contentFile.id;
            pageNumber = (this.pages[pageIdx].page - this.pages[pageIdx].numberLinksPrior);
            imageFormat = this.pages[pageIdx].format;
        }

        if (contentFileID === undefined || pageNumber === undefined) {
            return;
        }

        // Render image from blob as data url
        this.pages[pageIdx].imageSubject.pipe(
            distinctUntilChanged(), // won't request again the subject contains same value
            switchMap(() => {
                return this.contentFileService.getContentFilePageBlob(this.pages[pageIdx].contentFile.id, pageNumber, imageFormat,
                    this.pageWidth);
            }),
            takeUntil(this.imageUnsubscribe) // will cancel all requests
        ).subscribe({
            next: (data: Blob) => this.createImageFromBlob(data, pageIdx)
        });

    }

    checkLink(idx: number): string {
        if (this.dataNotLoaded()) {
            return null;
        }

        if (this.isLink(idx)) {
            const links = this.links.filter(l => l.id === this.pages[idx].linkID);
            if (links.length < 1) {
                return null;
            }
            this.hideFirstPage = false;
            return SessionViewComponent.embedYoutube(links[0].link);
        } else {
            return null;
        }
    }

    isLink(idx: number): boolean {
        return this.dataNotLoaded() ? null : (idx >= 0) && this.pages[idx].linkID !== null;
    }

    notYoutubeVideo(url: string): boolean {
        const regex = new RegExp('youtube');
        return !regex.test(url);
    }

    static embedYoutube(url: string): string {

        let regex = new RegExp('youtube');
        if (regex.test(url)) {
            regex = new RegExp('watch\\?v=');
            url = url.replace(regex, 'embed/');
        }
        regex = new RegExp('\\?t=.*|&t=.*');
        if (regex.test(url)) {
            url = url.replace(regex, '');
        }
        return url;
    }

    getPreviousPageIdx() {
        return this.pageIdx - 1 >= this.lowestPageIdx ? this.pageIdx - 1 : null;
    }

    getNextPageIdx() {
        return this.pageIdx + 1 < this.pages.length ? this.pageIdx + 1 : null;
    }

    setCurrentPageIdx(idx: number) {
        this.pageIdx = idx;
    }

    handleKeyEvent($event): void {
        if (!this.dialogsService.openDialogs()) {
            switch ($event.key) {
                case 'ArrowLeft':
                    if (!this.loadingSlides) {
                        this.handleArrowLeft();
                        $event.preventDefault();
                    }
                    break;
                case 'ArrowRight':
                case ' ':
                    if (!this.loadingSlides) {
                        this.handleArrowRight();
                        $event.preventDefault();
                    }
                    break;
                case 'Escape':
                    this.handleEscape();
                    $event.preventDefault();
                    break;
            }
        }
    }

    handleArrowLeft() {
        if (!this.dialogsService.openDialogs()) {
            this.imgLoadComplete = false;
            this.imgPreloadComplete = false;
            if (this.getPreviousPageIdx() !== null && !this.viewEnd) {
                // Record session metric
                this.recordMetric();

                // Load previous page
                this.setCurrentPageIdx(this.getPreviousPageIdx());
            } else {
                this.viewEnd = false;
                // Record session metric time when returning to last page from the end
                this.sessionMetric.end_time = new Date(Date.now());
            }
        }
    }

    handleArrowRight() {
        if (!this.dialogsService.openDialogs()) {
            this.imgLoadComplete = false;
            this.imgPreloadComplete = false;
            if (this.pages.length > 0 && this.getNextPageIdx() == null) {
                // Record session metric
                if (!this.viewEnd) {
                    this.recordMetric();
                }

                // Inform user that session finished
                this.viewEnd = true;
            }

            if (this.getNextPageIdx() !== null) {
                if (this.session.is_editable) {
                    // Record session metric
                    this.recordMetric();
                }

                // Load next page
                this.setCurrentPageIdx(this.getNextPageIdx())
            }
        }
    }

    handleEscape() {
        // Allow escape without dialog if on last page or after (view end)
        if (this.pages.length > 0 && ((this.getNextPageIdx() == null && this.viewEnd) || (this.pageIdx + 1 === this.pages.length))) {
            if (!this.viewEnd) {
                // Record metric for last page if existed on last page.
                this.recordMetric();
            }

            // Finish session and record participation
            if (this.sessionMetric.is_preview) {
                this.router.navigate(['/session', 'library']).then();
            } else {
                if (this.sessionGroup === null) {
                    this.router.navigate(['/session', 'attendees', this.sessionID]).then();
                } else {
                    this.router.navigate(['/session', 'attendees', this.sessionID, this.sessionGroup]).then();
                }
            }

        } else {
            if (this.sessionMetric.is_preview) {
                this.router.navigate(['/session', 'library']);
            } else {
                this.openDialogConfirm('', 'Are you sure you would like to navigate away? Session is not complete.', ['/dashboard']);
            }
        }
    }

    recordMetric() {
        this.sessionMetric.end_time = new Date(Date.now());
        const duration = (this.sessionMetric.end_time.getTime() - this.sessionMetric.start_time.getTime()); // Milli-seconds

        const end_time = this.datePipe.transform(this.sessionMetric.end_time, 'yyyy-MM-dd HH:mm:ss');
        const start_time = this.datePipe.transform(this.sessionMetric.end_time, 'yyyy-MM-dd HH:mm:ss');

        const sessionMetric = new SessionMetric();
        if (this.session.titlepage_id !== null && this.pageIdx < 0) {
            sessionMetric.id = 0;
            sessionMetric.user_id = this.sessionMetric.user_id;
            sessionMetric.session_id = this.sessionMetric.session_id;
            sessionMetric.group = this.sessionMetric.group;
            sessionMetric.content_id = null;
            sessionMetric.link_id = null;
            sessionMetric.number_links_prior = 0;
            sessionMetric.content_page_number = 0;
            sessionMetric.session_page_number = this.pageIdx + 1;
            sessionMetric.sequence_number = this.sessionMetric.sequence_number;
            sessionMetric.start_time = start_time;
            sessionMetric.end_time = end_time;
            sessionMetric.duration = duration;
            sessionMetric.is_preview = this.sessionMetric.is_preview;
            sessionMetric.run_count = this.sessionMetric.run_count;
        } else {
            sessionMetric.id = 0;
            sessionMetric.user_id = this.sessionMetric.user_id;
            sessionMetric.session_id = this.sessionMetric.session_id;
            sessionMetric.group = this.sessionMetric.group;
            sessionMetric.content_id = this.pages[this.pageIdx].contentID;
            sessionMetric.link_id = this.pages[this.pageIdx].linkID;
            sessionMetric.number_links_prior = this.pages[this.pageIdx].numberLinksPrior;
            sessionMetric.content_page_number = this.pages[this.pageIdx].page;
            sessionMetric.session_page_number = this.pageIdx + 1;
            sessionMetric.sequence_number = this.sessionMetric.sequence_number;
            sessionMetric.start_time = start_time;
            sessionMetric.end_time = end_time;
            sessionMetric.duration = duration;
            sessionMetric.is_preview = this.sessionMetric.is_preview;
            sessionMetric.run_count = this.sessionMetric.run_count;
        }
        this.sessionMetricService.createSessionMetric(sessionMetric).subscribe({
            next: () => {
            }
        });
        this.sessionMetric.start_time = new Date(Date.now());
        this.sessionMetric.sequence_number++;
    }

    getPageSize() {
        // Prevent continually updating once page loaded
        if (this.fullscreenIdentifier) {

            // Get DOM width such that server returns full size slide images. Clamp as to not crop the slide off the screen.
            this.fullscreenWidth = this.fullscreenIdentifier.nativeElement.offsetWidth;
            this.fullscreenHeight = this.fullscreenIdentifier.nativeElement.offsetHeight;
            document.documentElement.style.setProperty('--video-width', this.fullscreenWidth.toString().concat('px'));
            document.documentElement.style.setProperty('--video-height', this.fullscreenHeight.toString().concat('px'));
            document.documentElement.style.setProperty('--video-offset-top', '0'.concat('px'));
            document.documentElement.style.setProperty('--video--offset-left', '0'.concat('px'));
            // Used to capture focus from iframe to prevent iframe capturing keyboard events and preventing navigation to next page
            const overlayTopHeight = this.fullscreenHeight / 2 - this.fullscreenHeight * 5 / 100;
            const overlayBotHeight = this.fullscreenHeight / 2 - this.fullscreenHeight * 5 / 100 - 60;
            const overlayBotOffset = 60;
            document.documentElement.style.setProperty('--video-ol-top-height', overlayTopHeight.toString().concat('px'));
            document.documentElement.style.setProperty('--video-ol-bot-height', overlayBotHeight.toString().concat('px'));
            document.documentElement.style.setProperty('--video-offset-bot', overlayBotOffset.toString().concat('px'));
        }

        // Aspect ratio assumed and corrects once slide loaded into DOM
        if (this.slideIdentifier) {
            this.aspectRatio = this.slideIdentifier.nativeElement.offsetWidth / this.slideIdentifier.nativeElement.offsetHeight;
        }

        this.setTopLeftOffset();
    }

    setTopLeftOffset() {
        let topOffset: number;
        let leftOffset: number;
        if (this.aspectRatio > 1) {
            // Dynamic offset to centre content when re-sized.
            this.pageWidth = this.clamp(this.fullscreenWidth, Math.floor(this.fullscreenHeight * this.aspectRatio));
            topOffset = (this.fullscreenHeight - this.pageWidth / this.aspectRatio) / 2;
            leftOffset = (this.fullscreenWidth - this.pageWidth) / 2;
        } else {
            // Dynamic offset to centre content when re-sized.
            this.pageWidth = this.clamp(Math.floor(this.fullscreenWidth / this.aspectRatio), this.fullscreenHeight);
            topOffset = (this.fullscreenHeight - this.pageWidth) / 2;
            leftOffset = (this.fullscreenWidth - this.pageWidth * this.aspectRatio) / 2;
        }

        document.documentElement.style.setProperty('--slide-offset-top', topOffset.toString().concat('px'));
        document.documentElement.style.setProperty('--slide-offset-left', leftOffset.toString().concat('px'));
    }

    loadComplete() {
        if (this.loadingSlides) {
            this.pages[0].image = null; // reset first image to null as have aspect ratio now
            this.getPageSize();
            this.getPageImages();
            this.loadingSlides = false;
        }
    }

    public openDialogConfirm(title: string = '', message: string = '', path: any[]) {

        let result: boolean;

        this.dialogsService
            .confirm(title, message)
            .subscribe(res => {
                result = res;
                if (result) {
                    this.router.navigate(path).then();
                }
            });
    }

    /** Clamps a number between zero and a maximum. */
    private clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }

    transform(url: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    // Converts an image blob to a data url
    createImageFromBlob(image: Blob, pageIdx: number) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.pages[pageIdx].image = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image)
        }
    }

    // Returns true if the data has not yet been loaded into variables
    dataNotLoaded(): boolean {
        return this.pages.length < 1 || (this.contentLinks.length > 0 && this.links.length < 1);
    }
}
