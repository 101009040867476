export class State {
  constructor(public name: string = '',
              public color: string = ''
  ) {
}
}

export const States: State[] = [
  { name: 'Approved', color: '#008000' },
  { name: 'Pending', color: '#FFA500' },
  { name: 'Rejected', color: '#FF0000' }
];
