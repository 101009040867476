import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {ContentWorker, SessionWorker, Worker} from '../models/worker.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class WorkerService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('WorkerService Ready...');
  }

  getWorkers(): Observable<Worker[]> {
    return this.http.get<Worker[]>(AppSettings.API_ENDPOINT + 'worker', this.options);
  }

  getWorker(workerID: number): Observable<Worker> {
    return this.http.get<Worker>(AppSettings.API_ENDPOINT + 'worker/' + workerID, this.options);
  }

  getContentWorkersByContentID(contentID: number): Observable<ContentWorker[]> {
    return this.http.get<ContentWorker[]>(AppSettings.API_ENDPOINT + 'content/' + contentID + '/worker', this.options);
  }

  getSessionWorkersBySessionID(sessionID: number, groupID: number): Observable<SessionWorker[]> {
    return this.http.get<SessionWorker[]>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/' + groupID + '/worker', this.options);
  }

  getAbsentSessionWorkersBySessionID(sessionID: number, groupID: number): Observable<ContentWorker[]> {
    return this.http.get<ContentWorker[]>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/' + groupID + '/absent');
  }

  createWorker(worker: Worker): Observable<Worker> {
    return this.http.post<Worker>(AppSettings.API_ENDPOINT + 'worker', JSON.stringify(worker), this.options);
  }

  updateWorker(worker: Worker): Observable<Worker> {
    return this.http.put<Worker>(AppSettings.API_ENDPOINT + 'worker/' + worker.id, JSON.stringify(worker), this.options);
  }

  deleteWorker(workerID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'worker/' + workerID, this.options);
  }
}
