<div class="fullScreen" #fullscreenIdentifier>
    <div class="-100 h-100 d-flex align-items-center justify-content-center">
        <ng-container *ngIf="viewEnd; else view">
            <div class="overlay bg-secondary d-flex align-items-center justify-content-center">
                <div class="text-container text-center text-white">
                    <h3 class="font-weight-normal">End of Session</h3>
                    <ng-container *ngIf="sessionMetric.is_preview; else notPreview">
                        <p>Press ESC to exit</p>
                    </ng-container>
                    <ng-template #notPreview>
                        <p>Press ESC to record attendance</p>
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <ng-template #view>
            <div *ngIf="sessionMetric.is_preview"
                 class="alert bg-danger d-flex align-items-center justify-content-center">THIS IS A SESSION PREVIEW
            </div>
            <ng-container *ngIf="pages.length > 0; else loading">
                <mat-chip-listbox *ngIf="pageIdx > -1 && !loadingSlides">
                    <mat-chip class="page-count">
                        <b>{{pageIdx + 1}}/{{totalPageCount}}</b>
                    </mat-chip>
                </mat-chip-listbox>
                <ng-container *ngIf="isLink(pageIdx) && pages[pageIdx].image === null; else notLink">
                    <ng-container *ngIf="notYoutubeVideo(checkLink(pageIdx)); else YoutubeVideo">
                        <h1><a [href]="transform(checkLink(pageIdx))" target="_blank">{{checkLink(pageIdx)}}</a></h1>
                    </ng-container>
                    <ng-template #YoutubeVideo>
                        <iframe class="video" [src]="transform(checkLink(pageIdx))">
                        </iframe>
                    </ng-template>
                </ng-container>
                <ng-template #notLink>
                    <div *ngIf="pages[pageIdx].image === null || loadingSlides;">
                        <mat-spinner></mat-spinner>
                    </div>
                    <img class="slide border-warning"
                         [ngStyle]="{'visibility': loadingSlides ? 'hidden' : 'visible'}" (load)="loadComplete()"
                         #slideIdentifier [src]='pages[pageIdx].image' alt="Content Missing">
                </ng-template>
            </ng-container>
            <ng-template #loading>
                <mat-spinner></mat-spinner>
            </ng-template>
        </ng-template>
        <button class="button-left" mat-fab color="primary" [ngClass]="{ button: getPreviousPageIdx() !== null }"
                (click)="handleArrowLeft()" [hidden]="loadingSlides" [disabled]="getPreviousPageIdx() === null">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button class="button-right" mat-fab color="primary" [ngClass]="{ button: getNextPageIdx() !== null }"
                (click)="handleArrowRight()" [hidden]="loadingSlides">
            <mat-icon class="icon">chevron_right</mat-icon>
        </button>
        <ng-container *ngIf="!notYoutubeVideo(checkLink(pageIdx))">
            <div class="video-overlay-top"></div>
            <div class="video-overlay-bottom"></div>
        </ng-container>
    </div>
</div>
