import {Observable} from "rxjs";
import {AppSettings} from "../app.settings";
import {Injectable} from "@angular/core";
import {CoreService} from "./core.service";
import {AuthenticationService} from "./authentication.service";
import {HttpClient} from "@angular/common/http";
import {DashboardSession, DashboardStatistics} from "../models/dashboard.model";
import {ContentSchedule} from "../models/content.model";


@Injectable()
export class DashboardService extends CoreService {

  contentIDs: ContentSchedule[] = []

  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('DashboardService Ready...');
  }

  public getDashboardSessions(userDatetime: any): Observable<DashboardSession[]> {
    return this.http.post<DashboardSession[]>(AppSettings.API_ENDPOINT + 'dashboard/sessions', JSON.stringify(userDatetime));
  }

  public getDashboardStatistics(time_period: string): Observable<DashboardStatistics> {
    return this.http.post<DashboardStatistics>(AppSettings.API_ENDPOINT + 'dashboard/statistics', JSON.stringify(time_period));
  }
}
