// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {AppSettings} from '../app/app.settings';
import packageJson from '../../package.json'

export const baseUrl = AppSettings.API_ENDPOINT;

export const environment = {
    production: false,
    httpSettings: {
        retryEnabled: false,
        maxNumberTriesBeforeFail: 3,
        retryInterval: 2000,
        concurrentImageRequests: 3,
        cacheRetryInterval: 3000,
        maxUploadSize: 250000000,
        maxInitialImageRequests: 30,
        pingInterval: 5000
    },
    httpEndpoints: {
        base: baseUrl,
        content: baseUrl + 'content',
        content_file: baseUrl + 'content_file',
    },
    defaultTimezone: 'Australia/Sydney',
    version: packageJson.version
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
