import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ContentService } from '../../../services/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Log } from '../../../helpers/log.helper';
import { Content } from '../../../models/content.model';
import { WorkerService } from '../../../services/worker.service';
import { Crew } from '../../../models/crew.model';
import { MatInput } from '@angular/material/input';
import {CrewService} from '../../../services/crew.service';
import { ContentWorker, Worker } from '../../../models/worker.model';
import { DeliveryService } from '../../../services/delivery.service';
import { Delivery } from '../../../models/delivery.model';
import { CoverageService } from '../../../services/coverage.service';
import { ContentCoverageCrew } from '../../../models/coverage.model';

@Component({
  selector: 'app-review-content-attendees',
  templateUrl: './review-content-attendees.component.html',
  styleUrls: ['./review-content-attendees.component.scss']
})

export class ReviewContentAttendeesComponent implements OnInit {


  contentID: any;
  content: Content;
  compulsoryContent: Content[];
  contentWorkers: ContentWorker[];
  attendees: Worker[] = [];
  absentees: Worker[] = [];
  attendeeSelect: boolean = null;
  attendedFilter: any = null;
  workers: Worker[] = [];
  workerSearchTerm = '';
  filter = '';
  crews: Crew[] = [];
  crewsFiltered: Crew[] = [];
  mainCrews: Crew[] = [];
  supportCrews: Crew[] = [];
  selectedCrewID = 0; // All crews selected
  @ViewChild('numberMatInput', { read: MatInput })
  crewMatInput: MatInput;
  workersOrder: any[] = [];
  contentCoverageCrews: ContentCoverageCrew[] = [];
  deliverys: Delivery[] = [];

  constructor(private coverageService: CoverageService,
              private contentService: ContentService,
              private workerService: WorkerService,
              private crewService: CrewService,
              private deliveryService: DeliveryService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.contentID = params['id'];

      if (this.contentID === 'compulsory') {
        this.contentService.getCompulsoryContent(this.contentID).subscribe(content => {
          this.compulsoryContent = content;
        });
      } else {
        this.contentService.getContent(this.contentID).subscribe(content => {
          this.content = content;
        });
      }

      this.workerService.getWorkers().subscribe(workers => {
        this.workers = workers.filter(w => !!w.enabled === true);
        this.crewService.getCrews().subscribe(crews => {
          this.crews = crews;
          this.crewsFiltered = this.crews;
          this.mainCrews = crews.filter(c => c.priority === 1);
          this.supportCrews = crews.filter(c => c.priority !== 1);
          this.workerService.getContentWorkersByContentID(this.contentID).subscribe(contentWorkers => {
            this.contentWorkers = contentWorkers.sort((a, b) => (a.attended < b.attended ? -1 : a.attended > b.attended ? 1 : 0) ||
              (a.worker_surname.concat(a.worker_first_name).localeCompare(b.worker_surname.concat(b.worker_first_name))));
            if (contentWorkers) {
              // Map the attendance and worker_id fields to arrays and remove the duplicates with filter
              this.workersOrder = this.contentWorkers.map(item => {
                let container: any = new Array(this.contentWorkers.length);

                container = item.worker_id;

                return container
              });
            }
            this.deliveryService.getDeliverys().subscribe(deliverys => {
              this.deliverys = deliverys;
              this.coverageService.getContentCoverageCrew(this.contentID).subscribe(contentCoverageCrews => {
                this.contentCoverageCrews = contentCoverageCrews;
              });
            });
          });
        });
      });
    });
  }

  attended(workerID: number): boolean {
    const contentWorker = this.contentWorkers.filter(cw => cw.worker_id === workerID)[0];
    return contentWorker.attended;
  }

  workerInCrew(workerID: number, crewID: number): boolean {
    if (this.contentWorkers) {
      const contentWorker = this.contentWorkers.filter(cw => cw.worker_id === workerID)[0];
      if (contentWorker) {
        return contentWorker.crew_id === crewID;
      }
    }
    return false;
  }

  workersTypeAhead(): Worker[] {
    let workers = this.workers;
    if (this.selectedCrewID > 0) {
      workers = workers.filter(a => a.crew_id === this.selectedCrewID);
    }
    if (this.attendedFilter !== null) {
      workers = workers.filter(w => this.attended(w.id) === this.attendedFilter)
    }

    workers = Worker.typeAhead(workers, this.workerSearchTerm);

    if (this.contentWorkers && this.workersOrder) {
      const order = this.workersOrder;
      workers.sort(function(a, b) {
        return order.indexOf(a.id) - order.indexOf(b.id);
      });
    }

    return workers
  }

  updateFilter(crewID: number) {
    if (!crewID) {
      this.filter = null;
      this.crewsFiltered = this.crews;
    } else {
      this.filter = this.crews.filter(c => c.id === crewID)[0].abbrev;
      this.crewsFiltered = this.crews.filter(c => c.id === crewID);
    }
    this.selectedCrewID = crewID;
  }

  updateAttendeesFilter() {
    if (this.attendeeSelect) {
      this.attendedFilter = 1;
    } else if (this.attendeeSelect === false) {
      this.attendedFilter = 0;
    } else {
      this.attendedFilter = null;
    }
  }

  crewHasWorkers(crew: Crew) {
    let crewWorkers = []
    if (this.attendedFilter !== null) {
      crewWorkers = this.workers.filter(w => w.crew_id === crew.id && this.attended(w.id) === this.attendedFilter)
    } else {
      crewWorkers = this.workers.filter(w => w.crew_id === crew.id)
    }
    return crewWorkers.length > 0
  }

  getCoverage(ID: number): number {
    // Attendance not applicable to un-approved content or content flagged as anytime
    if (!this.contentCoverageCrews || this.contentCoverageCrews.length < 1 || this.contentCoverageCrews.filter(cc => cc.crew_id === ID).length < 1) {
      return null;
    }
    return this.contentCoverageCrews.filter(cc => cc.crew_id === ID)[0]
      .attendance_percent_by_required;
  }

  checkCoverageApplicable() {
    if (this.content && this.deliverys.length > 0) {
      return this.content.state === 'Approved';
    } else if (this.router.url === '/content/attendees/compulsory') {
      return true;
    }
    return false;
  }

  checkCoverage(ID: number): boolean {
    if (!this.contentCoverageCrews || this.contentCoverageCrews.length < 1) {
      return false;
    }
    return this.getCoverage(ID) === 100.0;
  }
}
