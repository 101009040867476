export class PrestartType {
  constructor(public id: any = null,
              public name: string = '',
              public window_after: number = 0,
              public window_before: number = 0,
              public start_offset: number = 0,
              public is_active: boolean = false,
              public ordinal: number = 0,
              public is_editable: boolean = false
              ) {}

  public static assign(pt: PrestartType) {
    const n = Object.assign(new PrestartType(), pt);

    return n;
  }
}
