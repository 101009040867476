<div class="container">
    <div class="row d-flex align-items-center justify-content-between">
        <h5>Access Management</h5>
        <button mat-flat-button color="accent" (click)="navigate(true)">Add New User</button>
    </div>
    <div class="col-12 p-0">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
                <td mat-cell *matCellDef="let user"> {{user.name}} </td>
            </ng-container>

            <ng-container matColumnDef="uname">
              <ng-container *ngIf = "integrated; else notIntegrated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
                <td mat-cell *matCellDef="let user"> {{user.remote_system_identity}} </td>
              </ng-container>
              <ng-template #notIntegrated>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let user"> {{user.email}} </td>
              </ng-template>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let user"> {{getStatus(user.id)}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User Role </th>
                <td mat-cell *matCellDef="let user"> {{user.role}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="text-right"> Actions </th>
                <td mat-cell *matCellDef="let user" class="actions text-right">
                    <button mat-stroked-button (click)="navigate(false, user.id)">Edit</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>

</div>
