import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {ContentLink} from '../models/content_link.model';
import {HttpClient} from '@angular/common/http';
import {SessionContent} from '../models/session_content.model';

@Injectable()
export class ContentLinkService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('ContentLinkService Ready...');
  }

  getContentLinks(): Observable<ContentLink[]> {
    return this.http.get<ContentLink[]>(AppSettings.API_ENDPOINT + 'content_link', this.options);
  }

  getContentLink(contentLinkID: number): Observable<ContentLink> {
    return this.http.get<ContentLink>(AppSettings.API_ENDPOINT + 'content_link/' + contentLinkID, this.options);
  }

  createContentLink(contentLink: ContentLink): Observable<ContentLink> {
    return this.http.post<ContentLink>(AppSettings.API_ENDPOINT + 'content_link', JSON.stringify(contentLink), this.options);
  }

  updateContentLink(contentLink: ContentLink): Observable<ContentLink> {
    return this.http.put<ContentLink>(AppSettings.API_ENDPOINT + 'content_link/' + contentLink.id, JSON.stringify(contentLink), this.options);
  }

  deleteContentLink(contentLinkID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'content_link/' + contentLinkID, this.options);
  }
}
