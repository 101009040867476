export class Shift {
  constructor(public id: any = null,
              public shift_definition_id: any = null,
              public shift_date: any = null
              ) {}

  public static assign(s: Shift) {
    const n = Object.assign(new Shift(), s);

    return n;
  }
}