import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {SessionWorker} from "../models/session_worker.model";
import {Worker} from "../models/worker.model";

@Injectable()
export class IntegrationService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('IntegrationService Ready...');
  }

  getAttendance(sessionID: number, groupID: number): Observable<SessionWorker[]> {
    const params = groupID ? new HttpParams().set('group', String(groupID)) : {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    }
    return this.http.get<SessionWorker[]>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/attendance_integration', httpOptions);
  }

  getLoggedIn(): Observable<Worker[]> {
    return this.http.get<Worker[]>(AppSettings.API_ENDPOINT + 'logged_integration', this.options);
  }
}
