import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionMetric, SessionMetricContentDuration, SessionMetricOverviewTimes} from '../../../models/session_metric.model';
import {SessionMetricService} from '../../../services/session_metric.service';
import {SessionContentService} from '../../../services/session_content.service';
import {ContentFileService} from '../../../services/content_file.service';
import {ContentLinkService} from '../../../services/content_link.service';
import {SessionContent} from '../../../models/session_content.model';
import {Content} from '../../../models/content.model';
import {ContentFile} from '../../../models/content_file.model';
import {LinkService} from '../../../services/link.service';
import {ContentLink} from '../../../models/content_link.model';
import {Link} from '../../../models/link.model';
import {UserService} from '../../../services/user.service';
import {UserName} from '../../../models/user.model';
import {Session, SessionRun} from '../../../models/session.model';
import {SessionService} from '../../../services/session.service';
import {ShiftDefinitionService} from '../../../services/shift_definition.service';
import {ShiftDefinition} from '../../../models/shift_definition.model';
import {AppSettings} from '../../../app.settings';
import {ContentService} from '../../../services/content.service';
import {DeliveryService} from '../../../services/delivery.service';
import {Delivery} from '../../../models/delivery.model';
import {Log} from '../../../helpers/log.helper';
import {Titlepage} from '../../../models/titlepage.model';
import {TitlepageService} from '../../../services/titlepage.service';
import {AuthenticationService} from "../../../services/authentication.service";
import {WorkerService} from "../../../services/worker.service";
import {SessionWorker, Worker} from "../../../models/worker.model";
import {DatePipe} from "@angular/common";
import {Crew} from "../../../models/crew.model";
import {CrewService} from "../../../services/crew.service";

@Component({
  selector: 'app-session-metrics',
  templateUrl: './session-metrics.component.html',
  styleUrls: ['./session-metrics.component.scss']
})
export class SessionMetricsComponent implements OnInit {

  sessionID: number;
  session: Session;
  shiftDefinitions: ShiftDefinition[] = [];
  sessionMetrics: SessionMetric[] = [];
  sessionCompleteMetrics: SessionMetric[] = [];
  sessionContents: SessionContent[] = [];
  slides: Content[] = [];
  contentFiles: ContentFile[] = [];
  contentLinks: ContentLink[] = [];
  links: Link[] = [];
  userName: UserName = new UserName();
  userNames: UserName[] = [];
  deliverys: Delivery[] = [];
  groupNumbersObj: any[] = [];
  overviewTimes: SessionMetricOverviewTimes;
  groupNumber: number;
  pageWidth = 800;
  sessionMetricContentDurations: SessionMetricContentDuration[] = [];
  titlepage: Titlepage;
  sessionWorkers: SessionWorker[] = [];
  filter = null;
  crews: Crew[] = [];
  crewsFiltered: Crew[] = [];
  mainCrews: Crew[] = [];
  supportCrews: Crew[] = [];
  selectedCrewID = 0; // All crews selected
  renderedImage: any = []; //  Thumbnail
  isImageLoading: boolean = false;
  renderedTitlepageImage: any;
  renderedSlideFirstPageImage: any = [];

  constructor(private sessionService: SessionService,
              private shiftDefinitionService: ShiftDefinitionService,
              private sessionMetricService: SessionMetricService,
              private sessionContentService: SessionContentService,
              private contentService: ContentService,
              private contentFileService: ContentFileService,
              private contentLinkService: ContentLinkService,
              private linkService: LinkService,
              private deliveryService: DeliveryService,
              private userService: UserService,
              private crewService: CrewService,
              private route: ActivatedRoute,
              private router: Router,
              private titlepageService: TitlepageService,
              private workerService: WorkerService,
              private authService: AuthenticationService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.groupNumber = 0;

    this.crewService.getCrews().subscribe(crews => {
      this.crews = crews;
      this.crewsFiltered = this.crews;
      this.mainCrews = crews.filter(c => c.priority === 1);
      this.supportCrews = crews.filter(c => c.priority !== 1);
    });

    this.route.params.subscribe(params => {
      this.sessionID = params['id'];

      this.sessionService.getSession(this.sessionID, 0).subscribe(session => {
        this.session = session;
        this.titlepageService.getTitlepages().subscribe(titlepages => {
          this.titlepage = titlepages.filter(t => t.id === session.titlepage_id)[0];
        });

        this.sessionMetricService.getSessionMetrics().subscribe(sessionMetrics => {
          this.sessionCompleteMetrics = sessionMetrics.filter(sm => sm.session_id == this.sessionID);
          this.sessionMetrics = this.sessionCompleteMetrics;
          // Map the run count field to an array and remove the duplicates with filter
          this.groupNumbersObj = this.sessionMetrics.map(item => {
            const container: any = {};

            container.is_preview = item.is_preview;
            container.run_count = item.run_count;

            return container
          }).filter((item, i, arr) => arr.findIndex(rc => rc.run_count.valueOf() === item.run_count.valueOf()) === i);

          this.workerService.getSessionWorkersBySessionID(this.sessionID, this.groupNumber).subscribe(sessionWorkers => {
            this.sessionWorkers = sessionWorkers.sort((a, b) => (a.attended > b.attended ? -1 : a.attended < b.attended ? 1 : 0) ||
              (a.worker_surname.concat(a.worker_first_name).localeCompare(b.worker_surname.concat(b.worker_first_name))));
          });

          // Use shown session by default
          if (this.sessionMetrics) {
            if (this.groupNumbersObj.filter(rc => rc.is_preview === 0).length > 0) {
              this.updateSessionMetric(this.groupNumbersObj.filter(rc => rc.is_preview === 0)[0].run_count);
            } else if (session) {
              this.updateSessionMetric(1);
            }
          }
          this.sessionContentService.getSessionContents().subscribe(sessionContents => {
            this.sessionContents = sessionContents.filter(sc => sc.session_id == this.sessionID);
            if (this.sessionContents.length > 1) {
              this.sessionContents.sort((a, b) => a.ordinal - b.ordinal);
            }


            this.contentService.getContents().subscribe((contents: Content[]) => {
              this.contentLinkService.getContentLinks().subscribe(contentLinks => {
                this.linkService.getLinks().subscribe(links => {

                  this.sessionContents.forEach((sc) => {
                    const content = contents.filter(c => c.id === sc.content_id)[0]
                    content.group = sc.group
                    this.slides.push(content);
                    this.contentLinks = contentLinks.filter(cl => cl.content_id === sc.content_id);

                    this.contentLinks.forEach(cl => {
                      this.links.push(links.filter(l => l.id === cl.link_id)[0]);
                    });
                  });

                  this.renderedImage = new Array(sessionMetrics.length);
                  for(let i = 0; i < this.sessionMetrics.length; i++){
                    this.pageURL(i, this.pageWidth);
                  }

                  this.renderedSlideFirstPageImage = new Array(this.slides.length);
                  this.slides.forEach((s, i) => {
                    this.slideFirstPage(s.content_file, i);
                  });

                });
              });
            });
          });
        });
      });


      this.deliveryService.getDeliverys().subscribe(deliverys => {
        this.deliverys = deliverys;
      });
      this.shiftDefinitionService.getShiftDefinitions().subscribe(shiftDefinitions => {
        this.shiftDefinitions = shiftDefinitions;
      })
    });
  }

  approvedDate(ID: number): string {
    return this.datePipe.transform(this.slides.filter(s => s.id === ID)[0].approver_date, 'yyyy-MM-dd');;
  }

  getUser(ID: number): string {
    return this.userNames.filter(u => u.id === ID)[0].name;
  }

  updateSessionMetric(groupNumber: number) {
    this.groupNumber = groupNumber;
    if (this.sessionMetrics.length > 0 && groupNumber > 0) {

      this.userService.getUserNames().subscribe(userNames => {
        this.userNames = userNames;
        if (this.sessionMetrics.length > 0) {
          this.userName = userNames.filter(u => u.id === this.sessionMetrics[groupNumber].user_id)[0];
        }
      });
      this.workerService.getSessionWorkersBySessionID(this.sessionID, this.groupNumber - 1).subscribe(sessionWorkers => {
        this.sessionWorkers = sessionWorkers.sort((a, b) => (a.attended > b.attended ? -1 : a.attended < b.attended ? 1 : 0) ||
          (a.worker_surname.concat(a.worker_first_name).localeCompare(b.worker_surname.concat(b.worker_first_name))));
      });
      if (this.session) {
        this.sessionMetricService.getSessionMetricOverviewTimes(new SessionRun(this.session.id, null, groupNumber)).subscribe(overviewTimes => {
          this.overviewTimes = overviewTimes;
        });
        this.sessionMetricService.getSessionMetricContentsDuration(new SessionRun(this.session.id, null, groupNumber)).subscribe(sessionMetricContentDurations => {
          this.sessionMetricContentDurations = sessionMetricContentDurations;
        });
      }
      this.sessionMetrics = this.sessionCompleteMetrics.filter(sm => sm.run_count === groupNumber);
    }
  }

  // Set the URL used in [SRC]
  pageURL(idx: number, pageWidth: number): boolean {

    let contentFileID: number;
    let pageNumber: number;
    const imageFormat = 'webp';
    const size: number = pageWidth;
    const slide = this.slides.filter(s => s.id === this.sessionMetrics[idx].content_id);

    if (this.sessionMetrics.length < 1) {
      return false;
    }
    else if (this.sessionMetrics[idx].content_id === null) {
      contentFileID = this.session.titlepage_id;
      pageNumber = 0;
    }
    else if (slide.length < 1) {
      return false;
    }
    else if (this.sessionMetrics[idx].link_id === null) {
      contentFileID = slide[0].content_file_id;
      pageNumber = (this.sessionMetrics[idx].content_page_number - this.sessionMetrics[idx].number_links_prior);
    }

    if (contentFileID === undefined || pageNumber === undefined) {
        this.renderedImage[idx] = null; // set to null if we have a link
        return false;
    }

    if(this.renderedImage[idx] !== undefined){
      return true
    }

    // Render image from blob as data url
    this.isImageLoading = true;
    this.contentFileService.getContentFilePageBlob(contentFileID,  pageNumber, imageFormat, size).subscribe(data => {
        this.createImageFromBlob(data, 'SidebarRight', idx);
        this.isImageLoading = false;
      }, error => {
       this.isImageLoading = false;
       console.log(error)
     } );

    return true;

  }

  getDuration(idx: number, getContentDuration: boolean = false): number {
    if (getContentDuration) {
      return this.sessionMetricContentDurations.filter(smcd => smcd.content_id === this.sessionMetrics[idx].content_id)[0].duration;
    }
    return this.sessionMetrics[idx].duration;
  }

  getShift(): string {
    if (!this.shiftDefinitions || this.shiftDefinitions.length < 1) {
      return null;
    }
    return this.shiftDefinitions.filter(sd => sd.id === this.session.shift_definition_id)[0].name;
  }

  isPrestart(): boolean {
    return !!this.session.shift_date;
  }

  getTitlepage(): boolean {
    if(!this.renderedTitlepageImage){
      this.contentFileService.getContentFilePageBlob(this.titlepage.content_file.id,  0, 'webp', 800).subscribe(data => {
        this.createImageFromBlob(data, 'Titlepage', 0);
        this.isImageLoading = false;
      }, error => {
       this.isImageLoading = false;
       console.log(error)
     } );
    }
    return true;
  }

// Used for content preview
  slideFirstPage(contentFile: ContentFile, idx: number): boolean {

    if (!contentFile || contentFile.page_count < 1) {
      return false;
    }

    if(this.renderedSlideFirstPageImage[this.slides.findIndex(slide => slide.content_file === contentFile)] !== undefined){
      return true;
    }

    this.contentFileService.getContentFilePageBlob(contentFile.id,  1, 'webp', 800).subscribe(data => {
      this.createImageFromBlob(data, 'SlideFirstPage', idx);
      this.isImageLoading = false;
    }, error => {
     this.isImageLoading = false;
      console.log(error)
    } );



    return true;
  }

  getFilename(contentFile: ContentFile): string {
    return contentFile.filename;
  }

  contentDelivery(deliveryID: number): Delivery {
    return this.deliverys.filter(d => d.id === deliveryID)[0];
  }

  getHours(MilliSeconds: number): number {
    return Math.floor((MilliSeconds / 1000.0) / 3600.0);
  }

  getMinutes(MilliSeconds: number): number {
    return Math.floor((MilliSeconds / 1000.0) / 60.0) % 60.0;
  }

  getSeconds(MilliSeconds: number): number {
    return Math.round((MilliSeconds / 1000.0) % 60.0);
  }

  getContentTitle(contentID: number): string {
    const content = this.slides.filter(s => s.id === contentID);
    if (content.length < 1) {
      return null;
    }
    return content[0].title;
  }

  getLinkName(linkID: number): string {
    const link = this.links.filter(l => l.id === linkID);
    if (link.length < 1) {
      return null;
    }
    return link[0].name;
  }

  getLinkURL(linkID: number): string {
    const link = this.links.filter(l => l.id === linkID);
    if (link.length < 1) {
      return null;
    }
    return link[0].link;
  }

  sessionRun(groupNumber: number): string {
    if (!this.sessionMetrics || this.sessionMetrics.length < 1) {
      return null;
    }
    if (!!this.sessionMetrics.filter(sm => sm.run_count === groupNumber)[0].is_preview) {
      return 'Preview'
    } else {
      return 'Presentation'
    }
  }

  attended(workerID: number): boolean {
    const sessionWorker = this.sessionWorkers.filter(sw => sw.worker_id === workerID)[0];
    return sessionWorker.attended;
  }

  navigate(partialPath: string, type: string = '') {
    this.router.navigate(['/session/', partialPath, this.session.id, type]);
  }

  updateFilter(crewID: number) {
    this.selectedCrewID = crewID;
  }

  sessionWorkersList(): SessionWorker[] {
    let sessionWorkers = this.sessionWorkers;
    if (this.selectedCrewID > 0) {
      sessionWorkers = sessionWorkers.filter(sw => sw.crew_id === this.selectedCrewID);
    }
    return sessionWorkers;
  }

  // Converts an image blob to a data url
  createImageFromBlob(image: Blob, pushTo: string, idx: number){

    let reader = new FileReader();
    reader.addEventListener('load', () => {
      if(pushTo === 'SidebarRight'){
        this.renderedImage[idx] = reader.result;
      } else if (pushTo === 'Titlepage'){
        this.renderedTitlepageImage = reader.result;
      } else if (pushTo === 'SlideFirstPage'){
        this.renderedSlideFirstPageImage[idx] = reader.result;
      }

    }, false);

    if(image){
      reader.readAsDataURL(image)
    }
  }

isNewGroup(index: number) {
    if (typeof this.slides[index - 1] !== 'undefined') {
      return this.slides[index].group !== this.slides[index - 1].group;
    }
  }
}
