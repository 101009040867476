export class AppSettings {

  public static get SERVER_ENDPOINT(): string {
   return 'https://demo.smartprestart.com';
  }

  // MUST end in a slash/
  public static get API_ENDPOINT(): string {
    return AppSettings.SERVER_ENDPOINT + '/api/';
  }

  public static get AUTH_LOCAL_STORAGE_TOKEN_KEY(): string {
    return 'token';
  }

  public static get AUTH_LOCAL_STORAGE_LOGGED_IN_KEY(): string {
    return 'logged_in';
  }

  public static get AUTH_HEADER_SCHEME(): string {
    return 'JWT';
  }

  /** Authentication that is integrated into the web-server so doesn't need manual logins, eg. NTLM or similar */
  public static get INTEGRATED_AUTHENTICATION(): boolean {
    return false;
  }

  public static get IS_PREMIUM(): boolean {
    return true;  // Premium or Basic
  }

  public static get SITE_ACRONYM(): string {
    return 'DEVFU-DEMO';  // Site initials/acronym
  }

  public static get IS_INTEGRATED_SIGNIN(): boolean {
    return false;  // True if used within lineup of other integration that provides signed-in workers
  }

  public static get LINEUP_ENABLED(): boolean {
    return true;
  }

  public static get LINEUP_SYSTEM_ROUTE(): string {
    return 'http://demo-prod.lineupboard.com';  // Route to integrated attendance system
  }
}
