<app-file-upload-target [isOnContentCreate]="true" [(currentContent)]="content" [(currentFile)]="file"
                        (currentFileChange)="dragAndDropFileChange()"></app-file-upload-target>
<div class="container">
    <div *ngIf="!hasUploadStarted;else uploadStarted">
        <div class="row d-flex align-items-center">
            <div class="col-3 p-0">
                <ng-container *ngIf="viewType ===  'edit'; else notEditLabel">
                    <h5 class="ml-auto">Edit Content</h5>
                </ng-container>
                <ng-template #notEditLabel>
                    <h5 class="ml-auto">Add Content</h5>
                </ng-template>
            </div>
            <div class="col-9 p-0 text-right">
        <span class="text-danger" [hidden]="!checkCreateInvalid()">
            <span class="mx-2" *ngIf="!content || !content.title">*Content title is required</span>
            <span class="mx-2"
                  *ngIf="(contentType == contentTypes.contentLinks || contentType == contentTypes.contentOnly) && !file && (viewType !==  'edit' || (content && !content.content_file))">*PDF
              is required</span>
            <span class="mx-2"
                  *ngIf="(contentType == contentTypes.contentLinks || contentType == contentTypes.linksOnly) && links.length < 1">*Link
              is required</span>
            </span>
                <ng-container *ngIf="content && !content.archive_date">
                    <ng-container *ngIf="viewType ===  'edit'; else notEditButtons">
                        <button mat-flat-button color="accent" [disabled]="checkCreateInvalid()"
                                (click)="keyPressed = true; saveContent()">Save
                        </button>
                        <ng-container *ngIf="!content || content.is_editable;">
                            <button mat-stroked-button (click)="onDelete()">Delete</button>
                        </ng-container>
                    </ng-container>
                    <ng-template #notEditButtons>
                        <button mat-flat-button color="accent" [disabled]="checkCreateInvalid()"
                                (click)="keyPressed = true; saveContent()">Create
                        </button>
                    </ng-template>
                </ng-container>
                <button mat-stroked-button (click)="navigateBack()">Back</button>
            </div>
        </div>

        <ng-container *ngIf="viewType ===  'edit' && !contentTypeChanged">
            <div class="row">
                <div class="card col-12" *ngIf="content" [ngClass]="content.state == 'Approved' ? 'border-success' :
                (content.state == 'Rejected' ? 'border-danger' : 'border-warning')">
                    <div class="card-body d-flex justify-content-between align-items-center">
                        <div class="mr-auto">
                            <div class="d-flex flex-row">
                                <div class="pr-3">
                                    <p>
                                        <small>Current Status</small>
                                    </p>
                                    <small>
                                        <b>{{content.state}}</b>
                                        <b *ngIf="content.archive_date"> - Archived</b>
                                    </small>
                                </div>
                                <div class="px-3" *ngIf="content.state == 'Approved'">
                                    <p>
                                        <small>Approved</small>
                                    </p>
                                    <small>
                                        <b>{{content.approver_date}}</b>
                                    </small>
                                </div>
                                <div class="px-3" *ngIf="content.state == 'Approved'">
                                    <p>
                                        <small>Approved By</small>
                                    </p>
                                    <small>
                                        <b>{{getUser(content.approver_id)}}</b>
                                    </small>
                                </div>
                                <div class="px-3" *ngIf="content.archive_date">
                                    <p>
                                        <small>Archived</small>
                                    </p>
                                    <small>
                                        <b>{{content.archive_date}}</b>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="ml-auto">
                            <div class="button-row">
                                <ng-container *ngIf="content && !content.archive_date && content.is_editable">
                                    <button mat-stroked-button *ngIf="content && content.state !== 'Rejected'"
                                            (click)="changeState('Rejected')"
                                            class="text-danger">Reject
                                    </button>
                                    <button mat-stroked-button *ngIf="content && content.state !== 'Pending'"
                                            (click)="changeState('Pending')" class="text-info">Revert to Pending
                                    </button>
                                    <button mat-stroked-button *ngIf="content && content.state !== 'Approved'"
                                            (click)="changeState('Approved')" class="text-success">Approve
                                    </button>
                                </ng-container>
                                <button mat-stroked-button
                                        *ngIf="content && !content.archive_date; else contentNotArchived"
                                        (click)="archiveContent()">Archive
                                </button>
                                <ng-template #contentNotArchived>
                                    <div
                                        matTooltip="Please remove Expiry Date or set Expiry Date to today or greater to restore content"
                                        [matTooltipDisabled]="!canRestoreContent()">
                                        <button mat-stroked-button [disabled]="canRestoreContent()"
                                                (click)="restoreContent()">Restore
                                        </button>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="row">
            <div class="col-6 p-0">
                <div class="col-12 pl-0 pt-0">
                    <div class="card" [class.border-info]="content && !content.title">
                        <div class="card-body row">
                            <div class="col-12">
                                <mat-form-field *ngIf="content" appearance="outline" class="w-100">
                                    <mat-label>Title*</mat-label>
                                    <input matInput autocomplete="off" placeholder="Please enter a title"
                                           name="contentTitle"
                                           [(ngModel)]="content.title" [disabled]="!content.is_editable">
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <p>Content Type</p>
                                <mat-button-toggle-group *ngIf="content" (change)="contentTypeChanged = true"
                                                         [(ngModel)]="contentType"
                                                         [disabled]="!content.is_editable" name="content"
                                                         #group="matButtonToggleGroup"
                                                         class="w-100 d-flex">
                                    <mat-button-toggle [value]="contentTypes.contentLinks" class="flex-fill">PDF & Links
                                    </mat-button-toggle>
                                    <mat-button-toggle [value]="contentTypes.contentOnly" class="flex-fill">PDF Only
                                    </mat-button-toggle>
                                    <mat-button-toggle [value]="contentTypes.linksOnly" class="flex-fill">Links Only
                                    </mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <div class="col-12">
                                <div class="row d-flex align-items-center">
                                    <mat-checkbox [(ngModel)]="compulsory">
                                        <p>Compulsory [Major Incident/Safety Event]</p>
                                    </mat-checkbox>
                                    <mat-hint class="ml-4">Content will be displayed in all sessions until full coverage
                                        achieved
                                    </mat-hint>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pl-0">
                    <ng-container
                        *ngIf="contentType == contentTypes.contentLinks || contentType == contentTypes.contentOnly">
                        <div class="card" [class.border-info]="!file && !content.content_file">
                            <div class="card-body">
                                <div *ngIf="!file;else fileSelected">
                                    <div class="row">
                                        <div class="col-12"
                                             *ngIf="!file && !content.content_file; else fileSelected">
                                            <label>
                                                <input type="file" autocomplete="off"
                                                       [multiple]="false"
                                                       (change)="handleFileInput($event.target.files)"
                                                       style="display:none" accept=".pdf">
                                                <a mat-stroked-button color="accent" class="upload-btn">
                                                    Browse for PDF ...
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ng-template #fileSelected>
                                    <ng-container *ngIf="!hasUploadStarted;else uploadStarted">
                                        <ng-container *ngIf="!content.content_file; else notCreate">
                                            <div class="d-flex justify-content-between align-items-center col-12">
                                                <div>{{file.name}}</div>
                                                <button mat-button [disabled]="!content.is_editable" *ngIf="file"
                                                        (click)="file = null; content.title = ''">Clear
                                                </button>
                                            </div>
                                        </ng-container>
                                        <ng-template #notCreate>
                                            <div class="d-flex justify-content-between align-items-center col-12">
                                                <div>{{content.content_file.filename}}</div>
                                                <button mat-button *ngIf="content.content_file"
                                                        [disabled]="!content.is_editable"
                                                        (click)="content.content_file = null; content.content_file_id = null">
                                                    Clear
                                                </button>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                    <div class="invalid-files-message mt-4 text-center text-danger"
                                         *ngIf="file && !getValidFileSize()">File is too
                                        large. Please clear the file and select a file smaller
                                        than {{getMaxFileSizeText()}} MB.
                                    </div>
                                    <div class="invalid-files-message mt-4 text-center text-danger"
                                         *ngIf="file && !getValidFileType()">File must be
                                        type PDF. Please clear the file and select a PDF file.
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-12 pl-0">
                    <ng-container
                        *ngIf="contentType == contentTypes.contentLinks || contentType == contentTypes.linksOnly">
                        <div class="card" [class.border-info]="links.length < 1">
                            <div class="card-body pb-0">
                                <div class="row m-0">
                                    <mat-form-field appearance="outline" class="col-6">
                                        <mat-label>Name*</mat-label>
                                        <input matInput autocomplete="off" [(ngModel)]="link.name"
                                               [disabled]="!content.is_editable"
                                               placeholder="Please enter a Name">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="col-6">
                                        <mat-label>Insert after:</mat-label>
                                        <input matInput autocomplete="off" [(ngModel)]="linkInsertAfter"
                                               [disabled]="!content.is_editable"
                                               type="number" min="0">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 d-flex align-items-center align-items-stretch">
                                    <mat-form-field appearance="outline" class="w-75">
                                        <mat-label>Link*</mat-label>
                                        <input matInput autocomplete="off" [(ngModel)]="link.link"
                                               [disabled]="!content.is_editable"
                                               placeholder="Please enter the full link">
                                    </mat-form-field>
                                    <span class="ml-4">
                                        <button class="upload" (click)="addLink()" mat-stroked-button color="accent"
                                                [disabled]="linkInvalid()">Add Link</button>
                                    </span>
                                </div>
                                <mat-hint class="ml-2" *ngIf="linkInvalidHint()">Invalid, please check the link you have
                                    entered is correct and ensure it has a title.
                                </mat-hint>
                            </div>
                            <table class="ctm-table border-0">
                                <tr>
                                    <th>Title</th>
                                    <th>Link</th>
                                    <th>Location</th>
                                    <th class="actions">Actions</th>
                                </tr>
                                <tr *ngFor="let linkEntry of links">
                                    <td>
                                        <small>{{linkEntry.name}}</small>
                                    </td>
                                    <td>
                                        <small>{{linkEntry.link}}</small>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="linkLocation(linkEntry.id) != null; else AtDefault">
                                            <small>After page: {{linkLocation(linkEntry.id) - 1}}</small>
                                        </ng-container>
                                        <ng-template #AtDefault>
                                            <small>End</small>
                                        </ng-template>
                                    </td>
                                    <td class="actions text-right">
                                        <button mat-icon-button [disabled]="!content.is_editable"
                                                (click)="removeLink(linkEntry.id)">Clear
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="links.length < 1"><small>No links have been added to this
                                    presentation.</small></tr>
                            </table>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="card">
                    <div class="card-body row pb-0 pt-1">
                        <div class="col-12">
                            <p>Category</p>
                            <mat-button-toggle-group *ngIf="content && categorys" [(ngModel)]="content.category_id"
                                                     name="category"
                                                     class="w-100 d-flex">
                                <mat-button-toggle *ngFor="let category of categorys" [value]="category.id"
                                                   class="flex-fill">
                                    {{category.title}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="col-12 mt-2">
                            <p>Audience</p>
                            <mat-button-toggle-group *ngIf="content && shiftDefinitions" [(ngModel)]="audience"
                                                     name="shiftDefinition"
                                                     class="w-100 d-flex">
                                <mat-button-toggle [value]="-1" class="flex-fill">All Shifts</mat-button-toggle>
                                <mat-button-toggle *ngFor="let shiftDefinition of shiftDefinitions"
                                                   [value]="shiftDefinition.id"
                                                   class="flex-fill">{{shiftDefinition.name}} Shift
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="col-12 mt-2">
                            <p>Timing of Delivery</p>
                            <mat-radio-group *ngIf="content && deliverys.length > 0" [(ngModel)]="content.delivery_id"
                                             aria-label="Select an option" class="d-flex flex-column">
                                <ng-container *ngFor="let delivery of deliverys">
                                    <mat-radio-button [ngStyle]="{'color': delivery.colour}"
                                                      [value]="delivery.id">{{delivery.title}}
                                    </mat-radio-button>
                                    <mat-hint class="ml-4 mb-3">{{delivery.comment}}</mat-hint>
                                </ng-container>
                            </mat-radio-group>
                        </div>
                        <div class="row">
                            <mat-form-field *ngIf="content" appearance="outline" class="col-6">
                                <mat-label>Valid From</mat-label>
                                <input matInput autocomplete="off" [min]="minDate" [(ngModel)]="validFrom"
                                       (dateChange)="content.valid_from = updateDate(validFrom)"
                                       [matDatepicker]="validPicker">
                                <mat-datepicker-toggle matSuffix [for]="validPicker"></mat-datepicker-toggle>
                                <mat-datepicker #validPicker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field *ngIf="content" appearance="outline" class="col-6">
                                <mat-label>Expiry</mat-label>
                                <input matInput autocomplete="off" [min]="minDate" [(ngModel)]="expiry"
                                       (dateChange)="content.expiry = updateDate(expiry)"
                                       [matDatepicker]="expiryPicker">
                                <span matSuffix class="d-flex align-items-center">
                                    <mat-datepicker-toggle [for]="expiryPicker"></mat-datepicker-toggle>
                                    <button *ngIf="content.expiry" mat-icon-button (click)="resetExpiryDate()">
                                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                    </button>
                                    <mat-datepicker #expiryPicker></mat-datepicker>
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #uploadStarted>
        <div class="card col-12 pt-3">
            <div class="p-4 text-center">
                <i>Please wait, while the file is being uploaded...</i>
            </div>
            <mat-progress-bar [value]="getCurrentUploadPercentCompleted()"></mat-progress-bar>
            <div class="p-4 text-center">{{getCurrentUploadPercentCompleted()}} %
                <br />
                <small>{{getCurrentUploadBytesCompleted() | fileSize}} / {{totalFileSize | fileSize}}</small>
            </div>
        </div>
    </ng-template>

</div>
