import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Content, ContentPage} from '../../../models/content.model';
import {ContentService} from '../../../services/content.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogsService} from '../../../services/dialogs.service';
import {ContentFileService} from '../../../services/content_file.service';
import {ContentHiddenService} from '../../../services/content_hidden.service';
import {ContentHidden} from '../../../models/content_hidden.model';
import {LinkService} from '../../../services/link.service';
import {Link} from '../../../models/link.model';
import {ContentLinkService} from '../../../services/content_link.service';
import {ContentLink} from '../../../models/content_link.model';
import {DomSanitizer} from '@angular/platform-browser';
import {distinctUntilChanged, mergeMap, switchMap, takeUntil} from "rxjs/operators";
import {of, Subject} from "rxjs";

@Component({
    selector: 'app-content-view',
    templateUrl: './content-view.component.html',
    styleUrls: ['./content-view.component.scss']
})
export class ContentViewComponent implements OnInit, AfterViewInit, OnDestroy {

    contentID: number;
    content: Content = new Content();
    contentHiddens: ContentHidden[] = [];
    contentLinks: ContentLink[] = [];
    links: Link[] = [];
    pages: ContentPage[] = [];
    totalPageCount = 0;
    pageIdx = 0;
    aspectRatio = 16 / 9 - 1;
    loadingSlides: boolean;   // Hides content until aspectRatio is calculated
    pageWidth = 1280;
    fullscreenWidth = 1280;
    fullscreenHeight = 720;
    lowestPageIdx = 0;
    viewEnd = false;
    resizeId: any;
    imageUnsubscribe: Subject<void> = new Subject<void>();

    @ViewChild('fullscreenIdentifier', {read: ElementRef}) fullscreenIdentifier: ElementRef;
    @ViewChild('slideIdentifier', {read: ElementRef}) slideIdentifier: ElementRef;

    @HostListener('document:keyup', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        this.handleKeyEvent(event);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        clearTimeout(this.resizeId);
        // Use timeout to prevent spamming the image generation algorithm (as no image already cached) on the server
        // when user slowly dragging window larger or smaller.
        this.resizeId = setTimeout(() => {
            this.getPageImages()
        }, 250);
    }

    constructor(private contentService: ContentService,
                private contentFileService: ContentFileService,
                private contentHiddenService: ContentHiddenService,
                private contentLinkService: ContentLinkService,
                private linkService: LinkService,
                private route: ActivatedRoute,
                private router: Router,
                private dialogsService: DialogsService,
                private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.loadingSlides = true;

        this.route.params.pipe(
            mergeMap(params => {
                this.contentID = parseInt(params['id'], 0)
                return of(this.contentID)
            }),
            mergeMap(contentID => this.contentService.getContent(contentID)),
            mergeMap(content => {
                this.content = content;
                return this.contentLinkService.getContentLinks()
                // TODO should do the above by contentid, not grabbing them all then filtering...
            })
        ).subscribe(contentLinks => {
            this.contentLinks = contentLinks;
            this.contentLinks = this.contentLinks.filter(cl => cl.content_id === this.contentID);

            this.contentLinks.forEach((cl) => {
                this.linkService.getLink(cl.link_id).subscribe(link => {
                    this.links.push(link);
                });
            });

            this.pages = ContentPage.getPages(this.contentLinks, this.contentID, this.content.content_file, this.pageWidth);
            this.initPageImageRequests();
            this.totalPageCount = this.pages.length;
            this.lowestPageIdx = 0; // No titlepage
            this.setCurrentPageIdx(this.lowestPageIdx);
        });

        this.contentHiddenService.getContentHiddens().subscribe(contentHiddens => {
            this.contentHiddens = contentHiddens.filter(ch => ch.content_id === this.contentID);
        });
    }

    ngAfterViewInit() {
        return
    }

    ngOnDestroy() {
        this.imageUnsubscribe.next(); // cancel requests
        this.imageUnsubscribe.complete(); // close subscription
    }

    initPageImageRequests() {
        let pageNo = 1;
        for (const page of this.pages) {
            if (page.linkID === null) { // don't need to request image on page with link
                this.pageURL(page.page - 1, pageNo);
                if (pageNo === 1 && this.loadingSlides) {
                    // get first image so can get aspect ratio
                    this.contentFileService.getContentFilePageBlob(page.contentFile.id, pageNo, 'webp', this.pageWidth).pipe(
                        takeUntil(this.imageUnsubscribe)
                    ).subscribe({
                        next: (image) => this.createImageFromBlob(image, 0)
                    });
                }
                pageNo += 1;
            }
        }

        if (pageNo === 1) {
            // there is no images to be requested
            this.loadingSlides = false;
        }
    }

    getPageImages() {
        // Find size of DOM and adjust content display size accordingly
        this.getPageSize();
        if (this.pages) {
            for (const page of this.pages) {
                page.imageSubject.next(this.pageWidth);
            }
        }
    }

    // Set the URL used in [SRC]
    pageURL(pageIdx: number, contentIdx: number): any {
        // TODO: Consider google ads implications. Possibly have ghostery installed as add-on in with adsense and doubleclicked
        // blocked with show purple box option unchecked browser.
        // return ContentViewComponent.embedYoutube(links[0].link);
        // TODO: Below link is devfu youtube link used for testing and uncomment standard link.
        // return ContentViewComponent.embedYoutube('https://www.youtube.com/watch?v=S-jwZtXuHuw');

        // Render image from blob as data url
        this.pages[pageIdx].imageSubject.pipe(
            distinctUntilChanged(), // won't request again the subject contains same value
            switchMap(() => {
                return this.contentFileService.getContentFilePageBlob(this.pages[pageIdx].contentFile.id, contentIdx, 'webp',
                    this.pageWidth);
            }),
            takeUntil(this.imageUnsubscribe) // will cancel all requests
        ).subscribe({
            next: (data: Blob) => this.createImageFromBlob(data, pageIdx)
        });
    }

    isLink(idx: number): boolean {
        return this.dataNotLoaded() ? false : (idx >= 0) && this.pages[idx].linkID !== null;
    }

    notYoutubeVideo(url: string): boolean {
        const regex = new RegExp('youtube');
        return !regex.test(url);
    }

    checkLink(idx: number): string {
        if (this.dataNotLoaded()) {
            return null;
        }

        if (this.isLink(idx)) {
            const links = this.links.filter(l => l.id === this.pages[idx].linkID);
            if (links.length < 1) {
                return null;
            }
            return ContentViewComponent.embedYoutube(links[0].link);
        } else {
            return null;
        }
    }

    static embedYoutube(url: string): string {
        let regex = new RegExp('youtube');
        if (regex.test(url)) {
            regex = new RegExp('watch\\?v=');
            url = url.replace(regex, 'embed/');
        }
        regex = new RegExp('\\?t=.*|&t=.*');
        if (regex.test(url)) {
            url = url.replace(regex, '');
        }
        return url;
    }

    getPreviousPageIdx() {
        return this.pageIdx - 1 >= this.lowestPageIdx ? this.pageIdx - 1 : null;
    }

    getNextPageIdx() {
        return this.pageIdx + 1 < this.pages.length ? this.pageIdx + 1 : null;
    }

    setCurrentPageIdx(idx: number) {
        this.pageIdx = idx;
    }

    handleKeyEvent($event: KeyboardEvent): void {
        if (!this.dialogsService.openDialogs()) {
            switch ($event.key) {
                case 'ArrowLeft':
                    if (!this.loadingSlides) {
                        this.handleArrowLeft();
                        $event.preventDefault();
                    }
                    break;
                case 'ArrowRight':
                case ' ':
                    if (!this.loadingSlides) {
                        this.handleArrowRight();
                        $event.preventDefault();
                    }
                    break;
                case 'Escape':
                    this.handleEscape();
                    $event.preventDefault();
                    break;
            }
        }
    }

    handleArrowLeft() {
        if (!this.dialogsService.openDialogs()) {
            if (this.getPreviousPageIdx() !== null && !this.viewEnd) {
                // Load previous page
                this.setCurrentPageIdx(this.getPreviousPageIdx());
            } else {
                this.viewEnd = false;
            }
        }
    }

    handleArrowRight() {
        if (!this.dialogsService.openDialogs()) {
            if (this.pages.length > 0 && this.getNextPageIdx() == null) {
                // Inform user that session finished
                this.viewEnd = true;
            }

            // Load next page
            if (this.getNextPageIdx() !== null) {
                this.setCurrentPageIdx(this.getNextPageIdx())
            }
        }
    }

    handleEscape() {
        if (this.pages.length > 0 && this.getNextPageIdx() == null && this.viewEnd) {
            // Finish session and record participation
            this.router.navigate(['/content/library']);
        } else {
            this.openDialogConfirm('', 'Are you sure you would like to navigate away? Content is not complete.', ['/content/library']);
        }
    }

    getPageSize() {
        // Prevent continually updating once page loaded
        if (this.fullscreenIdentifier) {
            // Get DOM width such that server returns full size slide images. Clamp as to not crop the slide off the screen.
            this.fullscreenWidth = this.fullscreenIdentifier.nativeElement.offsetWidth;
            this.fullscreenHeight = this.fullscreenIdentifier.nativeElement.offsetHeight;
            document.documentElement.style.setProperty('--video-width', this.fullscreenWidth.toString().concat('px'));
            document.documentElement.style.setProperty('--video-height', this.fullscreenHeight.toString().concat('px'));
            document.documentElement.style.setProperty('--video-offset-top', '0'.concat('px'));
            document.documentElement.style.setProperty('--video--offset-left', '0'.concat('px'));
            // Used to capture focus from iframe to prevent iframe capturing keyboard events and preventing navigation to next page
            const overlayTopHeight = this.fullscreenHeight / 2 - this.fullscreenHeight * 5 / 100;
            const overlayBotHeight = this.fullscreenHeight / 2 - this.fullscreenHeight * 5 / 100 - 60;
            const overlayBotOffset = 60;
            document.documentElement.style.setProperty('--video-ol-top-height', overlayTopHeight.toString().concat('px'));
            document.documentElement.style.setProperty('--video-ol-bot-height', overlayBotHeight.toString().concat('px'));
            document.documentElement.style.setProperty('--video-offset-bot', overlayBotOffset.toString().concat('px'));
        }

        // Aspect ratio assumed and corrects once slide loaded into DOM
        if (this.slideIdentifier) {
            this.aspectRatio = this.slideIdentifier.nativeElement.offsetWidth / this.slideIdentifier.nativeElement.offsetHeight;
        }

        this.setTopLeftOffset();
    }

    setTopLeftOffset() {
        let topOffset: number;
        let leftOffset: number;

        if (this.aspectRatio > 1) {
            // Dynamic offset to centre content when re-sized.
            this.pageWidth = this.clamp(this.fullscreenWidth, Math.floor(this.fullscreenHeight * this.aspectRatio));
            topOffset = (this.fullscreenHeight - this.pageWidth / this.aspectRatio) / 2;
            leftOffset = (this.fullscreenWidth - this.pageWidth) / 2;
        } else {
            // Dynamic offset to centre content when re-sized.
            this.pageWidth = this.clamp(Math.floor(this.fullscreenWidth / this.aspectRatio), this.fullscreenHeight);
            topOffset = (this.fullscreenHeight - this.pageWidth) / 2;
            leftOffset = (this.fullscreenWidth - this.pageWidth * this.aspectRatio) / 2;
        }
        document.documentElement.style.setProperty('--slide-offset-top', topOffset.toString().concat('px'));
        document.documentElement.style.setProperty('--slide-offset-left', leftOffset.toString().concat('px'));
    }

    toggleShown() {
        if (this.isShown() && this.contentHiddens.filter(ch => ch.page_idx === this.pageIdx).length < 1) {
            const contentHidden = new ContentHidden(0, this.contentID, this.pageIdx);
            this.contentHiddenService.createContentHidden(contentHidden).subscribe(res => {
                if (res) {
                    this.contentHiddens.push(res);
                }
            });
        } else if (this.contentHiddens.length > 0 && this.contentHiddens.filter(ch => ch.page_idx === this.pageIdx).length > 0) {
            this.contentHiddenService.deleteContentHidden(this.contentHiddens.filter(ch => ch.page_idx === this.pageIdx)[0].id
            ).subscribe(res => {
                if (res) {
                    this.contentHiddens = this.contentHiddens.filter(ch => ch.page_idx !== this.pageIdx);
                }
            });
        }
    }

    isShown(): boolean {
        return !(this.contentHiddens.length > 0 && this.contentHiddens.filter(ch => ch.page_idx === this.pageIdx).length > 0);
    }

    public openDialogConfirm(title: string = '', message: string = '', path: any[]) {

        let result: boolean;

        this.dialogsService
            .confirm(title, message)
            .subscribe(res => {
                result = res;
                if (result) {
                    this.router.navigate(path).then();
                }
            });
    }

    /** Clamps a number between zero and a maximum. */
    private clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }

    transform(url: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    // Converts an image blob to a data url
    createImageFromBlob(image: Blob, pageIdx: number) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.pages[pageIdx].image = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image)
        }
    }

    // Returns true if the data has not yet been loaded into variables
    dataNotLoaded(): boolean {
        return this.pages.length < 1 || (this.contentLinks.length > 0 && this.links.length < 1);
    }

    loadComplete() {
        if (this.loadingSlides) {
            this.pages[0].image = null; // reset first image to null as have aspect ratio now
            this.getPageSize();
            this.getPageImages();
            this.loadingSlides = false;
        }
    }

}
