<app-file-upload-target></app-file-upload-target>
<div class="container-lg p-xs-0">
    <div class="row mt-2 d-flex align-items-center justify-content-between">
        <div class="col-3 pb-0">
            <h5>Content Library</h5>
        </div>
        <div class="col-9 pb-0 text-right">
            <button [hidden]="!['ADMIN', 'MANAGER'].includes(user?.role)" mat-stroked-button color="basic" class="mb-2"
                    [routerLink]="['/content/attendees/compulsory']"> Compulsory Content Metrics
            </button>
            <button mat-flat-button color="accent" class="mb-2" [routerLink]="['/content/create']">Create Content
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field appearance="outline" class="w-100">
                <mat-icon matPrefix class="mr-2">search</mat-icon>
                <mat-label>Search Content</mat-label>
                <input matInput placeholder="Start search by typing.." type="text" autocomplete="off"
                       [(ngModel)]="contentSearchTerm" (keyup)="updateFilters()">
                <span matSuffix class="d-flex align-items-center">
          <button *ngIf="contentSearchTerm" mat-icon-button (click)="contentSearchTerm = ''; updateFilters()">
            <mat-icon matTooltip="Clear">clear</mat-icon>
          </button>
          <mat-slide-toggle class="pr-3 align-bottom mb-0" [(ngModel)]="filterValues.showArchived"
                            (change)="updateFilters()">
            <small>Show Archived</small>
          </mat-slide-toggle>
        </span>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Approval Status</mat-label>
                <mat-select [(ngModel)]="filterValues.stateName" placeholder="Status">
                    <mat-option (click)="updateFilters()" [value]="''">All</mat-option>
                    <mat-option *ngFor="let state of states" (click)="updateFilters()" [value]="state.name">
                        {{state.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Compulsory</mat-label>
                <mat-select [(ngModel)]="compulsory"
                            (ngModelChange)="filterValues.compulsory = updateFilter(compulsory); updateFilters()"
                            placeholder="Compulsory">
                    <mat-option [value]="-1">All</mat-option>
                    <mat-option *ngFor="let compulsoryEntry of compulsorys" [value]="compulsoryEntry.id">
                        {{compulsoryEntry.title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Delivery</mat-label>
                <mat-select [(ngModel)]="deliveryID"
                            (ngModelChange)="filterValues.deliveryID = updateFilter(deliveryID); updateFilters()"
                            placeholder="Delivery">
                    <mat-option [value]="-1">All</mat-option>
                    <mat-option *ngFor="let delivery of deliverys" [value]="delivery.id">
                        {{delivery.title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Category</mat-label>
                <mat-select [(ngModel)]="categoryID"
                            (ngModelChange)="filterValues.categoryID = updateFilter(categoryID); updateFilters()"
                            placeholder="Category">
                    <mat-option [value]="-1">All</mat-option>
                    <mat-option *ngFor="let category of categorys" [value]="category.id">
                        {{category.title}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="col-12 overflow-auto">
        <app-loading *ngIf="!dataSource"></app-loading>
        <table mat-table [ngStyle]="{'opacity' : !dataSource ? 0 : 1}" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Content</th>
                <td mat-cell *matCellDef="let content" class="content-column">
                    <div class="row m-2">
                        <ng-container *ngIf="contentFirstPage(content.content_file); else noContentPreview">
                            <img class="m-1 mr-2 thumbnail d-none d-xl-block"
                                 (click)="router.navigate(['/content/','show',content.id,''])"
                                 [src]=contentFirstPage(content.content_file) alt="Preview Missing">
                        </ng-container>
                        <ng-template #noContentPreview>
                            <span class="m-1 mr-2 thumbnail d-none d-xl-block"
                                  (click)="router.navigate(['/content/','show',content.id,''])"></span>
                        </ng-template>
                        <div class="content-column-text">
                            <p class="mb-0 mt-1 text-truncate" matTooltip="{{content.title}}">{{content.title}}</p>
                            <ng-container *ngIf="contentFirstPage(content.content_file); else noContentFile">
                                <p class="content-column-text text-truncate mb-0">
                                    <small>{{getFilename(content.id)}}</small></p>
                            </ng-container>
                            <ng-template #noContentFile>
                                <small>Links Only</small>
                            </ng-template>
                            <p class='mb-0 mt-2 font-weight-bold' [ngClass]="content.state == 'Approved' ? 'text-success' :
              (content.state == 'Rejected' ? 'text-danger' : 'text-warning')">{{content.state}}</p>
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="coverage">
                <th mat-header-cell *matHeaderCellDef> Coverage</th>
                <td mat-cell *matCellDef="let content" class="sm-column">
                    <ng-container *ngIf="getCoverage(content.id); else loadingCoverage">
                        <ng-container *ngIf="checkCoverageApplicable(content.id); else invalidCoverage">
                            <p class="m-0" [ngClass]="checkCoverage(content.id)">{{getCoverage(content.id)}} </p>
                        </ng-container>
                        <ng-template #invalidCoverage>
                            {{getInvalidReason(content.id)}}
                        </ng-template>
                    </ng-container>
                    <ng-template #loadingCoverage>
                        <mat-spinner color="warn" [diameter]="30"></mat-spinner>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="delivery">
                <th mat-header-cell *matHeaderCellDef> Delivery</th>
                <td mat-cell *matCellDef="let content" class="sm-column">
                    <div class="row">
                        <div *ngIf="deliverys" class="deliverys">
                            <p class="m-0 mt-0" [ngStyle]="{'color': contentDelivery(content.delivery_id).colour}">
                                {{contentDelivery(content.delivery_id).title}}</p>
                            <small *ngIf="getCompulsory(content.id)">
                                <mat-icon class="ml-2" matTooltip="Compulsory Content">error</mat-icon>
                            </small>
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> Category</th>
                <td mat-cell *matCellDef="let content">
                    {{getCategory(content.id)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="valid_from">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid From</th>
                <td mat-cell *matCellDef="let content">
                    {{getValidFrom(content.id) | date:'dd MMM, yyyy'}}
                    <span *ngIf="!getValidFrom(content.id)">No Valid From</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="archive_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiry Date</th>
                <td mat-cell *matCellDef="let content">
                    <!--            <ng-container *ngIf="getArchiveDate(content.id); else notArchived">-->
                    <!--              {{getArchiveDate(content.id) | date:'dd MMM, yyyy'}}-->
                    <!--            </ng-container>-->
                    <!--            <ng-template #notArchived>-->
                    <ng-container *ngIf="getExpiryDate(content.id); else noExpiry">
                        {{getExpiryDate(content.id) | date:'dd MMM, yyyy'}}
                    </ng-container>
                    <ng-template #noExpiry>
                        <span>No Expiry</span>
                    </ng-template>
                    <!--            </ng-template>-->
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell class="text-right" *matCellDef="let content">
                    <div class="btn-group btn-dropdown">
                        <button class="action" mat-stroked-button
                                (click)="navigate('show', content.id)">Preview
                        </button>
                        <button class="dropdown" mat-stroked-button [matMenuTriggerFor]="editMenu">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </div>
                    <mat-menu #editMenu="matMenu">
                        <button mat-menu-item (click)="navigate('create', content.id, 'edit')">Edit
                        </button>
                        <button mat-menu-item (click)="navigate('create', content.id, 'copy')">Copy
                        </button>
                        <button mat-menu-item (click)="router.navigate(['content','attendees',content.id])">Metrics
                        </button>
                    </mat-menu>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.state == 'Approved' ? 'border-success' :
              (row.state == 'Rejected' ? 'border-danger' : 'border-warning')"></tr>
        </table>

        <mat-paginator #paginator [ngStyle]="{'opacity' : !dataSource ? 0 : 1}"
                       [pageSizeOptions]="pageSizeOptions"
                       showFirstLastButtons></mat-paginator>

    </div>
</div>
