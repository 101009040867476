export class ContentLink {
  constructor(public id: any = null,
              public content_id: any = null,
              public link_id: any = null,
              public insert_at: number = 0
              ) {}

  public static assign(cl: ContentLink) {
    const n = Object.assign(new ContentLink(), cl);

    return n;
  }
}