import {Component, OnInit} from '@angular/core';
import {PrestartTypeService} from '../../../services/prestart_type.service';
import {PrestartType} from '../../../models/prestart_type.model';
import {Log} from '../../../helpers/log.helper';
import {Router} from "@angular/router";
import {DialogsService} from "../../../services/dialogs.service";
import {ShiftDefinitionService} from "../../../services/shift_definition.service";
import {ShiftDefinition} from "../../../models/shift_definition.model";
import {DatePipe} from "@angular/common";
import {HttpErrorResponse} from "@angular/common/http";
import {Content} from "../../../models/content.model";

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent implements OnInit {

  prestartTypes: PrestartType[] = [];
  prestartTypeCreate: PrestartType;
  shiftDefinitions: ShiftDefinition[] = [];
  prestartTypeID = 0;
  viewType = '';
  keyPressed = false;
  prestartTypeNoStartOffset = false;
  showCreatePrestart: boolean = false;
  isEdit: boolean = false;


  constructor(private prestartTypeService: PrestartTypeService,
              private shiftDefinitionService: ShiftDefinitionService,
              private router: Router,
              private dialogsService: DialogsService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.prestartTypeService.getPrestartTypes().subscribe(prestartTypes => {
      this.prestartTypes = prestartTypes;
      this.shiftDefinitionService.getShiftDefinitions().subscribe(shiftDefintions => {
        this.shiftDefinitions = shiftDefintions;
      })
    });
  }

  countActive(): number {
    let count = 0;
    this.prestartTypes.forEach(pt => {
      count += <any>pt.is_active;
    });
    return count;
  }

  createPrestartType() {
    this.showCreatePrestart = !this.showCreatePrestart;
    let prestartType = this.prestartTypes.reduce((res, obj) => {
      return (!!res.is_active === false || Math.abs(obj.ordinal) < Math.abs(res.ordinal)) ? obj : res;
    });
    this.prestartTypeCreate = new PrestartType(null, prestartType.name, prestartType.window_after,
      prestartType.window_before, prestartType.start_offset, prestartType.is_active, prestartType.ordinal, true)
    this.prestartTypeCreate.id = null;
  }

  editPrestartType(prestartTypeID: number) {
    this.showCreatePrestart = !this.showCreatePrestart;
    this.prestartTypeCreate = this.prestartTypes.filter(pt => pt.id === prestartTypeID)[0]
    this.isEdit = true;
  }

  deletePrestartType(prestartTypeID: number) {
    if (this.countActive() > 1) {
      this.prestartTypeService.deletePrestartType(prestartTypeID).subscribe(_ => {
        Log.i('PrestartType deleted')
      });
      this.prestartTypes = this.prestartTypes.filter(pt => pt.id !== prestartTypeID);
    } else {
      this.openDialogError('', 'Must have at least one active type')
    }
  }

  deactivatePrestartType(prestartTypeID: number) {
    if (this.countActive() > 1) {
      const prestartType = this.prestartTypes.filter(pt => pt.id === prestartTypeID)[0];
      prestartType.is_active = false;
      this.prestartTypeService.updatePrestartType(prestartType).subscribe(_ => {
        Log.i('PrestartType deactivated')
      });
    } else {
      this.openDialogError('', 'Must have at least one active type')
    }
  }

  activatePrestartType(prestartTypeID: number) {
    const prestartType = this.prestartTypes.filter(pt => pt.id === prestartTypeID)[0];

    this.prestartTypeService.checkPrestartTypeNameActive(prestartType).subscribe(_ => {
      prestartType.is_active = true;
      this.prestartTypeService.updatePrestartType(prestartType).subscribe(_ => {
        Log.i('PrestartType activated')
      });
    }, error => {
      if (error instanceof HttpErrorResponse && error.status === 409) {
        this.openDialogError('', 'Name already active. Please de-activate existing name.');
      }
    });
  }

  public openDialogError(title: string = '', message: string = '') {
    this.dialogsService
      .error(title, message)
      .subscribe(_ => {
      });
  }

  getTime(shiftOffset: number, shiftStart: string, minutesStart: number) {
    const d = new Date(new Date(Date.now()).getFullYear(), new Date(Date.now()).getMonth(), 0,
      Number(shiftStart.split(':')[0]), Number(shiftStart.split(':')[1]), Number(shiftStart.split(':')[2]));
    d.setHours(d.getHours() + shiftOffset * 24.0);
    d.setMinutes(d.getMinutes() + minutesStart);
    return d
  }

  // Moves an item by one index in an array in a selected direction
  private moveItemInList(items: PrestartType[], fromIndex: number, dirUp: boolean): PrestartType[] {
    const delta = dirUp ? -1 : 1;
    const from = this.clamp(fromIndex, items.length - 1);
    const to = this.clamp(from + delta, items.length - 1);

    const target = items[from];
    items[from] = items[to];
    items[to] = target;
    let ordinal = 1;
    items.forEach((pt, i) => {
      pt.ordinal = ordinal;
      ordinal++;
      this.prestartTypeService.updatePrestartType(pt).subscribe(_ => {
        Log.i('Prestart Type Updated');
      }, error => {
        return this.prestartTypeService.getPrestartTypes().subscribe(prestartTypes => {
          this.prestartTypes = prestartTypes;
          return this.prestartTypes;
        });
      });
    });

    return (items);
  }

  /** Clamps a number between zero and a maximum. */
  private clamp(value: number, max: number): number {
    return Math.max(0, Math.min(max, value));
  }


  checkCreateInvalid(): boolean {
    return this.keyPressed || this.prestartTypeCreate.name.length < 1;
  }

  savePrestartType() {
    this.prestartTypeService.checkPrestartTypeOverlap(this.prestartTypeCreate).subscribe(_ => {
      this.prestartTypeService.checkPrestartTypeNameActive(this.prestartTypeCreate).subscribe(_ => {
        if (this.isEdit) {
          this.prestartTypeService.updatePrestartType(this.prestartTypeCreate).subscribe(_ => {
            Log.i('PrestartType Updated');
            this.showCreatePrestart = false
            this.prestartTypeService.getPrestartTypes().subscribe(prestartTypes => {
              this.prestartTypes = prestartTypes;
            });
          });
        } else {
          this.prestartTypeService.createPrestartType(this.prestartTypeCreate).subscribe(_ => {
            Log.i('PrestartType Created');
          });
          this.showCreatePrestart = false
          this.prestartTypeService.getPrestartTypes().subscribe(prestartTypes => {
            this.prestartTypes = prestartTypes;
          });
        }
      }, error => {
        if (error instanceof HttpErrorResponse && error.status === 409) {
          this.openDialogError('', 'Name already used. Please select another name or de-activate existing name.');
          this.keyPressed = false;
        }
      });
    }, error => {
      if (error instanceof HttpErrorResponse && error.status === 409) {
        this.openDialogError('', 'Overlaps with another type. Change time or de-activate conflicting type.');
        this.keyPressed = false;
      }
    });
  }

  updateStartOffset() {
    if (this.prestartTypeNoStartOffset) {
      this.prestartTypeCreate.start_offset = null;
    } else {
      this.prestartTypeCreate.start_offset = 0;
    }
  }


}
