import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { OverlayModule } from "@angular/cdk/overlay";
// @ts-ignore
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { AppComponent } from './app.component';
import { CreateContentComponent } from './components/pages/content-create/create-content.component';
import { CreateSessionComponent } from './components/pages/session-create/create-session.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { SessionViewComponent } from './components/pages/session-view/session-view.component';
import { ContentService } from './services/content.service';
import { SessionService } from './services/session.service';
import { routing } from './app.routing';
import { AuthenticationService } from './services/authentication.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './auth/token.interceptor';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { SessionContentService } from './services/session_content.service';
import { FileService } from './services/file.service';
import { FileSizePipe } from './pipes/file-size.pipe';
import { RecordSessionAttendeesComponent } from './components/pages/session-record-attendees/record-session-attendees.component';
import { WorkerService } from './services/worker.service';
import { SessionWorkerService } from './services/session_worker.service';
import { ReviewContentAttendeesComponent } from './components/pages/content-review-attendees/review-content-attendees.component';
import { DialogsService } from './services/dialogs.service';
import { DialogConfirm } from './components/dialogs/confirm.component';
import { ContentLibraryComponent } from './components/pages/content-library/content-library.component';
import { ShiftDefinitionService } from './services/shift_definition.service';
import { ReportComponent } from './components/pages/report/report.component';
import { ContentViewComponent } from './components/pages/content-view/content-view.component';
import { SessionLibraryComponent } from './components/pages/session-library/session-library.component';
import {DatePipe, NgOptimizedImage} from '@angular/common';
import { AccessManagementComponent } from './components/pages/user-access-management/access-management.component';
import { ContentManagementComponent } from './components/pages/content-management/content-management.component';
import { UserSettingsComponent } from './components/pages/user-settings/user-settings.component';
import { SessionMetricsComponent } from './components/pages/session-metrics/session-metrics.component';
import { DeliveryService} from './services/delivery.service';
import { CategoryService} from './services/category.service';
import { LinkService} from './services/link.service';
import { ContentLinkService} from './services/content_link.service';
import { UserService } from './services/user.service';
import { ContentFileService } from './services/content_file.service';
import { CrewService } from './services/crew.service';
import { ContentHiddenService } from './services/content_hidden.service';
import { SessionMetricService } from './services/session_metric.service';
import { ReportService } from './services/report.service';
import { LoadingComponent } from './components/pages/app-loading/loading.component';
import { TitlepageService} from './services/titlepage.service';
import { CreateTitlepageComponent} from './components/pages/titlepage-create/create-titlepage.component';
import { LoginComponent} from './components/pages/user-login/login.component';
import { LogoutComponent} from './components/pages/user-logout/logout.component';
import { NoAccessComponent} from './components/pages/user-no-access/no-access.component';
import { NoNetworkComponent} from './components/pages/app-no-network/no-network.component';
import { AuthGuard} from './auth/auth.guard';
import { AttendanceService } from './services/attendance.service';
import { CoverageService } from './services/coverage.service';
import { DashboardService } from './services/dashboard.service';
import { RosterService } from './services/roster.service';
import { RosterRotationService } from './services/roster_rotation.service';
import { ShiftService } from './services/shift.service';
import { ShiftRosterService } from './services/shift_roster.service';
import { WorkerCrewService } from './services/worker_crew.service';
import { DialogError } from './components/dialogs/error.component';
import { NotFoundComponent } from './components/pages/app-not-found/not-found.component';
import { FooterComponent } from './components/footer/footer.component';
import {IntegrationService} from "./services/integration.service";
import {IntegrationLogService} from "./services/integration_log.service";
import { SystemHealthComponent } from './components/pages/system-integration-health/system-health.component';
import { SystemSettingsComponent } from './components/pages/system-settings/system-settings.component';
import { PrestartTypeService } from './services/prestart_type.service';
import { DialogWorkers} from "./components/dialogs/compulsory-content/workers";
import { FileUploadHintComponent } from './components/pages/file-upload-hint/file-upload-hint.component';
import { FileUploadTargetComponent } from './components/pages/file-upload-target/file-upload-target.component';

@NgModule({
    declarations: [
        AppComponent,
        CreateContentComponent,
        CreateSessionComponent,
        DashboardComponent,
        SessionViewComponent,
        FileSizePipe,
        RecordSessionAttendeesComponent,
        ReviewContentAttendeesComponent,
        DialogConfirm,
        DialogError,
        DialogWorkers,
        ContentLibraryComponent,
        ReportComponent,
        ContentViewComponent,
        SessionLibraryComponent,
        AccessManagementComponent,
        ContentManagementComponent,
        UserSettingsComponent,
        SessionMetricsComponent,
        LoadingComponent,
        CreateTitlepageComponent,
        LoginComponent,
        LogoutComponent,
        LoginComponent,
        NoAccessComponent,
        NoNetworkComponent,
        NotFoundComponent,
        FooterComponent,
        SystemHealthComponent,
        SystemSettingsComponent,
        FileUploadHintComponent,
        FileUploadTargetComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        routing,
        MatButtonToggleModule,
        MatButtonModule,
        MatToolbarModule,
        MatSelectModule,
        MatTabsModule,
        MatInputModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatGridListModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatSortModule,
        MatTreeModule,
        OverlayModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatStepperModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatSliderModule,
        NgOptimizedImage
    ],
    providers: [
        AttendanceService,
        CategoryService,
        ContentService,
        ContentFileService,
        ContentHiddenService,
        ContentLinkService,
        CoverageService,
        CrewService,
        DashboardService,
        DeliveryService,
        IntegrationService,
        LinkService,
        IntegrationLogService,
        ReportService,
        RosterService,
        RosterRotationService,
        SessionService,
        SessionContentService,
        SessionMetricService,
        SessionWorkerService,
        ShiftService,
        ShiftDefinitionService,
        ShiftRosterService,
        TitlepageService,
        UserService,
        WorkerService,
        WorkerCrewService,
        PrestartTypeService,
        FileService,
        DialogsService,
        DatePipe,
        AuthenticationService,
        AuthGuard,
        HttpClientModule,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
