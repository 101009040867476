import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {RosterRotation} from '../models/roster_rotation.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RosterRotationService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('RosterRotationService Ready...');
  }

  getRosterRotations(): Observable<RosterRotation[]> {
    return this.http.get<RosterRotation[]>(AppSettings.API_ENDPOINT + 'roster_rotation', this.options);
  }

  getRosterRotation(rosterRotationID: number): Observable<RosterRotation> {
    return this.http.get<RosterRotation>(AppSettings.API_ENDPOINT + 'roster_rotation/' + rosterRotationID, this.options);
  }

  createRosterRotation(rosterRotation: RosterRotation): Observable<RosterRotation> {
    return this.http.post<RosterRotation>(AppSettings.API_ENDPOINT + 'roster_rotation', JSON.stringify(rosterRotation), this.options);
  }

  updateRosterRotation(rosterRotation: RosterRotation): Observable<RosterRotation> {
    return this.http.put<RosterRotation>(AppSettings.API_ENDPOINT + 'roster_rotation/' + rosterRotation.id, JSON.stringify(rosterRotation), this.options);
  }

  deleteRosterRotation(rosterRotationID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'roster_rotation/' + rosterRotationID, this.options);
  }
}
