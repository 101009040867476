import { ContentFile } from './content_file.model';

export class Titlepage {
  constructor(public id: any = null,
              public state: string = 'Inactive',
              public ctime: any = null,
              public mtime: any = null,
              public content_file_id: any = null,
              public content_file: ContentFile = null
  ) {}

  public static assign(t: Titlepage) {
    const n = Object.assign(new Titlepage(), t);

    if (t.content_file) {
      n.content_file = ContentFile.assign(t.content_file)
    }

    return n;
  }
}
