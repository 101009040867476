<div class="container">
    <div class="row d-flex align-items-center justify-content-between">
        <div>
            <h5>Content Management</h5>
        </div>
        <div class="button-row">
            <button mat-stroked-button [routerLink]="['/titlepage/create']">Upload New</button>
            <button mat-stroked-button (click)="saveContentManagement()">Save Changes</button>
        </div>
    </div>
    <div class="row">
        <div class="card w-100">
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                    <p>Title Page</p>
                </div>
                <div class="row flex-wrap" *ngIf="titlepages">
                    <div class="thumbnail-container" *ngFor="let titlepage of titlepages">
                        <div aria-label="Select an option" color="primary">
                            <ng-container>
                                <div class="thumbnail-container">
                                    <div class="thumbnail-selector" *ngIf="titlepage.state === 'Active'">
                                        <mat-icon class="text-success">check_circle</mat-icon>
                                    </div>
                                    <img class="thumbnail border"
                                        [class.border-success]="titlepage.state === 'Active'"
                                        [src]="getPage(titlepage.id)"
                                        (click)="selectedID = titlepage.id; updateState()">
                                </div>
                                <p class="pl-3 mb-0 text-truncate">{{titlepage.content_file.filename}}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
