import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {ShiftRoster} from '../models/shift_roster.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ShiftRosterService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('ShiftRosterService Ready...');
  }

  getShiftRosters(): Observable<ShiftRoster[]> {
    return this.http.get<ShiftRoster[]>(AppSettings.API_ENDPOINT + 'shift_roster', this.options);
  }

  getShiftRoster(shiftRosterID: number): Observable<ShiftRoster> {
    return this.http.get<ShiftRoster>(AppSettings.API_ENDPOINT + 'shift_roster/' + shiftRosterID, this.options);
  }

  createShiftRoster(shiftRoster: ShiftRoster): Observable<ShiftRoster> {
    return this.http.post<ShiftRoster>(AppSettings.API_ENDPOINT + 'shift_roster', JSON.stringify(shiftRoster), this.options);
  }

  updateShiftRoster(shiftRoster: ShiftRoster): Observable<ShiftRoster> {
    return this.http.put<ShiftRoster>(AppSettings.API_ENDPOINT + 'shift_roster/' + shiftRoster.id, JSON.stringify(shiftRoster), this.options);
  }

  deleteShiftRoster(shiftRosterID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'shift_roster/' + shiftRosterID, this.options);
  }
}
