import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {ContentFile} from '../models/content_file.model';
import {HttpClient} from '@angular/common/http';
import { Content } from '../models/content.model';

@Injectable()
export class ContentFileService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('ContentFileService Ready...');
  }

  getContentFiles(): Observable<ContentFile[]> {
    return this.http.get<ContentFile[]>(AppSettings.API_ENDPOINT + 'content_file', this.options);
  }

  getContentFile(contentFileID: number): Observable<ContentFile> {
    return this.http.get<ContentFile>(AppSettings.API_ENDPOINT + 'content_file/' + contentFileID, this.options);
  }

  getContentFilePage(contentFileID: number, pageNumber: number, imageFormat: string, size: number): Observable<Content> {
    return this.http.get<Content>(AppSettings.API_ENDPOINT + 'content_file/' + contentFileID + '/page/' + pageNumber
      + '/' + imageFormat + '/' + size, this.options);
  }

  createContentFile(contentFileID: number, contentFile: ContentFile): Observable<ContentFile> {
    return this.http.post<ContentFile>(AppSettings.API_ENDPOINT + 'content_file/' + contentFileID, JSON.stringify(contentFile), this.options);
  }

  updateContentFile(contentFile: ContentFile): Observable<ContentFile> {
    return this.http.put<ContentFile>(AppSettings.API_ENDPOINT + 'content_file/' + contentFile.id, JSON.stringify(contentFile), this.options);
  }

  deleteContentFile(contentFileID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'content_file/' + contentFileID, this.options);
  }

    getContentFilePageBlob(contentFileID: number, pageNumber: number, imageFormat: string, size: number): Observable<Blob> {
        return this.http.get(AppSettings.API_ENDPOINT + 'content_file/' + contentFileID + '/page/' + pageNumber + '/' + imageFormat +
            '/' + size, {responseType: 'blob'});
    }
}
