<div class="container">
  <div class="row">
    <div class="col-12 pl-0 pt-2">
      <h5>
        <i class="mr-2">Title:</i>
        <ng-container *ngIf="content else compulsorycontent">
          <b >{{content.title}}</b>
        </ng-container>
        <ng-template #compulsorycontent>
          <b *ngFor="let content of compulsoryContent let i = index">
            {{i === compulsoryContent.length -1 ? content.title : content.title + ', '}}
          </b>
        </ng-template>
      </h5>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col-4 pl-0">
      <mat-form-field appearance="outline" class="w-100 no-subscript">
        <mat-label>Search</mat-label>
        <input matInput autocomplete="off" placeholder="Start typing a name.." [(ngModel)]="workerSearchTerm">
        <mat-icon class="mr-2" matPrefix>search</mat-icon>
        <span matSuffix>
          <button *ngIf="workerSearchTerm" mat-icon-button (click)="workerSearchTerm = ''">
            <mat-icon matTooltip="clear">clear</mat-icon>
          </button>
        </span>
      </mat-form-field>
    </div>
    <div class="col-6 p-0 d-flex align-items-center stretch-button-group">
      <mat-button-toggle-group [(ngModel)]="selectedCrewID" name="Crew" class="w-50">
        <mat-button-toggle (click)="updateFilter(0)" [value]="0">All</mat-button-toggle>
        <mat-button-toggle *ngFor="let mainCrew of mainCrews" (click)="updateFilter(mainCrew.id)" [value]="mainCrew.id">
          {{mainCrew.abbrev}}</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-form-field appearance="outline" class="w-50 no-subscript remove-left-border">
        <mat-label>Select Supporting Crews</mat-label>
        <mat-select [(ngModel)]="selectedCrewID" placeholder="Select Supporting Crews">
          <mat-option *ngFor="let supportCrew of supportCrews" (click)="updateFilter(supportCrew.id)"
            [value]="supportCrew.id">{{supportCrew.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-2 pl-3">
      <mat-radio-group class="radio-group" [(ngModel)]="attendeeSelect" (change)="updateAttendeesFilter()">
        <mat-radio-button [value]="null">All</mat-radio-button>
        <mat-radio-button [value]="true">Workers Viewed</mat-radio-button>
        <mat-radio-button [value]="false">Workers Not Viewed</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <ng-container *ngFor="let crew of crewsFiltered">
    <ng-container *ngIf="crewHasWorkers(crew)">
      <div class="card border-success my-3">
        <!--[class.border-success]="crew.attendance === '100%'">-->
        <div class="card-body">
          <div class="row mt-0">
            <div class="col-6">
              <h5>{{crew.name}}</h5>
            </div>
            <div class="col-6 text-right">
              <ng-container *ngIf="checkCoverageApplicable(); else invalidCoverage">
                <h5 *ngIf="getCoverage(crew.id) !== null; else coverageLoading"
                  [ngClass]="checkCoverage(crew.id) ? 'text-success' : 'text-info'">{{getCoverage(crew.id)}}%</h5>
                <ng-template #coverageLoading>
                  -
                </ng-template>
              </ng-container>
              <ng-template #invalidCoverage>
                Not Approved
              </ng-template>
            </div>
            <div class="col-12 pt-0">
              <mat-chip-listbox>
                <span *ngFor="let worker of workersTypeAhead()">
                  <mat-chip *ngIf="worker && workerInCrew(worker.id, crew.id)" color="primary" selected>
                    <span>
                      <span *ngIf="attended(worker.id); else absent">
                        <mat-icon class="text-success">check_circle</mat-icon>
                      </span>
                      <ng-template #absent>
                        <span>
                          <mat-icon class="text-danger">remove_circle</mat-icon>
                        </span>
                      </ng-template>
                      {{worker.first_name}} {{worker.surname}}
                    </span>
                  </mat-chip>
                </span>
              </mat-chip-listbox>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

</div>
