import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Session } from '../../../models/session.model';
import { SessionService } from '../../../services/session.service';
import { Router } from '@angular/router';
import { ShiftDefinition } from '../../../models/shift_definition.model';
import { ShiftDefinitionService } from '../../../services/shift_definition.service';
import { UserService } from '../../../services/user.service';
import { UserName} from '../../../models/user.model';
import { Log } from '../../../helpers/log.helper';
import { SessionMetric } from '../../../models/session_metric.model';
import { SessionMetricService } from '../../../services/session_metric.service';
import { SessionAttendance } from '../../../models/attendance.model';
import { AttendanceService } from '../../../services/attendance.service';
import {formatDate} from "@angular/common";
import {DialogsService} from "../../../services/dialogs.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-sessions-library',
  templateUrl: './session-library.component.html',
  styleUrls: ['./session-library.component.scss']
})
export class SessionLibraryComponent implements OnInit {

  sessions: Session[] = [];
  sessionMetrics: SessionMetric[] = [];
  shiftDefinitions: ShiftDefinition[] = [];
  userNames: UserName[];
  userID: number;
  sessionType: number;
  sessionsAttendance: SessionAttendance[] = [];

  // Table parameters
  displayedColumns: string[] = ['shift_date', 'presentation_type', 'created_by', 'attendance', 'actions'];
  dataSource: MatTableDataSource<Session>;
  @ViewChild(MatSort) sort: MatSort;
  filterValues: FilterValues = {
    title: null,
    shiftDate: null,
    userID: null,
    sessionType: null
  };

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 25, 50];

  constructor(private router: Router,
              private attendanceService: AttendanceService,
              private sessionService: SessionService,
              private sessionMetricService: SessionMetricService,
              private userService: UserService,
              private shiftDefinitionService: ShiftDefinitionService,
              private dialogsService: DialogsService) {
  }

  ngOnInit() {
    // Defaults
    this.userID = -1;  // All
    this.sessionType = -1;  // All

    this.userService.getUserNames().subscribe(userNames => {
      this.userNames = userNames;
      this.sessionService.getSessions().subscribe((sessions: Session[]) => {

        this.sessions = sessions;

        this.attendanceService.getSessionsAttendance().subscribe(sessionsAttendance => {
          this.sessionsAttendance = sessionsAttendance;
        });

        this.shiftDefinitionService.getShiftDefinitions().subscribe(shiftDefinitions => {
          this.shiftDefinitions = shiftDefinitions;
          this.initialiseMatTable(sessions);
        });
      });
    });

    this.sessionMetricService.getSessionMetrics().subscribe(sessionMetrics => {
      this.sessionMetrics = sessionMetrics;
    });
  }

  initialiseMatTable(sessions: Session[]) {
    this.dataSource = new MatTableDataSource(sessions);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'ctime') {
        if (!item) {
          return item[property];
        }
        return item.ctime;
      } else {
        return item[property];
      }
    };

    this.sort.sort(({ id: 'ctime', start: 'desc'}) as MatSortable);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data: {id: number, title: string, shift_date: string, shift_definition_id: number, user_id: string},
                                       dataFilter: string) => {
      const filters = JSON.parse(dataFilter);

      // Filters table based on type-ahead, archived switch, and drop-down states
      let shiftFilter = null;
      if (data.shift_date && this.filterValues.shiftDate) {
        const shiftDate = new Date(data.shift_date);
        const filterDate = new Date(filters.shiftDate);
        shiftFilter = shiftDate.toLocaleDateString().localeCompare(filterDate.toLocaleDateString());
      } else if (this.filterValues.shiftDate) {
        shiftFilter = 1;  // This prevents training sessions showing when a date selected
      }
      let dateName = '';
      if (!data.title) {
        dateName = formatDate(data.shift_date, 'dd MMMM YYYY', 'en').concat(' - ', this.shiftDefinitions.filter(sd => sd.id === data.shift_definition_id)[0].name)
      }

      return (!this.filterValues.title || (data.title && data.title.toLowerCase().includes(this.filterValues.title.toLowerCase())) ||
        (!data.title && dateName.toLowerCase().includes(this.filterValues.title.toLowerCase())))
        && (!shiftFilter || shiftFilter === 0)
        && (!filters.userID || data.user_id === filters.userID)
        && (filters.sessionType === null || this.isPrestart(data.id) === !!filters.sessionType)
    };
    this.updateFilters();
  }

  updateFilter(criteria: number) {
    if (criteria < 0) {
      return null;  // All
    }
    return criteria;
  }

  // Filtering
  updateFilters(): void {
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  getUser(ID: number): string {
    return (this.userNames.filter(u => u.id === this.sessions.filter(s => s.id === ID)[0].user_id)[0].name);
  }

  navigate(partialPath: string, ID: number, type: string = '') {
    const sessions = this.sessions.filter(s => s.id === ID);
    if (type.length > 0) {
      this.router.navigate(['/session/', partialPath, sessions[0].id, type]);
    } else {
      this.router.navigate(['/session/', partialPath, sessions[0].id]);
    }
  }

  getShift(ID: number): string {
    const sessions = this.sessions.filter(s => s.id === ID);
    return this.shiftDefinitions.filter(sd => sd.id === sessions[0].shift_definition_id)[0].name;
  }

  getType(ID: number): string {
    const sessions = this.sessions.filter(s => s.id === ID);
    return sessions[0].prestart_type_name;
  }

  isPrestart(ID: number): boolean {
    return this.sessions.filter(s => s.id === ID)[0].shift_date !== null;
  }

  checkValidMetrics(ID: number) {
    return this.sessionMetrics.filter(sm => sm.session_id === ID).length > 0
  }

  getAttendance(ID: number): string {
    const sessionAttendance = this.sessionsAttendance.filter(sa => sa.session_id === this.sessions.filter(s => s.id === ID)[0].id);

    // Attendance not applicable to un-approved content or content flagged as anytime
    if (!sessionAttendance || sessionAttendance.length < 1) {
      return null;
    }
    if (!(sessionAttendance[0].attendance_percent > 0.0)) {
      return 'Not Shown';
    }
    return sessionAttendance[0].attendance_percent.toString().concat('%');
  }

  getEditable(session: Session) {
    return session.is_editable;
  }

  checkAttendance(ID: number): string {
    return this.getAttendance(ID) === 'Not Shown' ? 'text' :
      (this.getAttendance(ID) === '100%' ? 'text-success' : 'text-danger')
  }
}

export class FilterValues {
  title: string;
  shiftDate: null;
  userID: number;
  sessionType: number;
}
