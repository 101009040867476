<div class="container">
  <div class="row col-12 d-flex align-items-center">
    <div class="mr-auto">
        <h5>User Management <ng-container *ngIf="!admin || isView()"> [Read Only]</ng-container></h5>
    </div>
    <div class="ml-auto">
        <ng-container *ngIf="!isView()">
            <ng-container *ngIf="isEdit(); else notEdit">
                <button mat-stroked-button color="accent" [disabled]="!checkValid()" (click)="editUser()">Save Changes</button>
            </ng-container>
            <ng-template #notEdit>
                <button mat-stroked-button color="accent" [disabled]="!checkValid()" (click)="createUser()">Create</button>
            </ng-template>
            <button mat-stroked-button color="accent" (click)="navigateBack()">Back</button>
        </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <p>Details</p>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input matInput autocomplete="off" [disabled]="isView()" [(ngModel)]="user.name">
          </mat-form-field>
          <ng-container *ngIf="admin">
            <mat-form-field *ngIf="admin && AppSettings.INTEGRATED_AUTHENTICATION" appearance="outline" class="w-100">
              <mat-label>Remote Username</mat-label>
              <input matInput autocomplete="off" [disabled]="isView()" [(ngModel)]="user.remote_system_identity">
            </mat-form-field>
            <mat-form-field *ngIf="admin" appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput type="email" autocomplete="off" [disabled]="isView()" [(ngModel)]="user.email">
            </mat-form-field>
            <mat-form-field *ngIf="admin && !AppSettings.INTEGRATED_AUTHENTICATION" appearance="outline" class="w-100">
              <mat-label>Password</mat-label>
              <input matInput type="password" autocomplete="off" [disabled]="isView()" [(ngModel)]="user.password">
            </mat-form-field>
            <div class="row d-flex align-items-top mt-3">
              <div class="col-2">
                <p>Status</p>
              </div>
              <div class="col-10">
                <mat-radio-group class="d-flex flex-column" [disabled]="isView()" [(ngModel)]="user.enabled">
                  <mat-radio-button [value]="true">Active</mat-radio-button>
                  <mat-radio-button [value]="false" class="pt-2">Inactive</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="col-12 p-0 pb-3">
        <div class="card">
          <div class="card-body">
            <div class="row d-flex align-items-center justify-content between mt-0">
              <p>User role</p>
              <!--<mat-icon class="text-success ml-auto d-none d-md-block" (click)="isShown = !isShown">info</mat-icon>-->
            </div>
            <mat-radio-group [disabled]="isView()" aria-label="Select an option" class="d-flex flex-column" [(ngModel)]="user.role">
              <ng-container *ngFor="let role of roles">
                <mat-radio-button class="ml-4 pl-1 mb-3" [value]="role.role">
                  <p>{{role.role}}</p>
                </mat-radio-button>
                <mat-hint class="ml-4 pl-1 mb-3">{{role.description}}</mat-hint>
              </ng-container>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 d-none d-md-block" *ngIf="isShown">
        <div class="card">
          <table class="ctm-table border-0">
            <tr>
              <th class="role">Role Type</th>
              <th>Create</th>
              <th>Approve</th>
              <th>Present</th>
              <th>Report</th>
              <th>Manage</th>
            </tr>
            <tr>
              <td>Author</td>
              <td rowspan="5">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
            </tr>
            <tr>
              <td>Approver</td>
              <td>
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td rowspan="4">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
            </tr>
            <tr>
              <td>Presenter</td>
              <td>
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td>
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td rowspan="3">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
            </tr>
            <tr>
              <td>Manager</td>
              <td>
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td>
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td>
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td rowspan="2">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
            </tr>
            <tr>
              <td>Admin</td>
              <td rowspan="1">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td rowspan="1">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td rowspan="1">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td rowspan="1">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
              <td rowspan="1">
                <mat-icon class="text-secondary">check_circle</mat-icon>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
