import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Titlepage} from '../models/titlepage.model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class TitlepageService extends CoreService {
    constructor(protected auth: AuthenticationService, protected http: HttpClient) {
        super(auth);
        console.log('TitlepageService Ready...');
    }

    getTitlepages(): Observable<Titlepage[]> {
        return this.http.get<Titlepage[]>(AppSettings.API_ENDPOINT + 'titlepage', this.options).pipe(
            map(ts => ts.map(t => Titlepage.assign(t)))
        );
    }

    getTitlepage(titlepageID: number): Observable<Titlepage> {
        return this.http.get<Titlepage>(AppSettings.API_ENDPOINT + 'titlepage/' + titlepageID, this.options).pipe(
            map(t => Titlepage.assign(t))
        );
    }

    createTitlepage(titlepage: Titlepage): Observable<Titlepage> {
        return this.http.post<Titlepage>(AppSettings.API_ENDPOINT + 'titlepage', JSON.stringify(titlepage), this.options);
    }

    updateTitlepage(titlepage: Titlepage): Observable<Titlepage> {
        return this.http.put<Titlepage>(AppSettings.API_ENDPOINT + 'titlepage/' + titlepage.id, JSON.stringify(titlepage), this.options);
    }

    deleteTitlepage(titlepageID: number): Observable<any> {
        return this.http.delete<any>(AppSettings.API_ENDPOINT + 'titlepage/' + titlepageID, this.options);
    }
}
