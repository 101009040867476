export class Link {
  constructor(public id: any = null,
              public user_id: any = null,
              public name: string = '',
              public link: string = ''
              ) {}

  public static assign(l: Link) {
    const n = Object.assign(new Link(), l);

    return n;
  }
}
