import { ContentLink } from './content_link.model';
import { ContentHidden } from './content_hidden.model';
import { Log } from '../helpers/log.helper';
import { ContentFile } from './content_file.model';
import {Subject} from "rxjs";

export class Content {
  constructor(public id: any = null,
              public user_id: any = null,
              public delivery_id: any = null,
              public category_id: any = null,
              public shift_definition_id: any = null,
              public title: string = '',
              public valid_from: any = null,
              public expiry: any = null,
              public archive_date: any = null,
              public approver_id: any = null,
              public approver_date: any = null,
              public state: string = '',
              public is_compulsory: boolean = false,
              public ctime: any = null,
              public mtime: any = null,
              public content_file_id: any = null,
              public content_file: ContentFile = null,
              public is_editable: boolean = false,
              public group: any = null
  ) {}

  public static assign(c: Content) {
    const n = Object.assign(new Content(), c);

    if (c.content_file) {
      n.content_file = ContentFile.assign(c.content_file)
    }

    return n;
  }

  public static typeAhead(contents: Content[], searchTerm: string, numberShown: number = null): Content[] {
    if (contents.length < 1) {
      return contents;
    }

    // Sorted alphabetically
    contents = contents.sort((a, b) => a.title
      .localeCompare(b.title));

    if (!numberShown) {
      if (searchTerm === '') {
        return (contents);
      } else {
        return (contents.filter(term => term.title.toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1));
      }
    } else {

      if (searchTerm === '') {
        return (contents.slice(0, numberShown));
      } else {
        return (contents.filter(term => term.title.toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1).slice(0, numberShown));
      }
    }
  }
}

export class ContentPage {
  constructor(public contentID: number = null,
              public contentFile: ContentFile = null,
              public page: number = null,
              public linkID: number = null,
              public numberLinksPrior: number = null,
              public format: string = '',
              public size: number = null,
              public image: any = null,
              public imageSubject: Subject<number> = new Subject<number>()) { }

  public static getPages(contentLinks: ContentLink[], contentID: number, contentFile: ContentFile, pageWidth: number,
                         contentHidden: ContentHidden[] = []): ContentPage[] {
    // Sort contentLinks by insert_at then link_id
    contentLinks.sort((a, b) => a.insert_at - b.insert_at || a.link_id - b.link_id);

    const pageCount = contentFile ? contentFile.page_count : 0;
    let numberLinksPrior = 0;
    let pageIdx = 0;
    let contentLinksForPage = contentLinks;
    let newPage: ContentPage;
    const pages: ContentPage[] = [];

    // Links only
    if (pageCount < 1) {
      contentLinksForPage = contentLinks.filter(cl => cl.insert_at !== null);
      // Sort to have nulls right at end
      contentLinks.filter(cl => cl.insert_at === null).forEach((cl) => {
        contentLinksForPage.push(cl);
      });
      if (contentLinksForPage.length > 0) {
        for (let i = 0; i < contentLinksForPage.length; i++) {
          newPage = new ContentPage(contentID, contentFile, pageIdx + 1, contentLinksForPage[i].link_id, numberLinksPrior, 'webp', pageWidth);
          pages.push(newPage);
          numberLinksPrior++;
          pageIdx++;
        }
      }
    } else {
      // Content only or Content and Links
      for (let loopIdx = 0; loopIdx < pageCount; loopIdx++) {

        // Add all links with 0 for insert_at at beginning
        if (loopIdx === 0) {
          contentLinksForPage = contentLinks.filter(cl => cl.insert_at === loopIdx);
          if (contentLinksForPage.length > 0) {
            for (let i = 0; i < contentLinksForPage.length; i++) {
              newPage = new ContentPage(contentID, contentFile, pageIdx + 1, contentLinksForPage[i].link_id, numberLinksPrior, 'webp', pageWidth);
              pages.push(newPage);
              numberLinksPrior++;
              pageIdx++;
            }
          }
        }

        contentLinksForPage = contentLinks.filter(cl => cl.insert_at === (loopIdx + 1));
        if (contentLinksForPage.length > 0) {
          for (let i = 0; i < contentLinksForPage.length; i++) {
            newPage = new ContentPage(contentID, contentFile, pageIdx + 1, contentLinksForPage[i].link_id, numberLinksPrior, 'webp', pageWidth);
            pages.push(newPage);
            numberLinksPrior++;
            pageIdx++;
          }
        }

        // Add pages for slides
        newPage = new ContentPage(contentID, contentFile, pageIdx + 1, null, numberLinksPrior, 'webp', pageWidth);
        pages.push(newPage);
        pageIdx++;

        // Place links with null for insert_at at end
        if (loopIdx === pageCount - 1) {
          contentLinksForPage = contentLinks.filter(cl => cl.insert_at > pageCount);
          // Sort to have nulls right at end
          contentLinks.filter(cl => cl.insert_at === null).forEach((cl) => {
            contentLinksForPage.push(cl);
          });
          if (contentLinksForPage.length > 0) {
            for (let i = 0; i < contentLinksForPage.length; i++) {
              newPage = new ContentPage(contentID, contentFile, pageIdx + 1, contentLinksForPage[i].link_id, numberLinksPrior, 'webp', pageWidth);
              pages.push(newPage);
              numberLinksPrior++;
              pageIdx++;
            }
          }
        }
      }
    }

    // With the array of page objects created remove the pages specified to be hidden during a session.
    const hideIndices = [];
    if (contentHidden.length > 0) {
      contentHidden.forEach((ch) => {
        hideIndices.push(ch.page_idx);
      });
    }
    // Sort indices in descending order to prevent splice changing indices after each remove
    hideIndices.sort((a, b) => b - a);
    hideIndices.forEach((hi) => {
      pages.splice(hi, 1);
    });

    Log.d(pages)
    return pages
  }
}

export class ContentShiftRequest {
  constructor(public shift_date: any = null,
              public shift_definition_id: number = null
  ) {}
}

export class ContentSchedule {
  constructor(public content_id: any = null,
              public is_mandatory: boolean = false,
  ) {}
}
