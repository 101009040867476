export class SessionWorker {
  constructor(public id: any = null,
              public session_id: any = null,
              public worker_id: any = null,
              public login_type: string = '',
              public group: number = null
              ) {}

  public static assign(sw: SessionWorker) {
    const n = Object.assign(new SessionWorker(), sw);

    return n;
  }
}
