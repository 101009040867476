import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Roster} from '../models/roster.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RosterService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('RosterService Ready...');
  }

  getRosters(): Observable<Roster[]> {
    return this.http.get<Roster[]>(AppSettings.API_ENDPOINT + 'roster', this.options);
  }

  getRoster(rosterID: number): Observable<Roster> {
    return this.http.get<Roster>(AppSettings.API_ENDPOINT + 'roster/' + rosterID, this.options);
  }

  createRoster(roster: Roster): Observable<Roster> {
    return this.http.post<Roster>(AppSettings.API_ENDPOINT + 'roster', JSON.stringify(roster), this.options);
  }

  updateRoster(roster: Roster): Observable<Roster> {
    return this.http.put<Roster>(AppSettings.API_ENDPOINT + 'roster/' + roster.id, JSON.stringify(roster), this.options);
  }

  deleteRoster(rosterID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'roster/' + rosterID, this.options);
  }
}
