export class ContentFile {
  constructor(public id: any = null,
              public filename: string = '',
              public page_count: number = 0
              ) {}

  public static assign(cf: ContentFile) {
    const n = Object.assign(new ContentFile(), cf);

    return n;
  }
}
