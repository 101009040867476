<div id="login-page">
    <div class="body-container">
        <div class="col-4-sm mx-auto">
            <div *ngIf="error" class="alert alert-danger fade show col-12 mx-auto p-3 text-center" role="alert">
                {{ error }}
            </div>
            <div class="row">
                <div class="card mat-elevation-z8 col-12 p-5 text-center">
                    <img src="/assets/logo.svg" class="logo w-25 mx-auto m-3" />
                    <h1>SmartPrestart</h1>
                    <p class="mx-auto m-3">Please sign in to access SmartPreStart</p>
                    <p class="text-danger" *ngIf="showSessionExpiredMessage && !error">Session expired! Please log in
                        again.</p>
                    <div class="m-3 mx-auto">
                        <mat-form-field color="accent" appearance="outline" class="w-100">
                            <mat-label>Email</mat-label>
                            <input matInput autocomplete="off" placeholder="Email" [(ngModel)]="loginData.username"
                                [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                        <mat-form-field color="accent" appearance="outline" class="w-100">
                            <mat-label>Password</mat-label>
                            <input matInput autocomplete="off" type="password" placeholder="Password"
                                [(ngModel)]="loginData.password" [ngModelOptions]="{standalone: true}"
                                (keyup.enter)="onLogin()">
                        </mat-form-field>
                    </div>
                    <div class="text-center">
                        <button mat-flat-button color="accent" class="w-25 mx-auto mb-3"
                            [disabled]="isLoading || !loginData.password" (click)="onLogin()">
                            <span *ngIf="!isLoading">Login</span>
                            <span *ngIf="isLoading">
                                <mat-spinner style="margin:0 auto" color="accent" [diameter]="30"></mat-spinner>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
