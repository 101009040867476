import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {Log} from "../../../helpers/log.helper";

@Component({
  selector: "app-compulsory-content-workers",
  templateUrl: "./workers.component.html",
  styleUrls: ["./workers.component.scss"]
})
export class DialogWorkers {

  viewWorkers = null;

  constructor(public dialogRef: MatDialogRef<DialogWorkers>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    // sort workers by name, easier to find
    DialogWorkers.sortWorkers(data.staying)
    DialogWorkers.sortWorkers(data.leaving)

    if (data.staying.length > data.leaving.length) {
      this.viewWorkers = 0;
    } else {
      this.viewWorkers = 1;
    }
  }

  private static sortWorkers(data) {
    data.sort(function (a, b) {
      const nameA = a.first_name + " " + a.surname
      const nameB = b.first_name + " " + b.surname
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }
}
