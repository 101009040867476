<!--<ng-container *ngIf="isContentPreview; else notContentPreview">-->
<!--  <app-content-view  [contentIDInput]="contentID"></app-content-view>-->
<!--</ng-container>-->
<!--<ng-template #notContentPreview>-->
<div class="container">
    <div class="row">
        <div class="col-3 d-flex align-items-center">
            <ng-container *ngIf="viewType === 'edit'; else notEditLabel">
                <h5>Edit Session</h5>
            </ng-container>
            <ng-template #notEditLabel>
                <h5>Create New Presentation</h5>
            </ng-template>
        </div>
        <div class="col-9 p-0 button-row text-right">
      <span class="text-danger" [hidden]="!checkInvalid()">
        <span class="mx-2" *ngIf="!isPrestartSession && (!session || !session.title)">*Training title is required</span>
        <span class="mx-2" *ngIf="slides.length < 1">*Slides are required</span>
      </span>
            <ng-container *ngIf="viewType === 'edit'; else notEditButtons">
                <ng-container *ngIf="canSmartSplit()">
                    <ng-container *ngIf="isSmartSplit(); else smartSplit">
                        <mat-icon *ngIf="!isBestSplit()" class="pt-1 mx-2 smartSplitInfo"
                                  matTooltip="Not Most Efficient Split">info
                        </mat-icon>
                        <button mat-flat-button color="accent" [disabled]="checkReset()" (click)=resetContent()>
                            Un-Split
                        </button>
                        <button mat-flat-button color="accent" [disabled]="checkSmartSplit" (click)=smartSplitContent()>
                            Smart Split
                        </button>
                    </ng-container>
                    <ng-template #smartSplit>
                        <button mat-flat-button color="accent" [disabled]="checkSmartSplit" (click)=smartSplitContent()>
                            Smart Split
                        </button>
                    </ng-template>
                </ng-container>
                <button mat-stroked-button color="accent" [disabled]="checkInvalid()" (click)="saveSession()">Save
                </button>
                <button mat-stroked-button color="accent" [disabled]="checkInvalid()"
                        (click)="router.navigate(['/session', 'show', session.id, 'preview']);">Preview
                </button>
            </ng-container>
            <ng-template #notEditButtons>
                <ng-container *ngIf="canSmartSplit()">
                    <ng-container *ngIf="isSmartSplit(); else smartSplit">
                        <mat-icon *ngIf="!isBestSplit()" class="pt-1 mx-2 smartSplitInfo"
                                  matTooltip="Not Most Efficient Split">info
                        </mat-icon>
                        <button mat-flat-button color="accent" [disabled]="checkReset()" (click)=resetContent()>
                            Un-Split
                        </button>
                        <button mat-flat-button color="accent" [disabled]="checkSmartSplit" (click)=smartSplitContent()>
                            Smart Split
                        </button>
                    </ng-container>
                    <ng-template #smartSplit>
                        <button mat-flat-button color="accent" [disabled]="checkSmartSplit" (click)=smartSplitContent()>
                            Smart Split
                        </button>
                    </ng-template>
                </ng-container>
                <button mat-flat-button color="accent" [disabled]="checkInvalid()" (click)="saveSession()">Create
                </button>
                <button mat-stroked-button color="accent" [disabled]="checkInvalid()" (click)="saveAndShowSession()">
                    Create &
                    Preview
                </button>
            </ng-template>
            <button mat-stroked-button [routerLink]="['/session/library']">Cancel</button>
        </div>
    </div>

    <div class="row">
        <div class="col-5 pl-0">
            <div class="card border-success"
                 [class.border-warning]="!isPrestartSession && (!session || !session.title)">
                <div class="card-body">
                    <h5 class="mb-4">Presentation Details</h5>
                    <div class="col-12 pb-2">
                        <mat-hint class="text-danger" *ngIf="this.validShift !== null">A Prestart already exists for
                            this shift.
                            Please select another shift/prestart type.
                        </mat-hint>
                    </div>
                    <div class="row p-0">
                        <div class="col-6">
                            <ng-container *ngIf="isPrestartSession; else trainingSession">
                                <div class="col-12 p-0">
                                    <mat-form-field appearance="outline" class="col-12 p-0">
                                        <mat-label>Shift Date</mat-label>
                                        <input matInput autocomplete="off" [min]="minDate" required
                                               placeholder="DD/MM/YY"
                                               [(ngModel)]="shiftDate" (dateChange)="updateShiftDate()"
                                               [matDatepicker]="shiftPicker">
                                        <mat-datepicker-toggle matSuffix [for]="shiftPicker"></mat-datepicker-toggle>
                                        <mat-datepicker #shiftPicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-template #trainingSession>
                                <div class="col-12 p-0">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Title</mat-label>
                                        <input matInput autocomplete="off" required type="text"
                                               [(ngModel)]="session.title">
                                    </mat-form-field>
                                </div>
                            </ng-template>
                        </div>
                        <div class="col-6" *ngIf="isPrestartSession">
                            <ng-container *ngIf="multiplePrestartTypesActive()">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Select Prestart Type</mat-label>
                                    <mat-select [(ngModel)]="session.prestart_type_id"
                                                (ngModelChange)="updateShiftDate()">
                                        <mat-option *ngFor="let prestartType of prestartTypes" [value]="prestartType.id"
                                                    class="flex-fill">{{prestartType.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row p-0">
                        <div class="col-6">
                            <p class="mt-2">Select session type</p>
                            <mat-button-toggle-group [(ngModel)]="isPrestartSession" (change)="updateSessionType()"
                                                     name="sessionType" class="w-100 d-flex">
                                <mat-button-toggle [value]="false" class="flex-fill">Training</mat-button-toggle>
                                <mat-button-toggle [value]="true" class="flex-fill">PreStart</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="col-6" *ngIf="isPrestartSession">
                            <p class="mt-2">Select session shift</p>
                            <mat-button-toggle-group name="shift" [(ngModel)]="this.session.shift_definition_id"
                                                     (change)="updateShiftDate()" class="w-100 d-flex">
                                <mat-button-toggle *ngFor="let shiftDefinition of shiftDefinitions"
                                                   [value]="shiftDefinition.id"
                                                   class="flex-fill">{{shiftDefinition.name}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="card col-12">
                    <div class="card-body">
                        <h5 class="mb-4">Content Library</h5>

                        <mat-form-field appearance="outline" class="w-100 mb-0">
                            <mat-label>Search Content</mat-label>
                            <input matInput placeholder="Start search by typing.." type="text" autocomplete="off"
                                   [(ngModel)]="filterValues.title">
                            <mat-icon matPrefix>search</mat-icon>
                            <span matSuffix>
                <mat-slide-toggle class="pr-1 align-bottom mb-1" type="checkbox" [(ngModel)]="filterValues.showArchived"
                                  (change)="updateFilters()">
                  <small>Archived</small>
                </mat-slide-toggle>
              </span>
                        </mat-form-field>
                        <div class="d-flex align-items-center">
                            <mat-form-field appearance="outline" class="w-50">
                                <mat-label>Category</mat-label>
                                <mat-select [(ngModel)]="filterValues.categoryID" placeholder="Category">
                                    <mat-option (click)="updateFilters()" [value]="null">All</mat-option>
                                    <mat-option *ngFor="let category of categorys" (click)="updateFilters()"
                                                [value]="category.id">
                                        {{category.title}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-50">
                                <mat-label>Uploaded by</mat-label>
                                <mat-select [(ngModel)]="filterValues.userID" placeholder="Uploaded By">
                                    <mat-option (click)="updateFilters()" [value]="null">All</mat-option>
                                    <mat-option *ngFor="let user of userNames" (click)="updateFilters()"
                                                [value]="user.id">
                                        {{user.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                    </div>

                    <ng-container *ngIf="contentInitialised && slidesInitialised && updateFilters().length < 1">
                        <p class="text-center">
                            No available valid and approved content.
                        </p>
                    </ng-container>
                    <ng-container *ngIf="!contentInitialised || !slidesInitialised; else showContent">
                        <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
                        <p class="text-center">Content Loading, please wait..</p>
                    </ng-container>
                    <ng-template #showContent>
                        <div class="table-wrapper-scroll-y library-scrollbar">
                            <table class="ctm-table border-0">
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Content</th>
                                    <th>Category</th>
                                    <th>Delivery</th>
                                    <th>Uploaded By</th>
                                    <th class="actions">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let content of updateFilters()">
                                    <td>
                                        <small
                                            (click)="router.navigate(['/content/', 'show', content.id, ''])">{{content.title}}</small>
                                    </td>
                                    <td>
                                        <small class="mb-0 mt-1">
                                            {{getCoverage(content.id)}}%
                                        </small>
                                    </td>
                                    <td>
                                        <small>{{getCategory(content.id)}}</small>
                                    </td>
                                    <td class="deliverys">
                                        <p [ngStyle]="{'color': contentDelivery(content.delivery_id).colour}">
                                            {{contentDelivery(content.delivery_id).title}}</p>
                                        <small *ngIf="content.is_compulsory">
                                            <mat-icon class="ml-1"
                                                      matTooltip="This content is compulsory and must be presented this session">
                                                error
                                            </mat-icon>
                                        </small>
                                    </td>
                                    <td>
                                        <small>{{getUser(content.id)}}</small>
                                    </td>
                                    <td class="actions text-right">
                                        <button mat-icon-button (click)="addSlides(content.id)">
                                            <mat-icon class="text-secondary">add_circle</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </div>
            </div>

        </div>
        <div class="col-7 pr-0">
            <div class="card overflow" [class.border-success]="slides && slides.length > 0">
                <div class="card-body">
                    <ng-container *ngIf="isPrestartSession; else trainingLabel">
                        <h5 class="mb-4">PreStart Content</h5>
                    </ng-container>
                    <ng-template #trainingLabel>
                        <h5 class="mb-4">Training Content</h5>
                    </ng-template>
                    <!--          <div>-->
                    <!--            Select Default Title Page-->
                    <!--          </div>-->
                    <!--          <div class="row d-flex align-items-top justify-content-start m-2 mb-3">-->
                    <!--            <mat-checkbox class="col-1 m-0" (change)="updateTitlePage()" [(ngModel)]="isTitlepage"></mat-checkbox>-->
                    <!--            <img *ngIf="titlepage" class="thumbnail p-0" [ngStyle]="{'opacity' : isTitlepage ? 1 : 0.5}"-->
                    <!--              [src]=getTitlepage()>-->
                    <!--            &lt;!&ndash;              <div class="text-truncate col-12 sm-font">&ndash;&gt;-->
                    <!--            &lt;!&ndash;                Please contact your admin to change the default title page. </div>&ndash;&gt;-->
                    <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
                    <!--            <p class="mb-0 mt-1 ml-2 text-wrap">Default Title Page</p>-->
                    <!--          </div>-->
                    <mat-divider></mat-divider>
                    <div class="row d-flex align-items-top m-2 mb-3 ctm-table">
                        <div class="col-1 th">Order</div>
                        <div class="col-4 th">Content</div>
                        <div class="col-2 th">Coverage</div>
                        <div class="col-3 th">Delivery</div>
                        <div class="col-2 th">
                            <div class="text-right mr-3">Actions</div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <ng-container *ngIf="slidesInitialised; else loadingSlides">
                        <div class="row d-flex align-items-top m-2" *ngFor="let slide of slides; let i = index">
                            <ng-container *ngIf="i !== 0 && isNewGroup(i)">
                                <hr/>
                            </ng-container>
                            <div class="p-0 col-1">
              <span (click)="slides = moveItemInList(slides,i,true)">
                <a>
                  <mat-icon>arrow_drop_up</mat-icon>
                </a>
              </span>
                                <br>
                                <span (click)="slides = moveItemInList(slides,i,false)">
                <a>
                  <mat-icon>arrow_drop_down</mat-icon>
                </a>
              </span>
                            </div>
                            <ng-container *ngIf="slideFirstPage(slide.content_file); else noSlidePreview">
                                <img class="thumbnail p-0" (click)="router.navigate(['/content/','show',slide.id,''])"
                                     [src]=slideFirstPage(slide.content_file) alt="Preview Missing">
                            </ng-container>
                            <ng-template #noSlidePreview>
                                <div
                                    class="thumbnail bg-light p-0 d-flex justify-content-center align-items-center link-thumb">
                                    <mat-icon>launch</mat-icon>
                                </div>
                            </ng-template>
                            <div class="col-3 p-0">
                                <p class="mb-0 mt-1 text-wrap col-12">{{slide.title}}</p>
                                <ng-container *ngIf="slideFirstPage(slide.content_file); else noSlideFile">
                                    <div *ngIf="slide.content_file" matTooltip="{{getFilename(slide.content_file)}}"
                                         class="text-truncate col-12 sm-font">
                                        {{getFilename(slide.content_file)}}</div>
                                </ng-container>
                                <ng-template #noSlideFile>
                                    <div class="text-truncate col-12 sm-font">Links Only</div>
                                </ng-template>
                            </div>
                            <div *ngIf="slides" class="col-2">
                                <p class="mb-0 mt-1">
                                    {{getCoverage(slide.id)}}%</p>
                            </div>
                            <div *ngIf="deliverys && slides" class="col-2 deliverys">
                                <p class="mb-0 mt-1" [ngStyle]="{'color': contentDelivery(slide.delivery_id).colour}">
                                    {{contentDelivery(slide.delivery_id).title}}</p>
                                <small *ngIf="slide.is_compulsory">
                                    <mat-icon class="ml-1"
                                              matTooltip="This content is compulsory and must be presented this session">
                                        error
                                    </mat-icon>
                                </small>
                            </div>
                            <div class="col-2 text-right">
                                <!-- <mat-icon *ngIf="!checkValidExpiry(slide.id)" class="text-info"
                                  matTooltip="The date for this content has expired">error</mat-icon> -->
                                <!--              <mat-icon *ngIf="!isMandatoryContent(slide.id)" class="text-secondary" (click)="removeSlides(slide.id)">-->
                                <mat-icon class="text-secondary" (click)="removeSlides(slide.id)">
                                    remove_circle
                                </mat-icon>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #loadingSlides>
                        <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
                        <p class="text-center">Content Loading, please wait..</p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<!--</ng-template>-->
