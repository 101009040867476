import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import { ContentHidden } from '../models/content_hidden.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ContentHiddenService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('ContentHiddenService Ready...');
  }

  getContentHiddens(): Observable<ContentHidden[]> {
    return this.http.get<ContentHidden[]>(AppSettings.API_ENDPOINT + 'content_hidden', this.options);
  }

  getContentHidden(contentHiddenID: number): Observable<ContentHidden> {
    return this.http.get<ContentHidden>(AppSettings.API_ENDPOINT + 'content_hidden/' + contentHiddenID, this.options);
  }

  createContentHidden(contentHidden: ContentHidden): Observable<ContentHidden> {
    return this.http.post<ContentHidden>(AppSettings.API_ENDPOINT + 'content_hidden', JSON.stringify(contentHidden), this.options);
  }

  updateContentHidden(contentHidden: ContentHidden): Observable<ContentHidden> {
    return this.http.put<ContentHidden>(AppSettings.API_ENDPOINT + 'content_hidden/' + contentHidden.id, JSON.stringify(contentHidden),
      this.options);
  }

  deleteContentHidden(contentHiddenID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'content_hidden/' + contentHiddenID, this.options);
  }
}
