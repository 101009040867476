import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SessionService } from './services/session.service';
import { DialogsService } from './services/dialogs.service';
import { Log } from './helpers/log.helper';
import {AppSettings} from './app.settings';
import {User} from './models/user.model';
import {AuthenticationService} from './services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'SmartPreStart';
  showNavbar = true;
  authenticated = false;
  currentUser: User = null;
  roles = AuthenticationService.getRoles();
  userNavbar: any[] = [];
  deployedVersion = AppSettings.IS_PREMIUM ? 'Premium' : 'Basic';
  deployedSite = AppSettings.SITE_ACRONYM;
  integratedAuthentication = AppSettings.INTEGRATED_AUTHENTICATION;
  lineupSystemRoute = AppSettings.LINEUP_SYSTEM_ROUTE;
  lineupEnabled = AppSettings.LINEUP_ENABLED;

  navbar = [
    {text: 'Presentation', route: 'session/library', role: this.roles.filter(r => r.role !== 'AUTHOR' && r.role !== 'APPROVER')},
    {text: 'Content', route: 'content/library', role: this.roles},
    {text: 'Report', route: 'report', role: this.roles.filter(r => r.role === 'MANAGER' || r.role === 'ADMIN')},
    {text: 'Admin', name: 'adminmenu', items: [
        {text: 'Access Management', icon: 'person', route: 'access-management'},
        // {text: 'Content Management', icon: 'assessment', route: 'content-management'},
        {text: 'System Health', icon: 'verified_user', route: 'system-health'},
        {text: 'System Settings', icon: 'settings', route: 'system-settings'}
    ], role: this.roles.filter(r => r.role === 'ADMIN')}
  ];

  constructor ( private router:  Router,
                private prestartService: SessionService,
                private dialogsService: DialogsService,
                private auth: AuthenticationService) {
    router.events.subscribe((event) => (event instanceof NavigationEnd) && this.handleRouteChange())
  }

  ngOnInit(): void {
  }

  appRedirect(nav: string) {
    window.location.href = nav;
  }

  whitelisted(): boolean {
    return this.router.url.includes('no-network') || this.router.url.includes('no-access');
  }

  handleRouteChange = () => {
    // Hide the navbar in a fullscreen page
    this.showNavbar = !(this.router.url.includes('show-session') || this.router.url.includes('/show'));
    // Check this on route change to avoid the dreaded ExpressionChangedAfterItHasBeenCheckedError exception
    this.authenticated = this.auth.isAuthenticated();
    if (AppSettings.INTEGRATED_AUTHENTICATION) {
      if (this.currentUser == null) {
        this.auth.refreshWhoAmI().then(user => {
          if (user) {
            this.updateRoleNav(user.role);
          }
        }).catch(error => {
          Log.e(error);
          if (error.status === 0) {
            if (!this.whitelisted()) {
              this.router.navigate(['no-network']);
            }
          } else {
            if (!this.whitelisted()) {
              this.router.navigate(['no-access']);
            }
          }
        });
      }
    } else {
      this.currentUser = this.auth.getCurrentUser();
      if (this.currentUser) {
        this.updateRoleNav(this.currentUser.role);
      }
    }
  };

  updateRoleNav(role: string) {
    if (!this.navbar && !role) {
      this.userNavbar = [];
    }
    this.userNavbar = [];
    this.navbar.forEach((item) => {
      if (item && item.role.filter(r => r.role === role).length > 0) {
        this.userNavbar.push(item);
      }
    });
  }

  // Remove navbar when showing session
  isShowNavbar() {
    return this.showNavbar;
  }

  public openDialogConfirm(title: string = '', message: string = '', path: any[]) {

    let result: boolean;

    this.dialogsService
      .confirm(title, message)
      .subscribe(res => {
        result = res;
        if (result) {
          this.router.navigate(path);
        }
      });
  }
}
