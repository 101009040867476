<ng-container *ngIf="!isShowNavbar()">
  <div class="no-navbar">
    <router-outlet></router-outlet>
  </div>
</ng-container>
<ng-container *ngIf="isShowNavbar()">
  <mat-toolbar color="accent">
    <div class="col-12 d-flex align-items-center justify-content-end">
      <div class="mr-auto">
        <div class="button-row mr-3">
          <a class="navbar-brand"
            [routerLink]="authenticated && auth.getCurrentUser() && (auth.getCurrentUser().role === 'PRESENTER' ||
             auth.getCurrentUser().role === 'MANAGER' || auth.getCurrentUser().role === 'ADMIN') ? ['dashboard'] : ['/content/library']">
            <ng-container *ngIf="deployedVersion === 'Premium' else basicLogo">
              <img src="/assets/premium_logo.svg" height="40px" class="m-1">
            </ng-container>
            <ng-template #basicLogo>
              <img src="/assets/basic_logo.svg" height="40px" class="m-1">
            </ng-template>
<!--            <span class="text-white ml-3" routerLink="/">SmartPreStart</span>-->
          </a>
          <ng-container *ngIf="authenticated && userNavbar.length > 0">
            <ng-container *ngFor="let item of userNavbar">
              <button *ngIf="item.text != 'Admin'" mat-button [routerLink]="[item.route]">{{ item.text}}</button>
              <button *ngIf="auth.getCurrentUser().role && auth.isAdmin() && item.text == 'Admin'" mat-button
                class="mat-button-sm" [matMenuTriggerFor]="adminmenu"><span>{{ item.text}}</span>
                <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #adminmenu="matMenu" yPosition="below" overlapTrigger="false">
                <ng-container *ngFor="let nav of item.items">
                  <button mat-menu-item [routerLink]="[nav.route]">
                    <mat-icon>{{nav.icon}}</mat-icon>
                    <span>{{nav.text}}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
          </ng-container>
          <button class="lineup-btn" mat-stroked-button *ngIf="lineupEnabled" (click)="appRedirect(lineupSystemRoute)">
            <img src="/assets/lineup_logo.png" height="25px" class="mr-1">
            LineupBoard
          </button>
        </div>
      </div>
      <ng-container *ngIf="authenticated && auth.getCurrentUser()">
        <div class="text-right mr-2 d-flex align-items-center">
          <small>{{auth.getCurrentUser().name}} - {{deployedSite}}</small>
        </div>
        <div class="float-right">
          <button mat-icon-button class="mr-1" [matMenuTriggerFor]="usermenu">
            <mat-icon>account_circle</mat-icon>
          </button>
          <mat-menu #usermenu="matMenu" yPosition="below" overlapTrigger="false">
            <button mat-menu-item [routerLink]="['user-settings/view/', auth.getCurrentUser().id]">
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
            <button *ngIf="!integratedAuthentication" mat-menu-item [routerLink]="['logout']">
              <mat-icon>input</mat-icon>
              <span>Log off</span>
            </button>
          </mat-menu>
          <!--<button mat-icon-button class="mr-1">-->
          <!--<img src="/assets/lineup_logo.png" height="32px" width="32px" class="m-1">-->
          <!--</button>-->
          <!--<button mat-icon-button class="mr-1" [matMenuTriggerFor]="menu">-->
          <!--<img src="/assets/light_icon.svg" height="32px" width="32px" class="m-1">-->
          <!--</button>-->
          <!--TODO: Determine where support navigates from as only smartprestart log is to be displayed as is on left side.-->
          <!--                <mat-menu #menu="matMenu" yPosition="below" overlapTrigger="false">-->
          <!--                    <a href="http://DevFU.zendesk.com " target="blank">-->
          <!--                        <button mat-menu-item>-->
          <!--                            <mat-icon>help_outline</mat-icon>-->
          <!--                            <span>Ask Support</span>-->
          <!--                        </button>-->
          <!--                    </a>-->
          <!--                </mat-menu>-->
        </div>
      </ng-container>
    </div>
  </mat-toolbar>

  <div>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</ng-container>
