export class SessionContent {
  constructor(public id: any = null,
              public session_id: any = null,
              public content_id: any = null,
              public group: any = null,
              public ordinal: number = 0
              ) {}

  public static assign(sc: SessionContent) {
    const n = Object.assign(new SessionContent(), sc);

    return n;
  }
}
