import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Content, ContentSchedule, ContentShiftRequest} from '../models/content.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DatePipe} from "@angular/common";
import {map} from 'rxjs/operators';

@Injectable()
export class ContentService extends CoreService {
    constructor(protected auth: AuthenticationService, protected http: HttpClient, protected datePipe: DatePipe) {
        super(auth);
        console.log('ContentService Ready...');
    }

    getContents(): Observable<Content[]> {
        return this.http.get<Content[]>(AppSettings.API_ENDPOINT + 'content', this.options).pipe(
            map(cs => cs.map(c => Content.assign(c))
            ));
    }

    getContent(contentID: number): Observable<Content> {
        return this.http.get<Content>(AppSettings.API_ENDPOINT + 'content/' + contentID, this.options).pipe(
            map(c => Content.assign(c))
        );
    }

    getCompulsoryContent(contentID: string): Observable<Content[]> {
        return this.http.get<Content[]>(AppSettings.API_ENDPOINT + 'content/' + contentID, this.options);

    }

    createContent(content: Content): Observable<Content> {
        return this.http.post<Content>(AppSettings.API_ENDPOINT + 'content', JSON.stringify(content), this.options);
    }

    updateContent(content: Content): Observable<Content> {
        content.valid_from = this.datePipe.transform(content.valid_from, 'yyyy-MM-dd');
        content.expiry = this.datePipe.transform(content.expiry, 'yyyy-MM-dd');
        return this.http.put<Content>(AppSettings.API_ENDPOINT + 'content/' + content.id, JSON.stringify(content),
            this.options);
    }

    deleteContent(contentID: number): Observable<any> {
        return this.http.delete<any>(AppSettings.API_ENDPOINT + 'content/' + contentID, this.options);
    }

    getValidApprovedContents(request: ContentShiftRequest): Observable<Content[]> {
        return this.http.post<Content[]>(AppSettings.API_ENDPOINT + 'content/' + 'valid_approved', request).pipe(
            map(cs => cs.map(c => Content.assign(c)))
        );
    }

    getActiveCompulsoryContent(shift_definition): Observable<any> {
        const params = new HttpParams().set('shift_def', shift_definition)
        return this.http.get<any>(AppSettings.API_ENDPOINT + 'content/compulsory', {params: params})
    }

    getPresortedContents(request: ContentShiftRequest): Observable<ContentSchedule[]> {
        return this.http.post<ContentSchedule[]>(AppSettings.API_ENDPOINT + 'content/' + 'presorted', request);
    }

    getSmartSplitContents(request: Content[]): Observable<Content[]> {
        return this.http.post<Content[]>(AppSettings.API_ENDPOINT + 'content/smartsplit', request);
    }
}
