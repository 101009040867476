import { FileService } from "../services/file.service";
import { Content } from "../models/content.model";

export function onFileDrop(event: any, _fileService: FileService): void {
        event.preventDefault();
        _fileService.setStoredFile(event.dataTransfer.files);
        if (event.dataTransfer.items) {
        event.dataTransfer.items.clear();
    } else {
        event.dataTransfer.clearData();
    }
}

export function handleFileDrop(files: FileList, currentFile: File, currentContent: Content): any {
    currentFile = files.item(0);

    currentContent.title = currentFile.name.split(".pdf")[0];

    return {'file': currentFile, 'content': currentContent};
}
