import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/user.model';
import { Router } from '@angular/router';
import {AppSettings} from "../../../app.settings";

@Component({
  selector: 'app-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.css']
})
export class AccessManagementComponent implements OnInit {

  users: User[] = [];

  displayedColumns: string[] = ['user', 'uname', 'status', 'role', 'actions'];
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<User>;
  integrated = AppSettings.INTEGRATED_AUTHENTICATION;

  constructor(private userService: UserService,
              private router: Router) { }

  ngOnInit() {
    this.userService.getUsers().subscribe(users => {
      this.users = users;
      this.dataSource = new MatTableDataSource(users);
      this.dataSource.sortingDataAccessor = (item, property) => {
        if (property === 'name') {
          if (!item) {
            return item[property];
          }
          return item.name;
        } else {
          return item[property];
        }
      };
      this.sort.sort(({ id: 'name', start: 'asc'}) as MatSortable);
      this.dataSource.sort = this.sort;
    });
  }

  getStatus(ID: number): string {
    const user = this.users.filter(u => u.id === ID);
    if (!this.users || user.length < 1) {
      return null;
    }

    if (user[0].enabled) {
      return 'Active'
    } else {
      return 'Inactive';
    }
  }

  navigate(create: boolean, ID: number = null) {
    if (create) {
      this.router.navigate(['user-settings', 'create']);
    } else {
      this.router.navigate(['user-settings', 'edit', ID]);
    }
  }
}
