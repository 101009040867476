import { Component, OnInit, ViewChild } from '@angular/core';
import { ShiftDefinitionService } from '../../../services/shift_definition.service';
import { ShiftDefinition } from '../../../models/shift_definition.model';
import { Pagination, ReportFilter, ReportRequest, ReportResult } from '../../../models/report.model';
import { DeliveryService } from '../../../services/delivery.service';
import { Delivery } from '../../../models/delivery.model';
import { Category } from '../../../models/category.model';
import { CategoryService } from '../../../services/category.service';
import { Content } from '../../../models/content.model';
import { Crew } from '../../../models/crew.model';
import { ContentService } from '../../../services/content.service';
import { CrewService } from '../../../services/crew.service';
import { formatDate } from '@angular/common';
import { ReportService } from '../../../services/report.service';
import { Log } from '../../../helpers/log.helper';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { WorkerService } from '../../../services/worker.service';
import { Worker } from '../../../models/worker.model'
import {PageEvent} from '@angular/material/paginator';
import {PrestartTypeService} from "../../../services/prestart_type.service";
import {PrestartType} from "../../../models/prestart_type.model";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  reportFilter: ReportFilter = new ReportFilter();
  reportRequest: ReportRequest = new ReportRequest();
  reportResult: ReportResult = new ReportResult();
  reportSnapshot: string = '';
  reportType = '';
  reportSubType = '';
  attendance: string;
  sessionType: string;
  compulsory: number;
  audience: string;
  fromDate: any;
  toDate: any;
  crewID: number;
  crews: Crew[] = [];
  categoryID: number;
  categorys: Category[] = [];
  deliveryID: number;
  deliverys: Delivery[] = [];
  shiftDefinitions: ShiftDefinition[] = [];
  prestartTypeName: string;
  prestartTypes: PrestartType[] = [];
  loading = false;
  isShow = true;
  initialisedData = false;

  pageEvent: PageEvent = new PageEvent();
  length = 50;
  pageSize = 15;
  pageSizeOptions: number[] = [15];

  workerSearchTerm = '';
  workers: Worker[] = [];
  @ViewChild('autoWorker', { read: MatAutocomplete }) workerMatAutocomplete: MatAutocomplete;

  contentSearchTerm = '';
  contents: Content[] = [];
  @ViewChild('autoContent', { read: MatAutocomplete }) contentMatAutocomplete: MatAutocomplete;

  constructor(private shiftDefinitionService: ShiftDefinitionService,
              private crewService: CrewService,
              private contentService: ContentService,
              private categoryService: CategoryService,
              private deliveryService: DeliveryService,
              private workerService: WorkerService,
              private reportService: ReportService,
              private prestartTypeService: PrestartTypeService) { }

  ngOnInit() {
    // Defaults
    this.reportType = 'content';
    this.reportSubType = 'summary';
    this.attendance = 'All';  // All
    this.sessionType = 'All'; // All
    this.compulsory = -1;     // All
    this.audience = 'All';    // All
    this.crewID = -1;        // All
    this.categoryID = -1;    // All
    this.deliveryID = -1;    // All
    this.prestartTypeName = 'All'; // All
    this.reportFilter.attendance = this.updateFilterString(this.attendance);
    this.reportFilter.session_type = this.updateFilterString(this.sessionType);
    this.reportFilter.compulsory = this.updateFilter(this.compulsory);
    this.reportFilter.audience = this.updateFilterString(this.audience);
    this.reportFilter.crew_id = this.updateFilter(this.crewID);
    this.reportFilter.content_id = null;
    this.reportFilter.category_id = this.updateFilter(this.categoryID);
    this.reportFilter.delivery_id = this.updateFilter(this.deliveryID);
    this.reportFilter.worker_id = null;
    this.reportFilter.prestart_type_name = this.updateFilterString(this.prestartTypeName);

    this.workerService.getWorkers().subscribe(workers => {
      this.workers = workers;
    });

    this.crewService.getCrews().subscribe(crews => {
      this.crews = crews;
    });

    this.contentService.getContents().subscribe(contents => {
      this.contents = contents;
    });

    this.categoryService.getCategorys().subscribe(categorys => {
      this.categorys = categorys;
    });

    this.deliveryService.getDeliverys().subscribe(deliverys => {
      this.deliverys = deliverys;
    });

    this.shiftDefinitionService.getShiftDefinitions().subscribe(shiftDefinitions => {
      this.shiftDefinitions = shiftDefinitions;
      this.reportFilter.audience = null;  // Default to all
    });

    this.prestartTypeService.getPrestartTypesUnique().subscribe(prestartTypes => {
      this.prestartTypes = prestartTypes;
      Log.d(this.prestartTypes)
    });

  }

  updateReportSubType() {
    if (this.reportType === 'content' && this.reportSubType === 'content') {
      this.reportSubType = 'summary';
    }
  }

  updateFilterString(criteria: string) {
    if (criteria === 'All') {
      return null;  // All
    } else {
      return criteria;
    }
  }

  updateFilter(criteria: number) {
    if (criteria < 0) {
      return null;  // All
    }
    return criteria;
  }

  updateFilterDate(criteria: any) {
    if (!criteria) {
      return null;
    }
    return formatDate(criteria, 'yyyy-MM-dd', 'en');
  }

  loadReport() {
    this.initialisedData = false;
    this.reportRequest.report_type = this.reportType.concat('-').concat(this.reportSubType);
    this.reportRequest.filters = this.reportFilter;
    // this.updateSnapshot(this.reportRequest.report_type, this.reportFilter);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageSize = this.pageSize;
    this.pageEvent.pageIndex = 0;
    this.reportRequest.pagination = new Pagination(this.pageEvent.pageIndex + 1, this.pageEvent.pageSize);
    this.loading = true;
    this.reportService.reportRequest(this.reportRequest).subscribe(reportResult => {
      this.reportResult = reportResult;
      if (reportResult) {
        this.loading = false;
        if (!this.initialisedData) {
          this.initialisedData = true;
          this.length = reportResult.length;
        }
      }
    });
  }

  loadPage() {
    this.reportRequest.pagination = new Pagination(this.pageEvent.pageIndex + 1, this.pageEvent.pageSize);
    // this.loading = true;
    this.reportService.reportRequest(this.reportRequest).subscribe(reportResult => {
      this.reportResult = reportResult;
      if (reportResult) {
        // this.loading = false;
      }
    });
  }

  workersTypeAhead(): Worker[] {
    const workersFiltered = this.workers.filter(w => !this.reportFilter.crew_id || w.crew_id === this.reportFilter.crew_id);
    return Worker.typeAhead(workersFiltered, this.workerSearchTerm);
  }

  setWorkerID(ID: number): void {
    this.reportFilter.worker_id = ID;
  }

  chooseFirstOptionWorker(): void {
    this.workerMatAutocomplete.options.first.select();
    this.reportFilter.worker_id = this.workersTypeAhead()[0].id;
  }

  onKeyPressWorker(event): void {
    if (event.key === 'Enter') {
      this.chooseFirstOptionWorker();
    }
  }

  resetAutoInputWorker() {
    setTimeout(_ => {
      this.workerMatAutocomplete.options.forEach((item) => {
        item.deselect()
      });
      this.workerSearchTerm = '';
      this.reportFilter.worker_id = null;
    }, 100);
  }

  contentsTypeAhead(): Content[] {
    const contentsFiltered = this.contents.filter(c => !this.reportFilter.delivery_id || c.delivery_id ===
      this.reportFilter.delivery_id).filter(c => !this.reportFilter.category_id || c.category_id ===
      this.reportFilter.category_id).filter(c => this.compulsory < 0 || !!c.is_compulsory === !!this.compulsory);
    return Content.typeAhead(contentsFiltered, this.contentSearchTerm);
  }

  setContentID(ID: number): void {
    this.reportFilter.content_id = ID;
  }

  chooseFirstOptionContent(): void {
    this.contentMatAutocomplete.options.first.select();
    this.reportFilter.content_id = this.contentsTypeAhead()[0].id;
  }

  onKeyPressContent(event): void {
    if (event.key === 'Enter') {
      this.chooseFirstOptionContent();
    }
  }

  resetAutoInputContent() {
    setTimeout(_ => {
      this.contentMatAutocomplete.options.forEach((item) => {
        item.deselect()
      });
      this.contentSearchTerm = '';
      this.reportFilter.content_id = null;
    }, 100);
  }

  onDownload(element) {
    this.reportRequest.report_type = this.reportType.concat('-').concat(this.reportSubType);
    this.reportRequest.filters = this.reportFilter;
    this.reportRequest.pagination = null;

    this.initializeCsvLink(this.reportRequest, element);
  }

  // Below logic used to download CSV report
  initializeCsvLink(request, element) {
    this.reportService.reportRequestAsCsv(request).subscribe(data => {
      this.downloadFile(data, element);
    });
  }

  downloadFile(data: string, element) {
    const blob = new Blob([data], { type: 'text' });
    const url = window.URL.createObjectURL(blob);

    const link = element;
    link.href = url;
    link.download = 'attendee_report.csv';
    link.click();

    setTimeout(function() {
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  convertHeading(heading: string): string {
    return heading.replace('_', ' ')
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getCrew(ID: number): string {
    const crews = this.crews.filter(c => c.id === ID);
    if (crews.length < 1 || !crews[0].name) {
      return 'All';
    }
    return crews[0].name
  }

  getWorker(ID: number): string {
    const workers = this.workers.filter(w => w.id === ID);
    if (workers.length < 1 || !workers[0].first_name || !workers[0].surname) {
      return 'All';
    }
    return workers[0].first_name.concat(' ', workers[0].surname)
  }

  getCategory(ID: number): string {
    const categorys = this.categorys.filter(c => c.id === ID);
    if (categorys.length < 1 || !categorys[0].title) {
      return 'All';
    }
    return categorys[0].title
  }

  getDelivery(ID: number): string {
    const deliverys = this.deliverys.filter(d => d.id === ID);
    if (deliverys.length < 1 || !deliverys[0].title) {
      return 'All';
    }
    return deliverys[0].title
  }

  getContent(ID: number): string {
    const contents = this.contents.filter(c => c.id === ID);
    if (contents.length < 1 || !contents[0].title) {
      return 'All';
    }
    return contents[0].title
  }

  getCompulsory(input: number): string {
    switch (input) {
      case -1:
        return 'All';
      case 0:
        return 'Non-Compulsory';
      case 1:
        return 'Compulsory';
      default:
        return null;
    }
  }

  // FixMe: Make this work such that the filters at time of report run are displayed
  updateSnapshot(reportType: string, filters: ReportFilter) {
    this.reportSnapshot.concat('', reportType.toUpperCase());
    this.reportSnapshot.concat(' [');
    if (reportType.includes('DETAILED', 0)) {
      this.reportSnapshot.concat('Attendance: ', filters.attendance, ', ');
    }
    this.reportSnapshot.concat('Session Type: ', filters.session_type, ', ',
      'Content Type: ', this.getCompulsory(filters.compulsory), ', ',
      'Audience: ', filters.audience);
    if (filters.from_date) {
      this.reportSnapshot.concat(', ', 'From Date: ', filters.from_date);
    }
    if (filters.to_date) {
      this.reportSnapshot.concat(', ', 'To Date: ', filters.from_date);
    }
    this.reportSnapshot.concat(', ', 'Crew: ', this.getCrew(filters.crew_id))
    this.reportSnapshot.concat(', ', 'Worker: ', this.getWorker(filters.worker_id),
      ', ', 'Category: ', this.getCategory(filters.category_id),
      ', ', 'Delivery: ', this.getDelivery(filters.delivery_id),
      ', ', 'Content: ', this.getContent(filters.content_id));
    this.reportSnapshot.concat(' ]');
  }
}
