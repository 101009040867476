export class Worker {
  constructor(public id: any = null,
              public first_name: string = '',
              public surname: string = '',
              public initials: string = '',
              public employee_number: number = 0,
              public login_id: number = 0,
              public mobile_number: string = '',
              public classification: string = '',
              public skill: string = '',
              public company: string = '',
              public enabled: boolean = true,
              public crew_id: number = 0,
              public crew_name: string = ''
  ) {}

  public static assign(w: Worker) {
    const n = Object.assign(new Worker(), w);

    return n;
  }

  public static typeAhead(workers: Worker[], searchTerm: string, numberShown: number = null): Worker[] {
    if (workers.length < 1) {
      return workers;
    }

    // Sorted alphabetically
    workers = workers.sort((a, b) => a.surname.concat(' ', a.first_name)
      .localeCompare(b.surname.concat(' ', b.first_name)));


    if (!numberShown) {         // XXX: was isNull(numberShown) but that function doesn't exist -AGW
      if (searchTerm === '') {
        return (workers);
      } else {
        return (workers.filter(term => term.first_name.concat(' ', term.surname).toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1));
      }
    } else {

      if (searchTerm === '') {
        return (workers.slice(0, numberShown));
      } else {
        return (workers.filter(term => term.first_name.concat(' ', term.surname).toLowerCase()
          .indexOf(searchTerm.toLowerCase()) > -1).slice(0, numberShown));
      }
    }
  }
}

export class ContentWorker {
  constructor(public worker_id: any = null,
              public worker_first_name: string = '',
              public worker_surname: string = '',
              public crew_id: any = null,
              public content_id: any = null,
              public attended: boolean = false
  ) {}
}

export class SessionWorker {
  constructor(public worker_id: any = null,
              public worker_first_name: string = '',
              public worker_surname: string = '',
              public crew_id: any = null,
              public session_id: any = null,
              public attended: boolean = false
  ) {}
}
