import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: "app-confirm-leave-session",
  templateUrl: "./confirm.component.html",
})
export class DialogConfirm {

  constructor(public dialogRef: MatDialogRef<DialogConfirm>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
