import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    currentYear: number;

    constructor() {
    }

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
    }

    protected readonly environment = environment;
}
