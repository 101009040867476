import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {IntegrationLog} from '../models/integration_log.model';
import {HttpClient} from '@angular/common/http';
import {AppSettings} from "../app.settings";

@Injectable()
export class IntegrationLogService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('IntegrationLogService Ready...');
  }

  getIntegrationLogs(): Observable<IntegrationLog[]> {
    return this.http.get<IntegrationLog[]>(AppSettings.API_ENDPOINT + 'integration_log', this.options);
  }

  getIntegrationLog(integrationLogID: number): Observable<IntegrationLog> {
    return this.http.get<IntegrationLog>(AppSettings.API_ENDPOINT + 'integration_log/' + integrationLogID, this.options);
  }

  getIntegrationLogLastSuccessfulUpdate(): Observable<IntegrationLog> {
    return this.http.get<IntegrationLog>(AppSettings.API_ENDPOINT + 'integration_log/last_update', this.options);
  }

  createIntegrationLog(integrationLog: IntegrationLog): Observable<IntegrationLog> {
    return this.http.post<IntegrationLog>(AppSettings.API_ENDPOINT + 'integration_log', JSON.stringify(integrationLog), this.options);
  }

  updateIntegrationLog(integrationLog: IntegrationLog): Observable<IntegrationLog> {
    return this.http.put<IntegrationLog>(AppSettings.API_ENDPOINT + 'integration_log/' + integrationLog.id, JSON.stringify(integrationLog), this.options);
  }

  deleteIntegrationLog(integrationLogID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'integration_log/' + integrationLogID, this.options);
  }
}
