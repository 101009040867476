<div class="modal-content">
  <div class="modal-header">
    <div class="container header">
      <div class="row mt-0">
        <div class="col-2 p-0">
          <mat-button-toggle-group [(ngModel)]="viewWorkers">
            <mat-button-toggle [value]="1">Staying</mat-button-toggle>
            <mat-button-toggle [value]="0">Leaving</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="col-8 mt-1">
          <h4 class="text-center modal-title text-white">{{data.title}}</h4>
        </div>
        <div class="col-2 mt-2">
          <button type="button" class="close" aria-label="Close" (click)="dialogRef.close(false)">
            <span aria-hidden="true">&times;</span>
          </button>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="list-container">
      <div class="row">
        <ng-container *ngIf="viewWorkers === 1 else Leaving">
          <div class="col-12">
            <h3 class="text-center mb-3">Staying</h3>
            <div class="pt-3">
              <mat-grid-list cols="5" rowHeight="35px">
                <mat-grid-tile *ngFor="let worker of data.staying">
                  <p>{{worker.first_name}} {{worker.surname}}</p>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </div>
        </ng-container>
        <ng-template #Leaving>
          <div class="col-12">
            <h3 class="text-center mb-3">Leaving</h3>
            <div class="pt-3">
              <mat-grid-list cols="5" rowHeight="35px">
                <mat-grid-tile *ngFor="let worker of data.leaving">
                  <p>{{worker.first_name}} {{worker.surname}}</p>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-toolbar w-100">
      <button class="btn btn-outline-secondary text-black close-buttons" (click)="dialogRef.close(false)">Close</button>
      <button class="btn btn-info text-white close-buttons " (click)="dialogRef.close(true)">Present Next</button>
    </div>
  </div>
</div>


<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-6">-->
<!--        <h5 class="text-center">Staying</h5>-->
<!--        <div class="card-body border border-secondary pr-3">-->
<!--          <mat-grid-list cols="4" rowHeight="30px">-->
<!--            <mat-grid-tile *ngFor="let worker of data.staying">-->
<!--              <p>{{worker.first_name}} {{worker.surname}}</p>-->
<!--            </mat-grid-tile>-->
<!--          </mat-grid-list>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-6">-->
<!--        <h5 class="text-center">Leaving</h5>-->
<!--        <div class="card-body border border-success pr-3">-->
<!--          <mat-grid-list cols="4" rowHeight="35px">-->
<!--            <mat-grid-tile *ngFor="let worker of data.leaving">-->
<!--              <p>{{worker.first_name}} {{worker.surname}}</p>-->
<!--            </mat-grid-tile>-->
<!--          </mat-grid-list>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
