import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {ShiftDefinition} from '../models/shift_definition.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ShiftDefinitionService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('ShiftDefinitionService Ready...');
  }

  getShiftDefinitions(): Observable<ShiftDefinition[]> {
    return this.http.get<ShiftDefinition[]>(AppSettings.API_ENDPOINT + 'shift_definition', this.options);
  }

  getShiftDefinition(shiftDefinitionID: number): Observable<ShiftDefinition> {
    return this.http.get<ShiftDefinition>(AppSettings.API_ENDPOINT + 'shift_definition/' + shiftDefinitionID, this.options);
  }

  getShiftDefinitionByTime(time: string): Observable<ShiftDefinition> {
    return this.http.post<ShiftDefinition>(AppSettings.API_ENDPOINT + 'shift_definition/time', JSON.stringify(time), this.options);
  }

  createShiftDefinition(shiftDefinition: ShiftDefinition): Observable<ShiftDefinition> {
    return this.http.post<ShiftDefinition>(AppSettings.API_ENDPOINT + 'shift_definition', JSON.stringify(shiftDefinition), this.options);
  }

  updateShiftDefinition(shiftDefinition: ShiftDefinition): Observable<ShiftDefinition> {
    return this.http.put<ShiftDefinition>(AppSettings.API_ENDPOINT + 'shift_definition/' + shiftDefinition.id, JSON.stringify(shiftDefinition), this.options);
  }

  deleteShiftDefinition(shiftDefinitionID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'shift_definition/' + shiftDefinitionID, this.options);
  }
}
