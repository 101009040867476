import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {HttpClient} from '@angular/common/http';
import {ReportRequest, ReportResult} from '../models/report.model';

@Injectable()
export class ReportService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('ReportService Ready...');
  }

  public reportRequest(request: ReportRequest): Observable<ReportResult> {
    return this.http.post<ReportResult>(AppSettings.API_ENDPOINT + 'report', request);
  }

  public reportRequestAsCsv(request: ReportRequest): Observable<any> {
    return this.http.post(AppSettings.API_ENDPOINT + 'report' + '/csv', request, {responseType: 'text'});
  }
}
