<div class="row pb-5 px-3 pt-2">
  <div *ngIf="isShow" class="card p-0 col-3">
    <div class="card-body filters-card p-2 px-4">
      <div class="row mt-2 d-flex align-items-center justify-content-between">
        <h5>Filter Report</h5>
      </div>
      <p>Report Type</p>
      <mat-button-toggle-group [(ngModel)]="reportType" (ngModelChange)="updateReportSubType()" name="report"
                               class="w-100 d-flex">
        <mat-button-toggle value="session" class="flex-fill">Presentation</mat-button-toggle>
        <mat-button-toggle value="content" class="flex-fill">Content</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-button-toggle-group [(ngModel)]="reportSubType" name="type" class="w-100 d-flex my-2">
        <mat-button-toggle value="summary" class="flex-fill">Summary</mat-button-toggle>
        <mat-button-toggle value="detailed" class="flex-fill">Detailed</mat-button-toggle>
        <mat-button-toggle *ngIf="reportType === 'session'" value="content" class="flex-fill">Content
        </mat-button-toggle>
      </mat-button-toggle-group>
      <p *ngIf="reportSubType != 'summary' && reportSubType != 'content'">Attendance</p>
      <mat-button-toggle-group *ngIf="reportSubType != 'summary' && reportSubType != 'content'" [(ngModel)]="attendance"
                               (ngModelChange)="reportFilter.attendance = updateFilterString(attendance)"
                               name="attendance"
                               class="w-100 d-flex">
        <ng-container *ngIf="reportType === 'session'; else content">
          <mat-button-toggle value='All' class="flex-fill">All</mat-button-toggle>
          <mat-button-toggle value='Present' class="flex-fill">Present</mat-button-toggle>
          <mat-button-toggle value='Absent' class="flex-fill">Absent</mat-button-toggle>
        </ng-container>
        <ng-template #content>
          <mat-button-toggle value='All' class="flex-fill">All</mat-button-toggle>
          <mat-button-toggle value='Seen' class="flex-fill">Seen</mat-button-toggle>
          <mat-button-toggle value='Not Seen' class="flex-fill">Not Seen</mat-button-toggle>
        </ng-template>
      </mat-button-toggle-group>
      <p class="mt-1">Presentation Type</p>
      <mat-button-toggle-group [(ngModel)]="sessionType"
                               (ngModelChange)="reportFilter.session_type = updateFilterString(sessionType)" name="session_type"
                               class="w-100 d-flex">
        <mat-button-toggle [value]="'All'" class="flex-fill">All</mat-button-toggle>
        <mat-button-toggle [value]="'Prestart'" class="flex-fill">Prestart</mat-button-toggle>
        <mat-button-toggle [value]="'Training'" class="flex-fill">Training</mat-button-toggle>
      </mat-button-toggle-group>
      <div *ngIf="prestartTypes && sessionType != 'Training'" class="row my-1">
        <p class="mt-1">Prestart Type</p>
        <mat-form-field class="w-100 pt-0 pl-0" appearance="outline">
          <mat-select *ngIf="prestartTypes" [(ngModel)]="prestartTypeName"
                      (ngModelChange)="reportFilter.prestart_type_name = updateFilterString(prestartTypeName)">
            <mat-option [value]="'All'">All</mat-option>
            <mat-option *ngFor="let prestartType of prestartTypes" [value]="prestartType.name">
              {{prestartType.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <p class="mt-1">Compulsory Content</p>
      <mat-button-toggle-group [(ngModel)]="compulsory"
                               (ngModelChange)="reportFilter.compulsory = updateFilter(compulsory)" name="compulsory"
                               class="w-100 d-flex mb-3">
        <mat-button-toggle [value]="-1" class="flex-fill">All</mat-button-toggle>
        <mat-button-toggle [value]="1" class="flex-fill">Compulsory</mat-button-toggle>
        <mat-button-toggle [value]="0" class="flex-fill">Non-Compulsory</mat-button-toggle>
      </mat-button-toggle-group>
      <p class="mt-1">Content Audience</p>
      <mat-button-toggle-group *ngIf="shiftDefinitions" [(ngModel)]="audience"
                               (ngModelChange)="reportFilter.audience = updateFilterString(audience)" name="audience"
                               class="w-100 d-flex mb-3">
        <mat-button-toggle [value]="'All'" class="flex-fill">All</mat-button-toggle>
        <mat-button-toggle *ngFor="let shiftDefinition of shiftDefinitions" [value]="shiftDefinition.name"
                           class="flex-fill">{{shiftDefinition.name}}</mat-button-toggle>
      </mat-button-toggle-group>
      <p class="mt-1">Filters</p>
      <div class="stretch-button-group">
        <div class="d-flex align-items-center inverse">
          <mat-form-field *ngIf="reportFilter" appearance="outline" class="w-100">
            <mat-label>From Date</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="fromDate"
                   (dateChange)="reportFilter.from_date = updateFilterDate(fromDate)" [matDatepicker]="fromDatePicker">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="stretch-button-group">
        <div class="d-flex align-items-center inverse">
          <mat-form-field *ngIf="reportFilter" appearance="outline" class="w-100">
            <mat-label>To Date</mat-label>
            <input matInput autocomplete="off" [(ngModel)]="toDate"
                   (dateChange)="reportFilter.to_date = updateFilterDate(toDate)" [matDatepicker]="toDatePicker">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!--col-6 pt-0 pl-0-->
      <div *ngIf="reportSubType != 'content'" class="row my-1">
        <mat-form-field class="w-100 pt-0 pl-0" appearance="outline">
          <mat-label>Select Crews</mat-label>
          <mat-select *ngIf="crews" [(ngModel)]="crewID"
                      (ngModelChange)="reportFilter.crew_id = updateFilter(crewID)">
            <mat-option [value]="-1">All</mat-option>
            <mat-option *ngFor="let crew of crews" [value]="crew.id">
              {{crew.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div  *ngIf="reportSubType != 'summary' && reportSubType != 'content'" class="row my-1">
        <mat-form-field appearance="outline" class="w-100 pt-0 pl-0">
          <mat-label>Select Worker</mat-label>
          <input matInput type="text" autocomplete="off" [(ngModel)]="workerSearchTerm"
                 (keypress)="onKeyPressWorker($event)" [matAutocomplete]="autoWorker" placeholder="Start search by typing..">
          <mat-autocomplete #autoWorker="matAutocomplete">
            <mat-option *ngFor="let workers of workersTypeAhead()" (click)="setWorkerID(workers.id)"
                        [value]="workers.first_name.concat(' ',workers.surname)">
              {{workers.first_name}} {{workers.surname}}
            </mat-option>
          </mat-autocomplete>
          <button matSuffix mat-icon-button (click)="resetAutoInputWorker()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Select Categories</mat-label>
        <mat-select *ngIf="categorys" [(ngModel)]="categoryID"
                    (ngModelChange)="reportFilter.category_id = updateFilter(categoryID)">
          <mat-option [value]="-1">All</mat-option>
          <mat-option *ngFor="let category of categorys" [value]="category.id">
            {{category.title}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Delivery</mat-label>
        <mat-select *ngIf="deliverys" [(ngModel)]="deliveryID"
                    (ngModelChange)="reportFilter.delivery_id = updateFilter(deliveryID)">
          <mat-option [value]="-1">All</mat-option>
          <mat-option *ngFor="let delivery of deliverys" [value]="delivery.id">
            {{delivery.title}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="card-body text-center" class="row my-1">
        <mat-form-field appearance="outline" class="w-100 pt-0 pl-0">
          <mat-label>Select Content</mat-label>
          <input matInput type="text" autocomplete="off" [(ngModel)]="contentSearchTerm"
                 (keypress)="onKeyPressContent($event)" [matAutocomplete]="autoContent"
                 placeholder="Start search by typing..">
          <mat-autocomplete #autoContent="matAutocomplete">
            <mat-option *ngFor="let content of contentsTypeAhead()" (click)="setContentID(content.id)"
                        [value]="content.title">
              {{content.title}}
            </mat-option>
          </mat-autocomplete>
          <button matSuffix mat-icon-button (click)="resetAutoInputContent()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="col-9 p-0 pl-3" [class.col-12]="!isShow">
    <div class="row mt-0 d-flex align-items-center justify-content-between">
      <button (click)=toggleDisplay() mat-stroked-button>Toggle Filter</button>
      <h5>Generate Report
        <ng-container *ngIf="reportResult.data && this.initialisedData">{{reportSnapshot}}
        </ng-container>
      </h5>
      <div class="button row">
        <a #downloadCsvLink class="d-none"></a>
        <button mat-stroked-button (click)="onDownload(downloadCsvLink)">Export as CSV</button>
        <ng-container *ngIf="loading; else notLoading">
          <button mat-stroked-button disabled color="accent">Loading</button>
        </ng-container>
        <ng-template #notLoading>
          <button mat-flat-button color="accent" (click)="loadReport()">Run Report</button>
        </ng-template>
      </div>
    </div>
  <div class="table-container">
    <table class="ctm-table w-100">
      <tr *ngIf="loading">
        Loading requested data, please wait.
      </tr>
      <tr *ngIf="loading">
        <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
      </tr>
      <tr *ngIf="reportResult.data">
        <th *ngFor="let column of reportResult.columns" style="min-width: 170px">
          {{convertHeading(column.name)}}
        </th>
      </tr>
      <tr *ngFor="let row of reportResult.data">
        <td *ngFor="let col of row" style="min-width: 170px; white-space: nowrap;">
          {{col}}
        </td>
      </tr>
      <tr *ngIf="!reportResult.data && !loading">
        <td class="text-center">Select Filters and Run Report to show data</td>
      </tr>
      <tr *ngIf="reportResult.data && !loading && reportResult.data.length < 1">
        <td class="text-center">Current filters returned 0 results</td>
      </tr>
    </table>
  </div>
    <mat-paginator *ngIf="reportResult.data && this.initialisedData"
                   [length]="length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="pageEvent = $event; loadPage()" showFirstLastButtons></mat-paginator>
  </div>
</div>
