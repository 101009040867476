import { Component, OnInit } from '@angular/core';
import {IntegrationLogService} from "../../../services/integration_log.service";
import {IntegrationLog} from "../../../models/integration_log.model";
import {WorkerService} from "../../../services/worker.service";
import {Log} from "../../../helpers/log.helper";

@Component({
  selector: 'app-system-health',
  templateUrl: './system-health.component.html',
  styleUrls: ['./system-health.component.css']
})
export class SystemHealthComponent implements OnInit {

  integrationLog: IntegrationLog = null;
  numberWorkers: number = null;
  numberActiveWorkers: number = null;
  public integrationLogs: IntegrationLog[] = [];


  constructor(private integrationLogService: IntegrationLogService,
              private workerService: WorkerService) {}

  ngOnInit() {
    this.integrationLogService.getIntegrationLogLastSuccessfulUpdate().subscribe(integrationLog => {
      this.integrationLog = integrationLog;
    });
    this.workerService.getWorkers().subscribe(workers => {
      this.numberWorkers = workers.length;
      this.numberActiveWorkers = workers.filter(w => !!w.enabled === true).length
    });
    this.integrationLogService.getIntegrationLogs().subscribe(integrationLogs => {
      this.integrationLogs = integrationLogs;
    });
  }

  lastUpdate(): number {
    return Date.now() - new Date(this.integrationLog.event_time).getTime();
  }

  getHours(MilliSeconds: number): number {
    return Math.floor((MilliSeconds / 1000.0) / 3600.0);
  }

  getMinutes(MilliSeconds: number): number {
    return Math.floor((MilliSeconds / 1000.0) / 60.0) % 60.0;
  }

  getSeconds(MilliSeconds: number): number {
    return Math.round((MilliSeconds / 1000.0) % 60.0);
  }
}
