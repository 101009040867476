import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment as env } from "../../environments/environment";
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpRequest } from "@angular/common/http";
import { Log } from "../helpers/log.helper";
import { AppSettings } from "../app.settings";

// import config from '@angular/cli/commands/config';

export class FileUploadStatus {
    public constructor(
        public content_file_id: any,
        public fileName: string,
        public percentDone: number = 0,
        public bytesUploaded: number = 0) {
    }
}

export interface Config {
    id: number;
}

@Injectable()
export class FileService {

    private storedFile: FileList;

    public endpointUrl: string = AppSettings.API_ENDPOINT + "content_file";

    public validateFile(file: File): boolean {

        let isValidSize = true;

        if (!file || file.size > env.httpSettings.maxUploadSize) {
            isValidSize = false;
        }

        return isValidSize;
    }

    /** Return distinct message for sent, upload progress, & response events */
    private getEventMessage(event: HttpEvent<any>, file: File): FileUploadStatus {
        switch (event.type) {
            case HttpEventType.Sent:
                Log.d(`Uploading file '${file.name}' of size ${file.size}.`);
                break;

            case HttpEventType.UploadProgress:
                // Compute and show the % done:
                const castEvent = (<HttpProgressEvent>event);
                const percentDone = Math.round(100 * castEvent.loaded / castEvent.total);
                Log.d(`File '${file.name}' is ${percentDone}% uploaded.`);
                return new FileUploadStatus(null, file.name, percentDone, castEvent.loaded);

            case HttpEventType.Response:
                Log.d(`File '${file.name}' was completely uploaded!`);
                return new FileUploadStatus(event.body.id, null, null, null);

            default:
                Log.d(`File '${file.name}' surprising upload event: ${event.type}.`);
        }
    }


    public upload(endpointUrl: string, file: File, page_count: number): Observable<FileUploadStatus> {

        const headers = new HttpHeaders();
        headers.set("Content-Type", "multipart/form-data");

        const formData = new FormData();

        formData.append("upload", file);
        formData.append("page_count", page_count.toString());
        // this.endpointUrl + '/' + content.id
        return this.httpClient.request(new HttpRequest("POST",
            endpointUrl,
            formData,
            { headers: headers, reportProgress: true })).pipe(
            map(event => this.getEventMessage(event, file)));
    }

    // public remove(contentFile: ContentFile): Observable<RestResponseModel> {
    //   return this.httpClient.delete<RestResponseModel>(AppSettings.API_ENDPOINT + 'content_file' + '/' + contentFile.id);
    // }

    // public getCachingStatus(contentFile: ContentFile): Observable<RestResponseModel> {
    //   return this.httpClient.get<RestResponseModel>(AppSettings.API_ENDPOINT + 'content_file' + '/' + contentFile.id + '/cache');
    // }

    public getstoredFile(): FileList {
        return this.storedFile;
    }

    public setStoredFile(file: FileList): void {
        this.storedFile = file;
    }

    public clearStoredFile(): void {
        this.storedFile = null;
    }

    constructor(private httpClient: HttpClient) {
    }

}
