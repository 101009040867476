import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import {User, UserName} from '../../../models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Log } from '../../../helpers/log.helper';
import {AuthenticationService} from "../../../services/authentication.service";
import {Role} from "../../../models/role.model";
import {AppSettings} from "../../../app.settings";
import { Location } from '@angular/common'

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {

  user: User = new User();
  userName: UserName = new UserName();
  roles: Role[] = [];
  userID: number;
  viewType: string;
  isShown = true;
  AppSettings = AppSettings;
  admin = false;

  constructor(private userService: UserService,
              public authService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location
  ) { }

  ngOnInit() {
    this.user.password = '';
    this.roles = AuthenticationService.getRoles();
    this.route.params.subscribe(params => {
      this.userID = params['id'];
      this.viewType = params['type'];
        this.authService.refreshWhoAmI().then(user => {
            this.admin = user.role === "ADMIN";
            if (this.userID) {
                if (this.admin) {
                    this.userService.getUser(this.userID).subscribe(manageUser => {
                        this.user = manageUser;
                        this.user.password = '';
                        // @ts-ignore enabled model is boolean but MySQL returns 1 or 0
                        this.user.enabled = manageUser.enabled === true || manageUser.enabled === 1;
                    });
                }
                this.userService.getUserNames().subscribe(userNames => {
                    this.userName = userNames.filter(u => u.id === this.userID)[0];
                });
            } else {
                // Set defaults
                this.user.enabled = true;
                this.user.role = 'AUTHOR'; // Lowest user level
            }
        });
    });
  }

  isEdit(): boolean {
    return this.viewType === 'edit';
  }

  isView(): boolean {
    return this.viewType === 'view';
  }

  createUser() {
    this.userService.createUser(this.user).subscribe(res => {
      if (res) {
        Log.i('User Created');
        this.router.navigate(['access-management']);
      }
    });
  }

  editUser() {
    this.userService.updateUser(this.user).subscribe(res => {
      if (res) {
        Log.i('User Updated');
        this.router.navigate(['access-management']);
      }
    });
  }

  checkValid(): boolean {
      return (this.user && (this.user.name.length > 1 && this.user.email.length > 1 && (
          AppSettings.INTEGRATED_AUTHENTICATION && this.user.remote_system_identity.length > 1) || (
              !AppSettings.INTEGRATED_AUTHENTICATION && this.user.password.length > 1)))
  }

    navigateBack(): void {
        this.location.back();
    }
}
