import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Category} from '../models/category.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CategoryService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('CategoryService Ready...');
  }

  getCategorys(): Observable<Category[]> {
    return this.http.get<Category[]>(AppSettings.API_ENDPOINT + 'category', this.options);
  }

  getCategory(categoryID: number): Observable<Category> {
    return this.http.get<Category>(AppSettings.API_ENDPOINT + 'category/' + categoryID, this.options);
  }

  createCategory(category: Category): Observable<Category> {
    return this.http.post<Category>(AppSettings.API_ENDPOINT + 'category', JSON.stringify(category), this.options);
  }

  updateCategory(category: Category): Observable<Category> {
    return this.http.put<Category>(AppSettings.API_ENDPOINT + 'category/' + category.id, JSON.stringify(category), this.options);
  }

  deleteCategory(categoryID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'category/' + categoryID, this.options);
  }
}
