import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { formatDate } from "@angular/common";
import { DashboardSession, DashboardStatistics } from "../../../models/dashboard.model";
import { DashboardService } from "../../../services/dashboard.service";
import { ContentService } from "../../../services/content.service";
import { SessionMetricService } from "../../../services/session_metric.service";
import { WorkerService } from "../../../services/worker.service";
import { IntegrationService } from "../../../services/integration.service";
import { SessionMetric } from "../../../models/session_metric.model";
import { AttendanceService } from "../../../services/attendance.service";
import { SessionAttendance } from "../../../models/attendance.model";
import { Log } from "../../../helpers/log.helper";
import { PrestartType } from "../../../models/prestart_type.model";
import { PrestartTypeService } from "../../../services/prestart_type.service";
import { Content, ContentSchedule } from "../../../models/content.model";
import { ContentWorker } from "../../../models/worker.model";
import { DialogsService } from "../../../services/dialogs.service";
import { AppSettings } from "../../../app.settings";

interface Range {
    value: string;
    viewValue: string;
}

interface DashboardData {
    range: string,
    data: DashboardStatistics;
}

interface CompulsaryData {
    absentWorkers: ContentWorker[];
    data: Content;
}

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {

    sessionOverviews: DashboardSession[];
    shiftLabels = ["Current", "Next", "Next"];
    ranges: Range[] = [
        { value: "shift", viewValue: "This Shift" },
        { value: "week", viewValue: "Week To Date" },
        { value: "month", viewValue: "Month To Date" }
    ];
    dashboardData: DashboardData[] = [];
    showVertical = true;
    sessionMetrics: SessionMetric[] = [];
    sessionsAttendance: SessionAttendance[] = [];
    prestartTypes: PrestartType[] = [];
    compulsoryData: CompulsaryData[] = [];
    compulsoryContent: Content[] = [];

    constructor(private dashboardService: DashboardService,
                private sessionMetricService: SessionMetricService,
                private attendanceService: AttendanceService,
                private prestartTypeService: PrestartTypeService,
                private contentService: ContentService,
                private workerService: WorkerService,
                private integrationService: IntegrationService,
                public router: Router,
                private dialogService: DialogsService) {
    }

    ngOnInit() {

        this.prestartTypeService.getPrestartTypes().subscribe(prestartTypes => {
            this.prestartTypes = prestartTypes.filter(pt => !!pt.is_active === true);
        });

        const currentDate = new Date(Date.now());
        const set_datetime: string = formatDate(currentDate, "yyyy-MM-dd HH:mm:ss", "en");
        this.dashboardService.getDashboardSessions(set_datetime).subscribe(sessionOverviews => {
            this.sessionOverviews = sessionOverviews;
            Log.d("SO: ", this.sessionOverviews);
            this.sessionMetricService.getSessionMetrics().subscribe(sessionMetrics => {
                this.sessionMetrics = sessionMetrics;
                this.ranges.forEach((item) => {
                    this.dashboardService.getDashboardStatistics(item.value).subscribe(dashboardStatistics => {
                        this.dashboardData.push({ range: item.value, data: dashboardStatistics });
                    });
                    this.attendanceService.getSessionsAttendance().subscribe(sessionsAttendance => {
                        this.sessionsAttendance = sessionsAttendance;
                    });
                });
            });
        });
    }

    getDashboardStatistics(range: string): DashboardStatistics {
        const data = this.dashboardData.filter(dd => dd.range === range);
        if (data.length < 1) {
            return null;
        }
        return data[0].data;
    }

    checkValidMetrics(ID: number) {
        return this.sessionMetrics.filter(sm => sm.session_id === ID).length > 0;
    }

    toggleView() {
        this.showVertical = !this.showVertical;
    }

    getHours(MilliSeconds: number): number {
        return Math.floor((MilliSeconds / 1000.0) / 3600.0);
    }

    getMinutes(MilliSeconds: number): number {
        return Math.floor((MilliSeconds / 1000.0) / 60.0) % 60.0;
    }

    getSeconds(MilliSeconds: number): number {
        return Math.round((MilliSeconds / 1000.0) % 60.0);
    }

    navigate(partialPath: string, session_id: number, type: string = "") {
        if (type === "") {
            this.router.navigate(["/session/", partialPath, session_id]);
        } else {
            this.router.navigate(["/session/", partialPath, session_id, type]);
        }
    }

    getAttendance(ID: number) {
        const sessionAttendances = this.sessionsAttendance.filter(s => s.session_id === ID);
        if (sessionAttendances.length < 1) {
            return null;
        }
        return sessionAttendances[0].attendance_percent > 0.0;
    }

    sessionOverviewInPrestartType(sessionOverwiewID: number, prestartTypeID: number): boolean {
        if (this.sessionOverviews) {
            const sessionOverwiews = this.sessionOverviews.filter(so => so.session_id === sessionOverwiewID)[0];
            if (sessionOverwiews) {
                return sessionOverwiews.prestart_type_id === prestartTypeID;
            }
        }
        return false;
    }

    getInt(numerator: number, demoninator: number): number {
        if (demoninator > 0) {
            return Math.floor(numerator / demoninator);
        } else {
            return numerator;
        }
    }

    addContentCreationIDs(contentID: number) {
        const content = new ContentSchedule(contentID, true);
        this.dashboardService.contentIDs.push(content);
    }

    addAllContentCreationIDs(contents: Content[]) {
        for (const content of contents) {
            const contentSchedule = new ContentSchedule(content.id, true);
            this.dashboardService.contentIDs.push(contentSchedule);
        }
    }

    isIntegratedSignIn() {
        return AppSettings.IS_INTEGRATED_SIGNIN;
    }

}
