import {Observable, throwError as observableThrowError} from 'rxjs';

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AppSettings} from '../app.settings';
import {HttpClient} from '@angular/common/http';

import {User} from '../models/user.model';
import {Log} from '../helpers/log.helper';
import {Role} from "../models/role.model";

@Injectable()
export class AuthenticationService {
  private userLoggedIn = false;
  private currentUser = null;

  protected headers = {
    'Content-Type': 'application/json',
    'access-control-expose-headers': 'X-New-Token'
  };

  protected options = {headers: this.headers};

  protected id = null;

  constructor(protected http: HttpClient,
              protected router: Router) {
    this.userLoggedIn = (localStorage.getItem(AppSettings.AUTH_LOCAL_STORAGE_LOGGED_IN_KEY) === 'true');
    this.id = Math.random();
    Log.c('AuthenticatorService created with id', this.id);
  }

  public isAuthenticated() {
    if (AppSettings.INTEGRATED_AUTHENTICATION) {return true;} //We're always authenticated, we might not always have access

    let logged_in = localStorage.getItem(AppSettings.AUTH_LOCAL_STORAGE_LOGGED_IN_KEY);
    let token = this.getToken();
    if (!logged_in || !token) {
      return false;
    }

    if (!this.checkToken()) {
      Log.i('Token is invalid and should be ignored. User should be logged out.');
      if (this.getToken()) {
        this.clearUserDataAndRedirect();
      }
      return false;
    }

    return true;
  }

  // Next two functions are from auth0/jwt-decode
  b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
      var code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = '0' + code;
      }
      return '%' + code;
    }));
  }

  decodeToken(str) {
    var output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }

    try {
      return this.b64DecodeUnicode(output);
    } catch (err) {
      return atob(output);
    }
  };

  public checkToken(token: string = ''): boolean {
    let t = token ? token : this.getToken();
    if (!t) {
      return false;
    }

    let sections = t.split('.').map(x => {
      return this.decodeToken(x);
    });
    let payload = JSON.parse(sections[1]);
    return Math.floor((new Date()).getTime() / 1000) < payload.exp;
  }

  public getToken() {
    return localStorage.getItem(AppSettings.AUTH_LOCAL_STORAGE_TOKEN_KEY);
  }

  public refreshWhoAmI(): Promise<User> {
    return new Promise<User>( (resolve, reject) => {
      this.http.get<User>(AppSettings.API_ENDPOINT + 'auth/whoami').subscribe(user => {
        this.currentUser = User.assign(user);
        // Now we also have a successful response from the WhoAmI, so we return the loginData
        resolve(user);
      }, (error) => {
        reject(error);
      });
    });
  }

  public login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post(AppSettings.API_ENDPOINT + 'auth/login',
        JSON.stringify({'username': email, 'password': password}),
        this.options
      ).subscribe((loginData: any) => {
        Log.i('AuthenticationService: Did login');

        if (loginData && loginData.access_token) {
          Log.i('Current Token:', loginData.access_token);
          localStorage.setItem(AppSettings.AUTH_LOCAL_STORAGE_TOKEN_KEY, loginData.access_token);
          localStorage.setItem(AppSettings.AUTH_LOCAL_STORAGE_LOGGED_IN_KEY, 'true');
          this.userLoggedIn = true;

          // We got a result from the login call, but we won't call resolve() yet, as this would return a result
          // Instead we make the WhoAmI call and then return to the login() call
          this.http.get<User>(AppSettings.API_ENDPOINT + 'auth/whoami').subscribe(user => {
            this.currentUser = user;
            localStorage.setItem('current_user', JSON.stringify(user)); //Cache in case we reload
            // Now we also have a successful response from the WhoAmI, so we return the loginData
            resolve(loginData);

          }, (error) => {
            this.handleLoginFail(error);
            reject(error);
          });
        } else {
          return false;
        }
      }, (error) => {
        this.handleLoginFail(error);
        reject(error);
      });
    });
  }

  public changePassword(current: string, new_password: string): Observable<any> {
    const data = {current: current, new_password: new_password};
    return this.http.patch<string>(AppSettings.API_ENDPOINT + 'auth/change_password', JSON.stringify(data),
      // @ts-ignore
      {responseType: 'text', ...this.options});
  }

  public handleLoginFail(error: any) {
    const errMsg = (error.message) ? error.message :
      error.stateName ? `${error.stateName} - ${error.statusText}` : 'Server error';
    console.error('Login failed', errMsg);
    return observableThrowError(error);
  }

  public logout() {
    Log.i('AuthenticationService: Did logout');
    this.userLoggedIn = false;
    this.currentUser = null;
    localStorage.setItem(AppSettings.AUTH_LOCAL_STORAGE_LOGGED_IN_KEY, 'false');
    localStorage.setItem(AppSettings.AUTH_LOCAL_STORAGE_TOKEN_KEY, '');
  }

  public clearUserDataAndRedirect() {
    Log.i('AuthenticationService: clearing user data');
    this.logout();
    // TODO: Clear any user data for their session
    this.router.navigate(['/session-expired']);
  }

  public getCurrentUserID() {
    if (AppSettings.INTEGRATED_AUTHENTICATION) {
      return this.currentUser ? this.currentUser['id'] : 0;
    }

    let token = this.getToken();
    if (!token) {
      return 0;
    }
    let payload = JSON.parse(atob(token.split('.')[1]));
    if (!('identity' in payload)) {
      return 0;
    }
    return payload['identity'];
  }

  public getCurrentUser(): User {
    if (AppSettings.INTEGRATED_AUTHENTICATION) {
      // This will return null if we haven't resolved a request to refreshWhoAmI() yet
      return this.currentUser;
    }

    if (this.currentUser == null && this.getCurrentUserID() != 0) {
      // Restore user details from local storage
      // TODO: Only restore if token is still valid
      this.currentUser = JSON.parse(localStorage.getItem('current_user'));
    }
    return this.currentUser;
  }

  public updateToken(newToken: string) {
    Log.i('Storing new token:', newToken);
    localStorage.setItem(AppSettings.AUTH_LOCAL_STORAGE_TOKEN_KEY, newToken);
  }

  public isAdmin(): boolean {
    // XXX: Update this if you change the role for admin
    return this.isAuthenticated() && this.getCurrentUser().role === 'ADMIN';
  }

  public static getRoles(): Role[] {
    return [
      {role: 'AUTHOR', description: 'Creates content to be delivered to the workforce'},
      {role: 'APPROVER', description: 'Approves the information in content'},
      {role: 'PRESENTER', description: 'Determines which content to show, delivers to workers in session and records attendance'},
      {role: 'MANAGER', description: 'Reviews compliance and content delivery statistics. Trainer will have manager privileges to perform their role'},
      {role: 'ADMIN', description: 'Manages users and configuration of system'}
      ];
  }
}
