export class SessionMetric {
  constructor(public id: any = null,
              public user_id: any = null,
              public session_id: any = null,
              public group: any = null,
              public content_id: any = null,
              public link_id: any = null,
              public number_links_prior: number = 0,
              public content_page_number: number = 0,
              public session_page_number: number = 0,
              public sequence_number: number = 0,
              public start_time: any = null,
              public end_time: any = null,
              public duration: number = 0,
              public is_preview: boolean = false,
              public run_count: number = 0
              ) {}

  public static assign(sm: SessionMetric) {
    const n = Object.assign(new SessionMetric(), sm);

    return n;
  }
}

export class SessionMetricOverviewTimes {
  constructor(public session_id: any = null,
              public run_count: number = 0,
              public overall_duration: number = 0,
              public average_page_duration: number = 0,
              public min_content_id: any = null,
              public min_link_id: any = null,
              public min_session_page_number: any = null,
              public min_duration: number = 0,
              public max_content_id: any = null,
              public max_link_id: any = null,
              public max_session_page_number: any = null,
              public max_duration: number = 0,
              public time_viewed: number = 0
  ) {}
}

export class SessionMetricContentDuration {
  constructor(public session_id: any = null,
              public group_id: any = null,
              public content_id: any = null,
              public run_count: number = 0,
              public duration: number = 0
  ) {}
}
