import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {WorkerCrew} from '../models/worker_crew.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class WorkerCrewService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('WorkerCrewService Ready...');
  }

  getWorkerCrews(): Observable<WorkerCrew[]> {
    return this.http.get<WorkerCrew[]>(AppSettings.API_ENDPOINT + 'worker_crew', this.options);
  }

  getWorkerCrew(workerCrewID: number): Observable<WorkerCrew> {
    return this.http.get<WorkerCrew>(AppSettings.API_ENDPOINT + 'worker_crew/' + workerCrewID, this.options);
  }

  createWorkerCrew(workerCrew: WorkerCrew): Observable<WorkerCrew> {
    return this.http.post<WorkerCrew>(AppSettings.API_ENDPOINT + 'worker_crew', JSON.stringify(workerCrew), this.options);
  }

  updateWorkerCrew(workerCrew: WorkerCrew): Observable<WorkerCrew> {
    return this.http.put<WorkerCrew>(AppSettings.API_ENDPOINT + 'worker_crew/' + workerCrew.id, JSON.stringify(workerCrew), this.options);
  }

  deleteWorkerCrew(workerCrewID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'worker_crew/' + workerCrewID, this.options);
  }
}
