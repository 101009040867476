import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {SessionContent} from '../models/session_content.model';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Worker } from '../models/worker.model';

@Injectable()
export class SessionContentService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('SessionContentService Ready...');
  }

  getSessionContents(): Observable<SessionContent[]> {
    return this.http.get<SessionContent[]>(AppSettings.API_ENDPOINT + 'session_content', this.options);
  }

  getSessionContent(sessionContentID: number): Observable<SessionContent> {
    return this.http.get<SessionContent>(AppSettings.API_ENDPOINT + 'session_content/' + sessionContentID, this.options);
  }

  getSessionContentsBySession(sessionID: number): Observable<SessionContent[]> {
    return this.http.get<SessionContent[]>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/content', this.options);
  }

  getSessionContentsBySessionGroup(sessionID: number, groupID: number): Observable<SessionContent[]> {
    return this.http.get<SessionContent[]>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/' + groupID + '/content', this.options);
  }

  createSessionContent(sessionContent: SessionContent): Observable<SessionContent> {
    return this.http.post<SessionContent>(AppSettings.API_ENDPOINT + 'session_content', JSON.stringify(sessionContent), this.options);
  }

  updateSessionContent(sessionContent: SessionContent): Observable<SessionContent> {
    return this.http.put<SessionContent>(AppSettings.API_ENDPOINT + 'session_content/' + sessionContent.id, JSON.stringify(sessionContent), this.options);
  }

  deleteSessionContent(sessionContentID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'session_content/' + sessionContentID, this.options);
  }

  getSessionNextContent(sessionID: number, groupID: number): Observable<any> {
    const params = groupID ? new HttpParams().set('group', String(groupID)) : {};
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    }
    return this.http.get<any>(AppSettings.API_ENDPOINT + 'session_content/' + sessionID + '/next', httpOptions)
  }
}
