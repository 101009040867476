import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Crew} from '../models/crew.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CrewService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('CrewService Ready...');
  }

  getCrews(): Observable<Crew[]> {
    return this.http.get<Crew[]>(AppSettings.API_ENDPOINT + 'crew', this.options);
  }

  getCrew(crewID: number): Observable<Crew> {
    return this.http.get<Crew>(AppSettings.API_ENDPOINT + 'crew/' + crewID, this.options);
  }

  createCrew(crew: Crew): Observable<Crew> {
    return this.http.post<Crew>(AppSettings.API_ENDPOINT + 'crew', JSON.stringify(crew), this.options);
  }

  updateCrew(crew: Crew): Observable<Crew> {
    return this.http.put<Crew>(AppSettings.API_ENDPOINT + 'crew/' + crew.id, JSON.stringify(crew), this.options);
  }

  deleteCrew(crewID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'crew/' + crewID, this.options);
  }
}
