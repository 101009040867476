import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Shift} from '../models/shift.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ShiftService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('ShiftService Ready...');
  }

  getShifts(): Observable<Shift[]> {
    return this.http.get<Shift[]>(AppSettings.API_ENDPOINT + 'shift', this.options);
  }

  getShift(shiftID: number): Observable<Shift> {
    return this.http.get<Shift>(AppSettings.API_ENDPOINT + 'shift/' + shiftID, this.options);
  }

  createShift(shift: Shift): Observable<Shift> {
    return this.http.post<Shift>(AppSettings.API_ENDPOINT + 'shift', JSON.stringify(shift), this.options);
  }

  updateShift(shift: Shift): Observable<Shift> {
    return this.http.put<Shift>(AppSettings.API_ENDPOINT + 'shift/' + shift.id, JSON.stringify(shift), this.options);
  }

  deleteShift(shiftID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'shift/' + shiftID, this.options);
  }
}
