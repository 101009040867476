import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule, mapToCanActivate, mapToCanDeactivate} from '@angular/router';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {SessionViewComponent} from './components/pages/session-view/session-view.component';
import {CreateSessionComponent} from './components/pages/session-create/create-session.component';
import {CreateContentComponent} from './components/pages/content-create/create-content.component';
import {RecordSessionAttendeesComponent} from './components/pages/session-record-attendees/record-session-attendees.component';
import {ReviewContentAttendeesComponent} from './components/pages/content-review-attendees/review-content-attendees.component';
import {ContentLibraryComponent} from './components/pages/content-library/content-library.component';
import {ReportComponent} from './components/pages/report/report.component';
import {ContentViewComponent} from './components/pages/content-view/content-view.component';
import {SessionLibraryComponent} from './components/pages/session-library/session-library.component';
import {AccessManagementComponent} from './components/pages/user-access-management/access-management.component';
import {ContentManagementComponent} from './components/pages/content-management/content-management.component';
import {UserSettingsComponent} from './components/pages/user-settings/user-settings.component';
import {SessionMetricsComponent} from './components/pages/session-metrics/session-metrics.component';
import {CreateTitlepageComponent} from './components/pages/titlepage-create/create-titlepage.component';
import {LogoutComponent} from './components/pages/user-logout/logout.component';
import {NoNetworkComponent} from './components/pages/app-no-network/no-network.component';
import {NoAccessComponent} from './components/pages/user-no-access/no-access.component';
import {LoginComponent} from './components/pages/user-login/login.component';
import {AuthGuard} from './auth/auth.guard';
import {NotFoundComponent} from "./components/pages/app-not-found/not-found.component";
import {NavigateAwayGuard} from "./navigate-away-guard";
import {SystemHealthComponent} from "./components/pages/system-integration-health/system-health.component";
import {SystemSettingsComponent} from './components/pages/system-settings/system-settings.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {path: 'login', component: LoginComponent},
    {path: 'no-access', component: NoAccessComponent},
    {path: 'no-network', component: NoNetworkComponent},
    {path: 'not-found', component: NotFoundComponent},
    {path: 'logout', component: LogoutComponent},
    {path: 'session/library', canActivate: mapToCanActivate([AuthGuard]), component: SessionLibraryComponent},
    {path: 'session/create', canActivate: mapToCanActivate([AuthGuard]), component: CreateSessionComponent},
    {path: 'session/create/:id/:type', canActivate: mapToCanActivate([AuthGuard]), component: CreateSessionComponent},
    {path: 'session/show/:id/:type', canActivate: mapToCanActivate([AuthGuard]), component: SessionViewComponent},
    {path: 'session/show/:id/:group/:type', canActivate: mapToCanActivate([AuthGuard]), component: SessionViewComponent},
    {
        path: 'session/attendees/:id',
        canActivate: mapToCanActivate([AuthGuard]),
        canDeactivate: mapToCanDeactivate([NavigateAwayGuard]),
        component: RecordSessionAttendeesComponent
    },
    {
        path: 'session/attendees/:id/:group',
        canActivate: mapToCanActivate([AuthGuard]),
        canDeactivate: mapToCanDeactivate([NavigateAwayGuard]),
        component: RecordSessionAttendeesComponent
    },
    {path: 'session/metrics/:id', canActivate: mapToCanActivate([AuthGuard]), component: SessionMetricsComponent},
    {path: 'content/library', canActivate: mapToCanActivate([AuthGuard]), component: ContentLibraryComponent},
    {path: 'content/create', canActivate: mapToCanActivate([AuthGuard]), component: CreateContentComponent},
    {path: 'content/create/:id/:type', canActivate: mapToCanActivate([AuthGuard]), component: CreateContentComponent},
    {path: 'content/show/:id/:type', canActivate: mapToCanActivate([AuthGuard]), component: ContentViewComponent},
    {path: 'content/attendees/:id', canActivate: mapToCanActivate([AuthGuard]), component: ReviewContentAttendeesComponent},
    {path: 'content/attendees/compulsory', canActivate: mapToCanActivate([AuthGuard]), component: ReviewContentAttendeesComponent},
    {path: 'report', canActivate: mapToCanActivate([AuthGuard]), component: ReportComponent},
    {path: 'access-management', canActivate: mapToCanActivate([AuthGuard]), component: AccessManagementComponent},
    {path: 'content-management', canActivate: mapToCanActivate([AuthGuard]), component: ContentManagementComponent},
    {path: 'titlepage/create', canActivate: mapToCanActivate([AuthGuard]), component: CreateTitlepageComponent},
    {path: 'user-settings/:type', canActivate: mapToCanActivate([AuthGuard]), component: UserSettingsComponent},
    {path: 'user-settings/:type/:id', canActivate: mapToCanActivate([AuthGuard]), component: UserSettingsComponent},
    {path: 'session-metrics', canActivate: mapToCanActivate([AuthGuard]), component: SessionMetricsComponent},
    {path: 'system-health', canActivate: mapToCanActivate([AuthGuard]), component: SystemHealthComponent},
    {path: 'system-settings', canActivate: mapToCanActivate([AuthGuard]), component: SystemSettingsComponent},
    {path: 'dashboard', canActivate: mapToCanActivate([AuthGuard]), component: DashboardComponent},
    {path: '**', redirectTo: '/login'}
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {});
