<div class="container">
  <div class="row mt-2 d-flex align-items-center">
    <div class="col-9 pb-0">
      <h5 *ngIf="session && shiftDefinitions">
        <ng-container *ngIf="isPrestart(); else trainingTitle">
          {{getShift()}} Shift - {{session.shift_date | date: "EEE dd-MMMM-yyyy"}} - {{session.prestart_type_name}}
        </ng-container>
        <ng-template #trainingTitle>
          {{session.title}}
        </ng-template>
      </h5>
    </div>
    <div class="col-3 pb-0 d-flex justify-content-end align-items-center">
      <div>
        <div class="d-flex align-items-center" [matMenuTriggerFor]="dateRange">
          <small class="mb-0">Group: {{groupNumber}} </small>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
        <mat-menu #dateRange="matMenu" ngDefaultControl>
          <button mat-menu-item *ngFor="let groupNumber of groupNumbersObj"
                  (click)="updateSessionMetric(groupNumber.run_count)">{{groupNumber.run_count}}</button>
        </mat-menu>
      </div>
      <button mat-stroked-button color="accent" (click)="navigate('show', 'preview');">Preview</button>
    </div>
  </div>
  <div class="row">
    <div class="col-6 p-0">
      <div class="row m-0">
        <div class="col-6">
          <div class="card">
            <div class="card-body">
              <h5>Duration</h5>
              <h1 *ngIf="overviewTimes" class="font-weight-normal text-secondary">
                <ng-container *ngIf="getHours(overviewTimes.overall_duration) > 0">
                  {{getHours(overviewTimes.overall_duration)}}h
                </ng-container>
                <ng-container *ngIf="getMinutes(overviewTimes.overall_duration) > 0">
                  {{getMinutes(overviewTimes.overall_duration)}}m
                </ng-container>
                {{getSeconds(overviewTimes.overall_duration)}}s
              </h1>
            </div>
          </div>
        </div>
        <div class="col-6 pl-0">
          <div class="card">
            <div class="card-body">
              <h5>Presented By</h5>
              <h1 class="font-weight-normal text-secondary">{{userName.name}}</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card overflow">
          <div class="card-header">
            <p>Session Notes</p>
          </div>
          <div class="card-body">
            <p *ngIf="!session || session.comment.length < 1; else validComment">No Notes Recorded</p>
            <ng-template #validComment>
              <p>{{session.comment}}</p>
            </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="session && slides" class="col-12">
        <div class="card overflow">
          <div class="card-body">
            <ng-container *ngIf="isPrestart(); else trainingLabel">
              <h5 class="mb-4">Prestart Content</h5>
            </ng-container>
            <ng-template #trainingLabel>
              <h5 class="mb-4">Training Content</h5>
            </ng-template>
            <div *ngIf="titlepage && session.titlepage_id && getTitlepage()" class="row d-flex align-items-top m-2">
              <div *ngIf="renderedTitlepageImage === undefined; else titleImageLoaded">
                <mat-spinner></mat-spinner>
              </div>
              <ng-template #titleImageLoaded>
                <img class="thumbnail col-2 p-0" [src]=renderedTitlepageImage>
              </ng-template>
              <div class="col-4 p-0">
                <p class="mb-0 mt-1 text-wrap col-12">Title Page</p>
              </div>
            </div>
            <div class="row d-flex align-items-top m-2">
              <div class="col-5">
                <p class="mb-0 mt-1">Content</p>
              </div>
              <div class="col-3">
                <p class="mb-0 mt-1">Delivery</p>
              </div>
              <div class="col-2">
                <p class="mb-0 mt-1">Approved Date</p>
              </div>
              <div class="col-2">
                <p class="mb-0 mt-1">Approver</p>
              </div>
            </div>
              <ng-container *ngIf="slides.length > 0; else loadingPrestartContent">
                  <div class="row d-flex align-items-top m-2" *ngFor="let slide of slides; let i = index">
                      <ng-container *ngIf="i !== 0 && isNewGroup(i)">
                          <hr/>
                      </ng-container>
                      <ng-container *ngIf="renderedSlideFirstPageImage[i] !== undefined; else noSlidePreview">
                          <img class="thumbnail col-2 p-0" [src]=renderedSlideFirstPageImage[i] alt="Preview Missing">
                      </ng-container>
                      <ng-template #noSlidePreview>
                          <span class="thumbnail col-2 p-0"></span>
                      </ng-template>
                      <div class="col-3 p-0">
                          <p class="mb-0 mt-1 text-wrap col-12">{{slide.title}}</p>
                          <ng-container *ngIf="renderedSlideFirstPageImage[i] !== undefined">
                              <div *ngIf="slide.content_file" matTooltip="{{getFilename(slide.content_file)}}"
                                   class="text-truncate col-12 sm-font">
                                  {{getFilename(slide.content_file)}}</div>
                          </ng-container>
                      </div>
                      <div *ngIf="deliverys" class="col-3 deliverys">
                          <p class="mb-0 mt-1" [ngStyle]="{'color': contentDelivery(slide.delivery_id).colour}">
                              {{contentDelivery(slide.delivery_id).title}}</p>
                          <small *ngIf="slide.is_compulsory">
                              <mat-icon class="ml-2" matTooltip="Compulsory Content">error</mat-icon>
                          </small>
                      </div>
                      <div *ngIf="slides" class="col-2">
                          <p class="mb-0 mt-1">
                              {{approvedDate(slide.id)}}</p>
                      </div>
                      <div *ngIf="userNames && slides" class="col-2">
                          <p class="mb-0 mt-1">
                              {{getUser(slide.user_id)}}</p>
                      </div>
                  </div>
              </ng-container>
              <ng-template #loadingPrestartContent>
                  <mat-spinner color="warn" [diameter]="50"></mat-spinner>
              </ng-template>
          </div>
        </div>
      </div>
      <div *ngIf="sessionWorkers" class="col-12">
        <div class="card overflow">
          <div class="card-body">
            <div class="row align-items-center">
                <h5 class="col-2 align-self-center">Attendees</h5>
                <div class="col-10 p-0 d-flex align-items-center justify-content-around stretch-button-group">
                  <mat-button-toggle-group [(ngModel)]="selectedCrewID" name="Crew" class="w-50">
                    <mat-button-toggle (click)="updateFilter(0)" [value]="0">All</mat-button-toggle>
                    <mat-button-toggle *ngFor="let mainCrew of mainCrews" (click)="updateFilter(mainCrew.id)" [value]="mainCrew.id">
                      {{mainCrew.abbrev}}</mat-button-toggle>
                  </mat-button-toggle-group>
                  <mat-form-field appearance="outline" class="m-0 p-0 w-50 no-subscript remove-left-border">
                    <mat-label>Select Supporting Crews</mat-label>
                    <mat-select [(ngModel)]="selectedCrewID" placeholder="Select Supporting Crews">
                      <mat-option *ngFor="let supportCrew of supportCrews" (click)="updateFilter(supportCrew.id)"
                                  [value]="supportCrew.id">{{supportCrew.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </div>
            <div class="col-12 pt-2">
              <mat-chip-listbox>
                <span *ngFor="let sessionWorker of sessionWorkersList()">
                  <mat-chip *ngIf="sessionWorker" color="primary" selected>
                    <span>
                      <span *ngIf="attended(sessionWorker.worker_id); else absent">
                        <mat-icon class="text-success">check_circle</mat-icon>
                      </span>
                      <ng-template #absent>
                        <span>
                          <mat-icon class="text-danger">remove_circle</mat-icon>
                        </span>
                      </ng-template>
                      {{sessionWorker.worker_first_name}} {{sessionWorker.worker_surname}}
                    </span>
                  </mat-chip>
                </span>
              </mat-chip-listbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 p-0">
      <div class="col-12 pl-0">
        <div class="card">
          <div class="card-body">
            <p>Session Overview</p>
            <mat-list>
              <mat-list-item class="border-bottom">
                <h5 mat-line class="text-secondary">Time Viewed</h5>
                <span *ngIf="overviewTimes"
                      class="w-50 text-right text-dark">{{overviewTimes.time_viewed | date: "hh:mm a EEE dd-MMMM-yyyy"}}</span>
              </mat-list-item>
              <mat-list-item class="border-bottom">
                <h5 mat-line class="text-secondary">Average Page Duration:</h5>
                <p *ngIf="overviewTimes" class="w-25 text-right text-dark">
                  <ng-container *ngIf="getHours(overviewTimes.average_page_duration) > 0">
                    {{getHours(overviewTimes.average_page_duration)}}h
                  </ng-container>
                  <ng-container *ngIf="getMinutes(overviewTimes.average_page_duration) > 0">
                    {{getMinutes(overviewTimes.average_page_duration)}}m
                  </ng-container>
                  {{getSeconds(overviewTimes.average_page_duration)}}s
                </p>
              </mat-list-item>
              <mat-list-item class="border-bottom">
                <h5 mat-line class="text-secondary">Shortest Page Duration:
                  <span *ngIf="overviewTimes" class="text-dark">Page {{overviewTimes.min_session_page_number}}</span>
                </h5>
                <p *ngIf="overviewTimes" class="w-25 text-right text-dark">
                  <ng-container *ngIf="getHours(overviewTimes.min_duration) > 0">
                    {{getHours(overviewTimes.min_duration)}}h
                  </ng-container>
                  <ng-container *ngIf="getMinutes(overviewTimes.min_duration) > 0">
                    {{getMinutes(overviewTimes.min_duration)}}m
                  </ng-container>
                  {{getSeconds(overviewTimes.min_duration)}}s
                </p>
              </mat-list-item>
              <mat-list-item>
                <h5 mat-line class="text-secondary">Longest Page Duration:
                  <span *ngIf="overviewTimes" class="text-dark">Page {{overviewTimes.max_session_page_number}}</span>
                </h5>
                <p *ngIf="overviewTimes" class="w-25 text-right text-dark">
                  <ng-container *ngIf="getHours(overviewTimes.max_duration) > 0">
                    {{getHours(overviewTimes.max_duration)}}h
                  </ng-container>
                  <ng-container *ngIf="getMinutes(overviewTimes.max_duration) > 0">
                    {{getMinutes(overviewTimes.max_duration)}}m
                  </ng-container>
                  {{getSeconds(overviewTimes.max_duration)}}s
                </p>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
      <div class="col-12 pl-0">
        <div class="card overflow">
          <table class="ctm-table">
            <tr>
              <th>
                Slide
              </th>
              <th class="text-right">
                Duration
              </th>
            </tr>
            <ng-container *ngIf="sessionMetricContentDurations.length > 0 && sessionMetrics.length > 0">
              <span *ngFor="let sessionMetric of sessionMetrics; let pageIdx = index">
                <ng-container *ngIf="renderedImage[pageIdx] !== null; else isLink">
                  <tr class="py-3">
                    <div *ngIf="renderedImage[pageIdx] === undefined; else imageLoaded">
                      <mat-spinner color="warn" [diameter]="70"></mat-spinner>
                    </div>
                    <ng-template #imageLoaded>
                      <img class="thumbnail col-2 p-0" [src]="renderedImage[pageIdx]" alt="Preview Missing">
                    </ng-template>
                    <div class="col-6 p-0">
                      <div class="mb-0 text-wrap col-12 py-0 sm-font">Slide {{sessionMetric.sequence_number}}</div>
                    </div>
                    <div class="col-4 text-right">
                      <p>
                        <ng-container *ngIf="getHours(getDuration(pageIdx)) > 0">
                          {{getHours(getDuration(pageIdx))}}h</ng-container>
                        <ng-container *ngIf="getMinutes(getDuration(pageIdx)) > 0">
                          {{getMinutes(getDuration(pageIdx))}}m</ng-container>
                        {{getSeconds(getDuration(pageIdx))}}s
                      </p>
                    </div>
                  </tr>
                </ng-container>
                <ng-template #isLink>
                  <tr class="py-3">
                    <div class="col-2 p-0 d-flex justify-content-center align-items-center link-thumb">
                      <mat-icon>launch</mat-icon>
                    </div>
                    <div class="col-6 p-0">
                      <div class="mb-0 text-wrap col-12 py-0 sm-font">{{getLinkName(sessionMetric.link_id)}}</div>
                      <div matTooltip="Slide" class="text-truncate py-0 col-12 sm-font">
                        {{getLinkURL(sessionMetric.link_id)}}</div>
                    </div>
                    <div class="col-4 text-right">
                      <p>
                        <ng-container *ngIf="getHours(getDuration(pageIdx)) > 0">
                          {{getHours(getDuration(pageIdx))}}h</ng-container>
                        <ng-container *ngIf="getMinutes(getDuration(pageIdx)) > 0">
                          {{getMinutes(getDuration(pageIdx))}}m</ng-container>
                        {{getSeconds(getDuration(pageIdx))}}s
                      </p>
                    </div>
                  </tr>
                </ng-template>
              </span>
            </ng-container>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
