<div class="container">
  <div *ngIf="!hasUploadStarted;else uploadStarted">
    <div class="row d-flex align-items-center">
      <div class="col-9 p-0">
        <ng-container *ngIf="titlepage.id !== null; else noTitlepage">
            <button mat-stroked-button (click)="onDelete()">Delete</button>
        </ng-container>
        <ng-template #noTitlepage>
          <button mat-stroked-button color="accent" [disabled]="checkCreateInvalid()"
                  (click)="saveTitlepageFile()">Create</button>
        </ng-template>
        <button mat-stroked-button [routerLink]="['/content-management']">Cancel</button>
      </div>
    </div>

    <div class="row">
      <div class="col-6 p-0">
        <div class="col-12 pl-0">
            <div class="card">
              <div class="card-body">
                <div class="col-12" *ngIf="!file; else fileSelected">
                  <label>
                    <input accept=".pdf" type="file" autocomplete="off" (change)="handleFileInput($event.target.files)" style="display:none">
                    <a mat-stroked-button color="accent">
                      Browse File ...
                    </a>
                  </label>
                </div>
                <ng-template #fileSelected>
                  <ng-container *ngIf="!hasUploadStarted;else uploadStarted">
                    <ng-container *ngIf="titlepage.id === null; else notCreate">
                    <div class="d-flex justify-content-between align-items-center col-12">
                      <div>{{file.name}}</div>
                      <button mat-button *ngIf="file" (click)="file = null">Clear</button>
                    </div>
                    </ng-container>
                    <ng-template #notCreate>
                      <div class="d-flex justify-content-between align-items-center col-12">
                        <div>{{titlepage.filename}}</div>
                        <button mat-button *ngIf="titlepage" (click)="titlepage.id = null">Clear</button>
                      </div>
                    </ng-template>
                  </ng-container>
                  <div class="invalid-files-message mt-4 text-center" *ngIf="file && !getValidFileSize()">File is too large. Please clear the file and select a file smaller than {{getMaxFileSizeText()}} MB.</div>
                  <div class="invalid-files-message mt-4 text-center" *ngIf="file && !getValidFileType()">File must be type PDF. Please clear the file and select a PDF file.</div>
                </ng-template>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #uploadStarted>
    <div class="card col-12 pt-3">
      <div class="p-4 text-center">
        <i>Please wait, while the file is being uploaded...</i>
      </div>
      <mat-progress-bar [value]="getCurrentUploadPercentCompleted()"></mat-progress-bar>
      <div class="p-4 text-center">{{getCurrentUploadPercentCompleted()}} %
        <br/>
        <small>{{getCurrentUploadBytesCompleted() | fileSize}} / {{totalFileSize | fileSize}}</small>
      </div>
    </div>
  </ng-template>
</div>
