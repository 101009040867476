<app-file-upload-target></app-file-upload-target>
<div class="row m-0 jumbotron jumbotron-fluid p-0">
    <mat-progress-bar *ngIf="dashboardData.length < ranges.length;" color="warn"
                      mode="indeterminate"></mat-progress-bar>
    <div class="container mb-3">
        <h5 class="metrics mt-5 mb-3" style="color: white">Performance Metrics</h5>
        <div class="col-12 p-0">
            <div class="row px-3 py-0">
            </div>
            <ng-container *ngIf="dashboardData.length < ranges.length; else dashboardStatisticsExists">
                <p class="text-center text-white">Metrics Loading, please wait..</p>
            </ng-container>
            <ng-template #dashboardStatisticsExists>
                <div *ngFor="let range of ranges">
                    <ng-container *ngIf="getDashboardStatistics(range.value)">
                        <div class="row py-0 pb-3">
                            <mat-card appearance="outlined" class="card-row-title col-2 bg-light rounded-left">
                                <h3 class="text-black mb-0 py-2">{{range.viewValue}}</h3>
                            </mat-card>
                            <mat-card appearance="outlined" class="col-10 rounded-right" [class.border-success]="range.value === 'shift'">
                                <div class="row ">
                                    <div class="col-4">
                                        <p class="mb-0 pt-2">Critical Content Coverage</p>
                                        <div class="row d-flex mt-0 jusitfy-content-between align-items-center">
                                            <h1 class="text-success font-weight-normal">
                                                {{getDashboardStatistics(range.value).critical_coverage}}%</h1>
                                            <!--                      <mat-icon class="mx-3 text-success">warning</mat-icon>-->
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <p class="mb-0 pt-2">Average Presentation Duration</p>
                                        <h1 class="text-success font-weight-normal average-duration"
                                            *ngIf="getHours(getDashboardStatistics(range.value).average_duration) > 0">
                                            {{getHours(getDashboardStatistics(range.value).average_duration)}}h</h1>
                                        <h1 class="text-success font-weight-normal average-duration"
                                            *ngIf="getMinutes(getDashboardStatistics(range.value).average_duration) > 0">
                                            {{getMinutes(getDashboardStatistics(range.value).average_duration)}}m</h1>
                                        <h1 class="text-success font-weight-normal average-duration">
                                            {{getSeconds(getDashboardStatistics(range.value).average_duration)}}s</h1>
                                    </div>
                                    <div class="col-4">
                                        <p class="mb-0 pt-2">Presentation Attendance Overall</p>
                                        <h1 class="text-success font-weight-normal">
                                            {{getDashboardStatistics(range.value).overall_attendance}}%</h1>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="container d-flex flex-wrap">
    <ng-container *ngIf="sessionOverviews; else noSessionsLoaded">
        <ng-container *ngFor="let prestartType of prestartTypes; let k=index">
            <div class="col-12 bg-light-grey mt-4">
                <h5 class="mt-3">{{prestartType.name}} PreStarts</h5>
            </div>
            <div class="col-12 row mt-0 bg-light-grey">
                <ng-container *ngFor="let sessionOverview of sessionOverviews; let i=index">
                    <ng-container *ngIf="sessionOverview.prestart_type_id === prestartType.id">
                        <div class="pb-0 col-4">
                            <mat-card appearance="outlined" class="shift"
                                      [class.bg-info]="shiftLabels[getInt(i, prestartTypes.length)] === 'Current' && sessionOverview.number_contents < 1">
                                <div class="card-header d-flex align-items-center"
                                     [ngClass]="shiftLabels[getInt(i, prestartTypes.length)] === 'Current' && sessionOverview.number_contents > 0 ? 'bg-success' :
                                     (shiftLabels[getInt(i, prestartTypes.length)] === 'Current' ? 'bg-secondary' : sessionOverview.number_contents > 0 ? 'bg-secondary' : 'bg-secondary')">
                                    <mat-icon matTooltip="There is no content added for this shift"
                                              *ngIf="shiftLabels[getInt(i, prestartTypes.length)] === 'Current' && sessionOverview.number_contents < 1"
                                              class="text-info mr-2">
                                        warning
                                    </mat-icon>
                                    <p
                                        class="mb-0 text-white">{{shiftLabels[getInt(i, prestartTypes.length)]}} {{sessionOverview.sd_name}}
                                        Shift</p>
                                </div>
                                <mat-card-content>
                                    <h5
                                        [ngClass]="shiftLabels[getInt(i, prestartTypes.length)] === 'Current' && sessionOverview.number_contents > 0 ? 'text-success' :
                                        (shiftLabels[getInt(i, prestartTypes.length)] === 'Current' ? 'text-secondary' : sessionOverview.number_contents > 0 ? 'text-secondary' : 'text-secondary')">
                                        {{sessionOverview.sd_name}}
                                        - {{sessionOverview.shift_date | date:'dd MMM, yyyy'}}</h5>
                                    <div class="d-flex align-items-center mb-0">
                                        <h2 class="mr-3 font-weight-normal"
                                            [ngClass]="shiftLabels[getInt(i, prestartTypes.length)] === 'Current' && sessionOverview.number_contents > 0 ? 'text-success' :
                                            (shiftLabels[getInt(i, prestartTypes.length)] === 'Current' ? 'text-info' : sessionOverview.number_contents > 0 ? 'text-secondary' : 'text-info')">
                                            {{sessionOverview.number_contents}}
                                            <span *ngIf="sessionOverview.number_contents == null">
                                              0
                                            </span>
                                        </h2>
                                        <span>Contents</span>
                                        <ng-template #shown>
                                            <i>Shown</i>
                                        </ng-template>
                                    </div>
                                </mat-card-content>
                                <div class="row p-3 pt-0">
                                    <ng-container *ngIf="sessionOverview.session_id; else noValidSession">
                                        <ng-container *ngIf="sessionOverview.is_editable; else shown">
                                            <div class="btn-group w-100">
                                                <button class="btn btn-outline-secondary"
                                                        (click)="navigate('create', sessionOverview.session_id, 'edit')">
                                                    Edit
                                                </button>
                                                <button class="btn btn-outline-secondary"
                                                        (click)="navigate('show', sessionOverview.session_id, 'present')">
                                                    Present
                                                </button>
                                            </div>
                                        </ng-container>
                                        <ng-template #shown>
                                            <div class="btn-group w-100">
                                                <button class="btn btn-outline-secondary"
                                                        (click)="navigate('create', sessionOverview.session_id, 'copy')">
                                                    Copy
                                                </button>
                                                <button class="btn btn-outline-secondary"
                                                        *ngIf="sessionMetrics"
                                                        (click)="navigate('metrics', sessionOverview.session_id)">
                                                    Metrics
                                                </button>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #noValidSession>
                                        <button class="w-100 btn" [routerLink]="['/session/create']"
                                                [queryParams]="{date: sessionOverview.shift_date, shift: sessionOverview.shift_definition_id}"
                                                [ngClass]="shiftLabels[getInt(i, prestartTypes.length)] === 'Current' && sessionOverview.number_contents > 0 ? 'btn-success' :
                                                (shiftLabels[getInt(i, prestartTypes.length)] === 'Current' ? 'btn-info text-white' : sessionOverview.number_contents > 0 ? 'btn-secondary' : 'btn-info text-white')">
                                            Create
                                        </button>
                                    </ng-template>
                                </div>
                            </mat-card>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #noSessionsLoaded>
        <div class="d-flex justify-content-center align-items-center error-banner">
            <div class="text-white text-center">
                <h2>Service Not Available</h2>
                <h5>Please contact support</h5>
            </div>
        </div>
    </ng-template>
</div>
