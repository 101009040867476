import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { Log } from "../../../helpers/log.helper";
import { errorLookup } from '../../../helpers/error.helper';

@Component({
  selector: 'devfu-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginData = { username: '', password: '' };
  error;
  isLoading = false;
  showSessionExpiredMessage = false;

  constructor(public router: Router,
              public authService: AuthenticationService,
              public activatedRoute: ActivatedRoute) {

  }

  onLogin() {
    this.isLoading = true;
    let promise = this.authService.login(this.loginData.username, this.loginData.password)
      .then(x => {
        this.authService.refreshWhoAmI().then(user => {
          (user.role === 'PRESENTER' || user.role === 'MANAGER' || user.role === 'ADMIN') ? this.router.navigate(['dashboard']) : this.router.navigate(['/content/library']);
        });
        // this.router.navigate(['dashboard']);
      }).catch(err => {
        Log.e("There was an error logging in :(")
        this.error = errorLookup(err);
        this.isLoading = false;
        setTimeout(() => {
          this.error = false;
        }, 5000);
      });

  }

  ngOnInit(): void {
    // this.breadcrumbService.setCurrentPath([
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.hasOwnProperty('session_expired')) {
        if (queryParams['session_expired'] == 'true') {
          this.showSessionExpiredMessage = true;
        }
      }
    });

    if (this.authService.isAuthenticated()) {
      this.authService.refreshWhoAmI().then(user => {
        (user.role === 'PRESENTER' || user.role === 'MANAGER' || user.role === 'ADMIN') ? this.router.navigate(['dashboard']) : this.router.navigate(['/content/library']);
      });
    }
  }


}
