<div class="body-container">
    <div class="card card-transparent text-center"> 
        <div class="m-3 d-flex align-items-center justify-content-center">
            <i class="material-icons md-48 mr-2">lock</i>
            <h2 class="md-48">No Access - Please contact your IT department.</h2>
        </div>
        <h4 class="m-2">Your site is in Integrated Authentication mode and your user does not have access to the requested resource
        </h4>
    </div>
</div>
