import {Observable, of} from 'rxjs';
import {DialogConfirm} from '../components/dialogs/confirm.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {DialogError} from "../components/dialogs/error.component";
import {DialogWorkers} from "../components/dialogs/compulsory-content/workers";
import {ContentWorker} from "../models/worker.model";
import {Worker} from "../models/worker.model";
import {Log} from "../helpers/log.helper";
import {Session} from "../models/session.model";

@Injectable()
export class DialogsService {

  constructor(private dialog: MatDialog) {
  }

  public confirm(title: string, message: string): Observable<boolean> {

    let dialogRef: MatDialogRef<DialogConfirm>;

    dialogRef = this.dialog.open(DialogConfirm, {
      data: { title: title, message: message },
    });

    return dialogRef.afterClosed();

  }

  public error(title: string, message: string): Observable<boolean> {

    let dialogRef: MatDialogRef<DialogError>;

    dialogRef = this.dialog.open(DialogError, {
      data: { title: title, message: message },
    });

    return dialogRef.afterClosed();

  }

  public workers(title: string, staying: Worker[], leaving: Worker[]): Observable<boolean> {

    let dialogRef: MatDialogRef<DialogWorkers>;

    dialogRef = this.dialog.open(DialogWorkers, {
      width: '80%',
      height: 'auto',
      data: { title: title, staying: staying, leaving: leaving },
    });

    return dialogRef.afterClosed();

  }

  public openDialogs(): boolean {
    let openDialogs = this.dialog.openDialogs;
    return (openDialogs.length > 0)
  }

  public navigateAway(message?: string): Observable<boolean> {
    /**
     * Ask user to confirm an action. `message` explains the action and choices.
     * Returns observable resolving to `true`=confirm or `false`=cancel
     */
    const confirmation = window.confirm(message || 'Navigate away ?');

    return of(confirmation);
  }
}
