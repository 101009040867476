<div class="container">
    <div class="row d-flex align-items-center">
        <div class="col-6 pb-0">
            <h5>Presentation Library</h5>
        </div>
        <div class="col-6 pb-0 text-right">
            <button mat-flat-button color="accent" class="mb-2" [routerLink]="['/session/create']">Create Presentation
            </button>
        </div>
    </div>

    <div class="row d-flex align-items-center">
        <mat-form-field appearance="outline" class="col-3">
            <mat-icon matPrefix class="mr-2">search</mat-icon>
            <mat-label>Search by Title</mat-label>
            <input matInput placeholder="Start search by typing.." type="text" autocomplete="off"
            [(ngModel)]="filterValues.title" (ngModelChange)="updateFilters()">
            <span matSuffix class="d-flex align-items-center">
                <button *ngIf="filterValues.title" mat-icon-button (click)="filterValues.title = null; updateFilters()">
                    <mat-icon>clear</mat-icon>
                </button>
            </span>
        </mat-form-field>
        <!-- TODO: Why do we need two seperate searches? Is this so they can search by date typing? -->
        <mat-form-field appearance="outline" class="col-3 pl-0">
            <mat-icon matPrefix class="mr-2">search</mat-icon>
            <mat-label>Search by Date</mat-label>
            <input matInput autocomplete="off" type="text" [(ngModel)]="filterValues.shiftDate"
                (dateChange)="updateFilters()"
                [matDatepicker]="sessionPicker">
            <span matSuffix class="d-flex align-items-center">
                <mat-datepicker-toggle [for]="sessionPicker">
                <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #sessionPicker></mat-datepicker>
                <button *ngIf="filterValues.shiftDate" mat-icon-button (click)="filterValues.shiftDate = null; updateFilters()">
                    <mat-icon>clear</mat-icon>
                </button>
            </span>
        </mat-form-field>
        <div class="col-3 p-0">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Created By</mat-label>
                <mat-select [(ngModel)]="userID"
                            (ngModelChange)="filterValues.userID = updateFilter(userID); updateFilters()"
                            placeholder="Created By">
                    <mat-option [value]="-1">All</mat-option>
                    <mat-option *ngFor="let user of userNames" [value]="user.id">
                        {{user.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-3">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="sessionType"
                            (ngModelChange)="filterValues.sessionType = updateFilter(sessionType); updateFilters()"
                            placeholder="Type">
                    <mat-option [value]="-1">All</mat-option>
                    <mat-option [value]="1">Prestart</mat-option>
                    <mat-option [value]="0">Training</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="col-12">
        <app-loading *ngIf="!dataSource"></app-loading>
        <table class="presentation-library-table" mat-table [ngStyle]="{'opacity' : !dataSource ? 0 : 1}" [dataSource]="dataSource" matSort>
            <!-- Title / Shift Date Column -->
            <ng-container matColumnDef="shift_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Shift Date/Title</th>
                <td mat-cell *matCellDef="let session">
                    <ng-container *ngIf="isPrestart(session.id); else trainingTitle">
                        {{getShift(session.id)}} - {{session.shift_date | date: "EEEE, dd MMMM yyyy"}}
                        - {{getType(session.id)}}
                    </ng-container>
                    <ng-template #trainingTitle>
                        {{session.title}}
                    </ng-template>
                </td>
            </ng-container>

            <!--   Presentation Type Column   -->
            <ng-container matColumnDef="presentation_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Presentation Type</th>
                <td mat-cell *matCellDef="let session">
                    <ng-container *ngIf="isPrestart(session.id); else trainingTitle">
                        PreStart
                    </ng-container>
                    <ng-template #trainingTitle>
                        Training
                    </ng-template>
                </td>
            </ng-container>

            <!-- Created By Column -->
            <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By</th>
                <td mat-cell *matCellDef="let session">
                    {{getUser(session.id)}}
                </td>
            </ng-container>


            <!-- Attendance Column -->
            <ng-container matColumnDef="attendance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Attendance</th>
                <td mat-cell *matCellDef="let session">
                    <p class="mb-0" [ngClass]="checkAttendance(session.id)">{{getAttendance(session.id)}}</p>
                </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let session" class="action-column">
                    <div class="d-flex justify-content-between">
                        <div>
                            <button *ngIf="getEditable(session); else showMetricsBtn" class="action present px-2" mat-stroked-button
                                    (click)="navigate('show', session.id, 'present')">Present
                            </button>
                            <ng-template #showMetricsBtn>
                                <button mat-stroked-button class="action px-2"
                                        *ngIf="sessionMetrics && checkValidMetrics(session.id)"
                                        (click)="navigate('metrics',session.id)">Metrics
                                </button>
                            </ng-template>
                        </div>
                        <div>
                            <div *ngIf="!getEditable(session) else actionBtn">
                                <button mat-stroked-button (click)="navigate('create', session.id, 'copy')">
                                    Copy
                                </button>
                            </div>
                            <ng-template #actionBtn>
                                <button class="dropdown p-0" mat-stroked-button [matMenuTriggerFor]="editMenu">
                                    <span class="pl-2 d-none d-xl-inline-flex">Action</span>
                                    <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                                </button>
                                <mat-menu #editMenu="matMenu">
                                    <button mat-menu-item *ngIf="getEditable(session)"
                                            (click)="navigate('create', session.id, 'edit')">Edit
                                    </button>
                                    <button mat-menu-item (click)="navigate('create', session.id, 'copy')">
                                        Copy
                                    </button>
                                </mat-menu>
                            </ng-template>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.shift_date ?
                'border-success' : 'border-warning'"></tr>
        </table>

        <mat-paginator #paginator [ngStyle]="{'opacity' : !dataSource ? 0 : 1}"
                       [pageSizeOptions]="pageSizeOptions"
                       showFirstLastButtons></mat-paginator>
    </div>
</div>
