import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Link} from '../models/link.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LinkService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('LinkService Ready...');
  }

  getLinks(): Observable<Link[]> {
    return this.http.get<Link[]>(AppSettings.API_ENDPOINT + 'link', this.options);
  }

  getLink(linkID: number): Observable<Link> {
    return this.http.get<Link>(AppSettings.API_ENDPOINT + 'link/' + linkID, this.options);
  }

  getLinksByContent(contentID: number): Observable<Link[]> {
    return this.http.get<Link[]>(AppSettings.API_ENDPOINT + 'link/content/' + contentID, this.options);
  }

  createLink(link: Link): Observable<Link> {
    return this.http.post<Link>(AppSettings.API_ENDPOINT + 'link', JSON.stringify(link), this.options);
  }

  updateLink(link: Link): Observable<Link> {
    return this.http.put<Link>(AppSettings.API_ENDPOINT + 'link/' + link.id, JSON.stringify(link), this.options);
  }

  deleteLink(linkID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'link/' + linkID, this.options);
  }
}
