
import {tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppSettings} from '../app.settings';
import {Router} from '@angular/router';
import {DialogsService} from "../services/dialogs.service";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private dialogsService: DialogsService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestWithCustomHeaders = request.clone({
      setHeaders: {}
    });

    return next.handle(requestWithCustomHeaders).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

        if (event.headers.has('x-new-token')) {
          localStorage.setItem(AppSettings.AUTH_LOCAL_STORAGE_TOKEN_KEY, event.headers.get('x-new-token'));
          localStorage.setItem(AppSettings.AUTH_LOCAL_STORAGE_LOGGED_IN_KEY, 'true');
        }

      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 ) {
          // redirect to the login route
          this.router.navigateByUrl('/login');
	      } else if (err.status === 403 && AppSettings.INTEGRATED_AUTHENTICATION) {
          this.router.navigateByUrl('/no-access');
        } else if (err.status === 500) {
            this.dialogsService
              .error('', 'Internal error occurred. Please contact your system administrator')
              .subscribe(res => {
                if (res) {
                  location.reload();
                }
              });
        } else if (err.status === 404) {
          this.router.navigateByUrl('/not-found');
        }
      }
    }));
  }
}
