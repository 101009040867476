export class User {
  constructor(public id: any = null,
              public name: string = '',
              public email: string = '',
              public password: string = '',
              public remote_system_identity: string = '',
              public role: string = null,
              public enabled: boolean = false
              ) {}

  public static assign(u: User) {
    const n = Object.assign(new User(), u);

    return n;
  }
}

export class UserName {
  constructor(public id: any = null,
              public name: string = ''
  ) {}
}
