<div class="container">
  <div class="row mt-2 d-flex align-items-center">
    <div class="col-6 pb-0 my-3">
      <h5>System Health</h5>
    </div>
  </div>
  <div class="row">
    <ng-container *ngIf="integrationLog !== null">
      <div class="col-4">
        <mat-card appearance="outlined" class="card">
          <p class="d-flex align-items-center">
            <mat-icon class="mr-2">update</mat-icon>
            Time since last successful Lineup update (expected every 24 hrs):
          </p>
          <ng-container *ngIf="lastUpdate() > 0; else noUpdate">
            <h3 class="text-center mt-2">
              <ng-container *ngIf="getHours(lastUpdate()) > 0">
                {{getHours(lastUpdate())}}h
              </ng-container>
              <ng-container *ngIf="getMinutes(lastUpdate()) > 0">
                {{getMinutes(lastUpdate())}}m
              </ng-container>
              {{getSeconds(lastUpdate())}}s
            </h3>
          </ng-container>
          <ng-template #noUpdate>
            <h3 class="text-center mt-2">No Update</h3>
          </ng-template>
        </mat-card>
      </div>
    </ng-container>
    <ng-container *ngIf="numberWorkers !== null">
      <div class="col-4">
        <mat-card appearance="outlined" class="card">

          <p class="d-flex align-items-center">
            <mat-icon class="mr-2">people_outline</mat-icon>
            Number workers in system:
          </p>
          <h3 class="text-center mt-2">{{numberWorkers}}</h3>

        </mat-card>
      </div>
    </ng-container>
    <ng-container *ngIf="numberActiveWorkers !== null">
      <div class="col-4">
        <mat-card appearance="outlined" class="card">

          <p class="d-flex align-items-center">
            <mat-icon class="mr-2">people</mat-icon>
            Number active workers in system:
          </p>
          <h3 class="text-center mt-2">{{numberActiveWorkers}}</h3>

        </mat-card>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="row mt-2 d-flex align-items-center">
      <div class="col-12 pb-0 my-3">
        <h5>Integration Log</h5>
      </div>
    </div>
    <div class="col-12">
      <table class="ctm-table">
        <tr>
          <th class="col-2">Event Time</th>
          <th class="col-3">Source</th>
          <th class="col-1">Status</th>
          <th class="col-6">Message</th>
        </tr>
        <tr *ngFor="let integrationLogEntry of integrationLogs">
          <td class="col-2">{{integrationLogEntry.event_time | date:'dd-MM-yyyy hh:mm:ss a'}}</td>
          <td class="col-3">{{integrationLogEntry.source}}</td>
          <td class="col-1">{{integrationLogEntry.status}}</td>
          <td class="col-6">{{integrationLogEntry.message}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
