import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-file-upload-hint",
    templateUrl: "./file-upload-hint.component.html",
    styleUrls: ["./file-upload-hint.component.scss"]
})
export class FileUploadHintComponent implements OnInit {
    readonly message = 'Release to create content with file';

    constructor() {
    }

    ngOnInit(): void {
    }

}
