import { Component, OnInit } from "@angular/core";
import { Content } from "../../../models/content.model";
import { ContentService } from "../../../services/content.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Log } from "../../../helpers/log.helper";
import { FileService, FileUploadStatus } from "../../../services/file.service";
import { environment as env } from "../../../../environments/environment";
import { Delivery } from "../../../models/delivery.model";
import { Category } from "../../../models/category.model";
import { DeliveryService } from "../../../services/delivery.service";
import { CategoryService } from "../../../services/category.service";
import { LinkService } from "../../../services/link.service";
import { ContentLink } from "../../../models/content_link.model";
import { Link } from "../../../models/link.model";
import { ContentLinkService } from "../../../services/content_link.service";
import { DatePipe, formatDate, Location } from "@angular/common";
import { ContentFileService } from "../../../services/content_file.service";
import { ShiftDefinitionService } from "../../../services/shift_definition.service";
import { ShiftDefinition } from "../../../models/shift_definition.model";
import { AppSettings } from "../../../app.settings";
import { CoverageService } from "../../../services/coverage.service";
import { HttpErrorResponse } from "@angular/common/http";
import { getDocument, PDFDocumentProxy } from "pdfjs-dist";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { UserService } from "../../../services/user.service";
import { UserName } from "../../../models/user.model";
import { AuthenticationService } from "../../../services/authentication.service";
import { DialogsService } from "../../../services/dialogs.service";
import { handleFileDrop } from "../../../helpers/file.helper";

pdfjsLib.GlobalWorkerOptions.workerSrc = import("pdfjs-dist/build/pdf.worker.entry.js");

export enum ContentTypes {
    contentLinks = 1,
    contentOnly = 2,
    linksOnly = 3
}

@Component({
    selector: "app-create-content",
    templateUrl: "./create-content.component.html",
    styleUrls: ["./create-content.component.scss"]
})
export class CreateContentComponent implements OnInit {

    content: Content;
    contentID: number;
    deliverys: Delivery[] = [];
    categorys: Category[] = [];
    shiftDefinitions: ShiftDefinition[] = [];
    audience = -1;
    link: Link = new Link();
    links: Link[] = [];
    contentLinks: ContentLink[] = [];
    contentLinksInitial: ContentLink[] = [];
    linkInsertAfter: number = null;
    compulsory = false;
    contentTypes = ContentTypes;
    contentType = null;
    validFrom: any;
    expiry: any;
    minDate: Date;
    viewType: string;
    linkIdx = 0;
    addLinks: Link[] = [];
    addContentLinks: ContentLink[] = [];
    deleteContentLinks: ContentLink[] = [];
    keyPressed = false;
    userNames: UserName[] = [];
    contentTypeChanged = false;

    file: File = null;
    hasUploadStarted = false;
    totalFileSize = 0;
    currentBytesUploaded: { [fileName: string]: number } = {};

    pdfSrc: string;
    numPages: number = null;

    constructor(private coverageService: CoverageService,
                private contentService: ContentService,
                private contentFileService: ContentFileService,
                private shiftDefinitionService: ShiftDefinitionService,
                private deliveryService: DeliveryService,
                private categoryService: CategoryService,
                private linkService: LinkService,
                private contentLinkService: ContentLinkService,
                private fileService: FileService,
                private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private datePipe: DatePipe,
                private authService: AuthenticationService,
                private dialogsService: DialogsService,
                private location: Location) {
    }

    ngOnInit() {
        this.minDate = new Date(Date.now());
        // Pre-fill field for valid from date
        this.validFrom = this.minDate;

        this.userService.getUserNames().subscribe(userNames => {
            this.userNames = userNames;
        });

        this.route.params.subscribe(params => {
            this.contentID = params["id"];
            this.viewType = params["type"];

            if (this.viewType !== "edit" && this.viewType !== "copy") {
                this.contentType = this.contentTypes.contentOnly;  // Set default if create
            }

            if (this.contentID) {
                this.contentService.getContent(this.contentID).subscribe(content => {
                    this.content = content;
                    if (this.content && !this.content.content_file_id) {
                        this.content.content_file = null;
                    }

                    this.validFrom = this.datePipe.transform(content.valid_from, "yyyy-MM-dd");
                    this.updateDate(this.validFrom);
                    this.expiry = this.datePipe.transform(content.expiry, "yyyy-MM-dd");
                    this.compulsory = content.is_compulsory;

                    if (!content.shift_definition_id) {
                        this.audience = -1;
                    } else {
                        this.audience = content.shift_definition_id;
                    }

                    this.contentLinkService.getContentLinks().subscribe(contentLinks => {
                        contentLinks = contentLinks.filter(cl => cl.content_id === content.id);
                        const contentLinkPositions = contentLinks.filter(cl => cl.insert_at !== null);
                        contentLinks = contentLinks.filter(cl => cl.insert_at === null);
                        if (contentLinkPositions.length > 1) {
                            contentLinkPositions.sort((a, b) => a.insert_at - b.insert_at);
                        }
                        contentLinkPositions.forEach((clp) => {
                            this.contentLinks.push(clp);
                        });
                        contentLinks.forEach((cl) => {
                            this.contentLinks.push(cl);
                        });

                        this.contentLinksInitial = this.contentLinks;
                        this.linkService.getLinks().subscribe(links => {
                            this.contentLinks.forEach((cl) => {
                                this.links.push(links.filter(l => l.id === cl.link_id)[0]);
                            });
                        });

                        if (this.content.content_file && this.contentLinks.length > 0) {
                            this.contentType = this.contentTypes.contentLinks;
                        } else if (this.contentLinks.length > 0) {
                            this.contentType = this.contentTypes.linksOnly;
                        } else {
                            this.contentType = this.contentTypes.contentOnly;
                        }
                    });
                });

                this.deliveryService.getDeliverys().subscribe(deliverys => {
                    this.deliverys = deliverys.sort((a, b) => a.ordinal - b.ordinal);
                });
                this.categoryService.getCategorys().subscribe(categorys => {
                    this.categorys = categorys;
                });
                this.shiftDefinitionService.getShiftDefinitions().subscribe(shiftDefinitions => {
                    this.shiftDefinitions = shiftDefinitions;
                });
            } else {
                this.content = new Content();
                this.loadStoredFileFromService();
                this.content.is_editable = true;
                this.content.archive_date = null;
                this.content.state = "Pending";
                this.updateDate(this.validFrom);
                this.content.is_compulsory = false;

                this.deliveryService.getDeliverys().subscribe(deliverys => {
                    this.deliverys = deliverys.sort((a, b) => a.ordinal - b.ordinal);
                    this.content.delivery_id = deliverys.filter(d => d.title.trim()
                        .toLowerCase().indexOf("Anytime".trim().toLowerCase()) !== -1)[0].id;
                });
                this.categoryService.getCategorys().subscribe(categorys => {
                    this.categorys = categorys;
                    this.content.category_id = categorys.filter(c => c.title.trim()
                        .toLowerCase().indexOf("General".trim().toLowerCase()) !== -1)[0].id;
                });
                this.shiftDefinitionService.getShiftDefinitions().subscribe(shiftDefinitions => {
                    this.shiftDefinitions = shiftDefinitions;
                    this.content.shift_definition_id = shiftDefinitions.filter(sd => sd.abbrev.trim()
                        .toLowerCase().indexOf("D".trim().toLowerCase()) !== -1)[0].id;
                });
            }
        });
    }

    loadStoredFileFromService(): void {
        if (this.fileService.getstoredFile()) {
            const handleFileDropResult = handleFileDrop(this.fileService.getstoredFile(), this.file, this.content);
            this.file = handleFileDropResult.file;
            this.content = handleFileDropResult.content;
            this.loadPdfSrc(this.file);
            this.fileService.clearStoredFile();
        }
    }

    loadComplete(src): void {

        const loadingTask: any = getDocument(src);

        loadingTask.promise.then((pdf: PDFDocumentProxy) => {
            this.numPages = pdf.numPages;
        });
    }

    handleFileInput(files: FileList) {
        this.file = files.item(0);
        if (!this.file.type.localeCompare("application/pdf")) {
            if (this.content.title.length < 1) {
                this.content.title = this.file.name.split(".pdf")[0];
            }
            this.loadPdfSrc(this.file);
        }
    }

    private loadPdfSrc(file: File) {
        this.pdfSrc = URL.createObjectURL(file);
        this.loadComplete(this.pdfSrc);
    }

    getValidFileSize(): boolean {
        return (this.fileService.validateFile(this.file));
    }

    // Checks file is type PDF
    getValidFileType(): boolean {
        if (!this.file) {
            return null;
        } else if (!this.file.type.localeCompare("application/pdf")) {
            return true;
        }
        return false;
    }

    getMaxFileSizeText(): number {
        return Math.round(env.httpSettings.maxUploadSize / 1000 / 1000);
    }

    getCurrentUploadBytesCompleted(): number {

        let totalBytes = 0;

        for (const fileName in this.currentBytesUploaded) {
            totalBytes += this.currentBytesUploaded[fileName];
        }

        return totalBytes;
    }

    getCurrentUploadPercentCompleted(): number {
        const calcValue = Math.round(this.getCurrentUploadBytesCompleted() / this.totalFileSize * 100);
        // This is a hack during to the usual float rounding issues
        return calcValue > 100 ? 100 : calcValue;
    }

    saveContent(): void {

        if (this.audience < 0) {
            this.content.shift_definition_id = null;
        } else {
            this.content.shift_definition_id = this.audience;
        }

        switch (this.viewType) {
            case "edit":
                switch (this.contentType) {
                    case this.contentTypes.contentOnly:
                        this.deleteContentLinks = this.contentLinks;
                        break;
                    case this.contentTypes.linksOnly:
                        this.file = null;
                        this.content.content_file = null;
                        this.content.content_file_id = null;
                        break;
                    default:
                        break;
                }
                this.contentService.updateContent(this.content).subscribe(content => {
                    if (this.file) {
                        this.uploadFile(content);
                    }
                    if (this.deleteContentLinks.length > 0 || this.addLinks.length > 0) {
                        this.editLinks(content);
                    }
                    if (!this.file && !(this.deleteContentLinks.length > 0 || this.addLinks.length > 0)) {
                        this.router.navigate(["/content/library"]);
                    }
                });
                break;
            case "copy":
                switch (this.contentType) {
                    case this.contentTypes.contentOnly:
                        this.links = [];
                        this.contentLinks = [];
                        break;
                    case this.contentTypes.linksOnly:
                        this.file = null;
                        this.content.content_file = null;
                        this.content.content_file_id = null;
                        break;
                    default:
                        break;
                }
                // Revert to pending
                this.content.state = "Pending";

                this.contentService.createContent(this.content).subscribe(content => {
                    if (this.contentType === this.contentTypes.linksOnly) {
                        this.createLinks(content, this.links, this.contentLinks);
                    } else if (this.file) {
                        this.uploadFileAndCreateLinks(content);
                    } else {
                        this.router.navigate(["/content/library"]);
                    }
                });
                break;
            default:
                this.contentService.createContent(this.content).subscribe(content => {
                    if (this.contentType === this.contentTypes.linksOnly) {
                        this.createLinks(content, this.links, this.contentLinks);
                    } else {
                        this.uploadFileAndCreateLinks(content);
                    }
                    this.content = content;

                    // check if admin here and if is admin auto approve
                    if (this.authService.isAdmin()) {
                        this.content.approver_id = this.authService.getCurrentUserID();
                        this.content.approver_date = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
                        this.changeState("Approved");
                    }

                });
                break;
        }
    }

    onDelete() {
        this.contentService.deleteContent(this.content.id).subscribe(res => {
            if (res) {
                Log.i("Content Deleted");
                this.router.navigate(["/content/library"]);
            }
        });
    }

    uploadFile(content: Content) {
        const fileUploadHandler = (progress: FileUploadStatus) => {
            if (progress) {
                if (!this.currentBytesUploaded.hasOwnProperty(progress.fileName)) {
                    this.currentBytesUploaded[progress.fileName] = 0;
                }

                this.currentBytesUploaded[progress.fileName] = progress.bytesUploaded;

                if (this.getCurrentUploadPercentCompleted() === 100 && progress.content_file_id) {
                    content.content_file_id = progress.content_file_id;

                    this.contentService.updateContent(content).subscribe(_ => {
                        setTimeout(() => {
                            this.router.navigate(["/content/library"], { queryParams: { created: true } }).then(() => {

                                this.currentBytesUploaded = {};
                                this.hasUploadStarted = false;
                                this.totalFileSize = 0;
                                this.file = null;
                            }, () => {
                                // TODO: Add proper error handling
                            });
                        }, 1500);
                    });
                }
            }
        };

        this.hasUploadStarted = true;
        this.totalFileSize = this.file.size;
        this.fileService.upload(AppSettings.API_ENDPOINT + "content_file", this.file, this.numPages).subscribe(
            fileUploadHandler,
            (e) => {
                Log.error(e);
                this.keyPressed = false;
            });
    }

    uploadFileAndCreateLinks(content: Content) {
        const fileUploadHandler = (progress: FileUploadStatus) => {
            if (progress) {
                if (!this.currentBytesUploaded.hasOwnProperty(progress.fileName)) {
                    this.currentBytesUploaded[progress.fileName] = 0;
                }

                this.currentBytesUploaded[progress.fileName] = progress.bytesUploaded;

                if (this.getCurrentUploadPercentCompleted() === 100 && progress.content_file_id) {
                    content.content_file_id = progress.content_file_id;

                    this.contentService.updateContent(content).subscribe(_ => {
                        setTimeout(() => {
                            if (this.links.length > 0) {
                                let linkCount = 0;
                                for (let i = 0; i < this.links.length; i++) {
                                    this.linkService.createLink(this.links[i]).subscribe(linkRes => {
                                        Log.i("Link created");
                                        this.contentLinkService.createContentLink(new ContentLink(0, content.id, linkRes.id,
                                            this.linkLocation(this.links[i].id))).subscribe(contentLinkRes => {
                                            Log.i("ContentLink created");
                                            linkCount++;
                                            if (contentLinkRes && linkCount > this.links.length - 1) {
                                                this.router.navigate(["/content/library"], { queryParams: { created: true } }).then(() => {

                                                    this.currentBytesUploaded = {};
                                                    this.hasUploadStarted = false;
                                                    this.totalFileSize = 0;
                                                    this.file = null;
                                                }, () => {
                                                    // TODO: Add proper error handling
                                                });
                                            }
                                        });
                                    });
                                }
                            } else {
                                this.router.navigate(["/content/library"], { queryParams: { created: true } }).then(() => {

                                    this.currentBytesUploaded = {};
                                    this.hasUploadStarted = false;
                                    this.totalFileSize = 0;
                                    this.file = null;
                                }, () => {
                                    // TODO: Add proper error handling
                                });
                            }
                        }, 1500);
                    });
                }
            }
        };

        this.hasUploadStarted = true;
        this.totalFileSize = this.file.size;
        this.fileService.upload(AppSettings.API_ENDPOINT + "content_file", this.file, this.numPages).subscribe(
            fileUploadHandler,
            (e) => {
                Log.error(e);
                this.keyPressed = false;
            });
    }

    createLinks(content: Content, links: Link[], contentLinks: ContentLink[]) {
        let linkCount = 0;
        for (let i = 0; i < links.length; i++) {
            this.linkService.createLink(links[i]).subscribe(linkRes => {
                Log.i("Link created");
                this.contentLinkService.createContentLink(new ContentLink(0, content.id, linkRes.id,
                    contentLinks.filter(cl => cl.link_id === links.filter(l => l.id === links[i].id)[0].id)[0].insert_at
                )).subscribe(contentLinkRes => {
                    Log.i("ContentLink created");
                    linkCount++;
                    if (contentLinkRes && linkCount > links.length - 1) {
                        this.router.navigate(["/content/library"]);
                    }
                }, () => {
                    this.keyPressed = false;
                });
            });
        }
    }

    editLinks(content: Content) {
        if (this.deleteContentLinks.length > 0) {
            let deleteLinkCount = 0;
            this.deleteContentLinks.forEach((dcl) => {
                this.contentLinkService.deleteContentLink(dcl.id).subscribe(res => {
                    Log.i("ContentLink deleted");
                    if (res) {
                        deleteLinkCount++;
                    }
                    if (deleteLinkCount > this.deleteContentLinks.length - 1) {
                        if (this.addContentLinks.length > 0) {
                            this.createLinks(content, this.addLinks, this.addContentLinks);
                        } else {
                            this.router.navigate(["/content/library"]);
                        }
                    }
                });
            });
        } else if (this.addContentLinks.length > 0) {
            this.createLinks(content, this.addLinks, this.addContentLinks);
        } else {
            this.router.navigate(["/content/library"]);
        }
    }

    checkCompulsory(): boolean {
        if (this.compulsory) {
            this.content.delivery_id = this.deliverys.filter(d => d.title.trim()
                .toLowerCase().indexOf("Next Shift".trim().toLowerCase()) !== -1)[0].id;
            this.content.is_compulsory = true;
        } else {
            this.content.is_compulsory = false;
        }
        return this.content.is_compulsory;
    }

    addLink() {
        if (this.linkInsertAfter == null) {
            this.contentLinks.push(new ContentLink(0, 0, this.linkIdx, null));
            this.addContentLinks.push(new ContentLink(0, 0, this.linkIdx, null));
        } else {
            this.contentLinks.push(new ContentLink(0, 0, this.linkIdx, this.linkInsertAfter + 1));
            this.addContentLinks.push(new ContentLink(0, 0, this.linkIdx, this.linkInsertAfter + 1));
        }

        const regex = new RegExp("www");
        if (regex.test(this.link.link.slice(0, 3))) {
            this.link.link = "https://" + this.link.link;
        }

        this.links.push(new Link(this.linkIdx, null, this.link.name, this.link.link));
        this.addLinks.push(new Link(this.linkIdx, null, this.link.name, this.link.link));

        this.linkIdx++;

        // Reset
        this.linkInsertAfter = null;
        this.link.name = "";
        this.link.link = "";
    }

    removeLink(ID: number) {
        this.deleteContentLinks.push(this.contentLinks.filter(cl => cl.link_id === ID)[0]);

        this.contentLinks = this.contentLinks.filter(cl => cl.link_id !== ID);
        this.links = this.links.filter(l => l.id !== ID);
    }

    linkLocation(ID: number): any {
        return this.contentLinks.filter(cl => cl.link_id === this.links.filter(l => l.id === ID)[0].id)[0].insert_at;
    }

    linkInvalid(): boolean {
        // const regex: RegExp = /\s/gm; // Whitespace
        return this.link.name.length < 1 || this.link.link.length < 1;
    }

    linkInvalidHint(): boolean {
        const regex = new RegExp(".com|.au");
        return this.linkInvalid() && regex.test(this.link.link);
    }

    navigate(partialPath: string, type: string = "") {
        this.router.navigate(["/content/", partialPath, this.content.id, type]);
    }

    restoreContent() {
        const prevDate = this.content.archive_date;
        const content = this.content;
        content.archive_date = null;
        this.contentService.updateContent(content).subscribe(result => {
            if (result) {
                this.content = result;
                // this.content.content_file = null;
            }
        }, err => {
            if (err instanceof HttpErrorResponse) {
                this.content.archive_date = prevDate;
                // this.content.content_file = null;
            }
        });
    }

    archiveContent() {
        const prevDate = this.content.archive_date;
        const content = this.content;
        content.archive_date = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
        // content.archive_date = archiveDate.getFullYear() + '/' + (archiveDate.getMonth() + 1) + '/' + archiveDate.getDate();
        this.contentService.updateContent(content).subscribe(result => {
            if (result) {
                this.content = result;
                // this.content.content_file = null;
            }
        }, err => {
            if (err instanceof HttpErrorResponse) {
                this.content.archive_date = prevDate;
                this.dialogsService
                    .error("Archiving Content", err.error)
                    .subscribe(res => {
                        if (res) {
                            location.reload();
                        }
                    });
                // this.content.content_file = null;
            }
        });
    }

    navigateBack() {
        this.location.back();
    }

    updateDate(criteria: any): string {
        if (!criteria) {
            return null;
        }
        return formatDate(criteria, "yyyy-MM-dd", "en");
    }

    checkCreateInvalid() {
        switch (this.contentType) {
            case ContentTypes.contentLinks:
                return this.keyPressed || (!!this.file && (!this.getValidFileSize() || !this.getValidFileType())) || (this.content ?
                    this.content.title.length < 1 : false) || (!this.file && !this.content.content_file) || (this.links.length < 1);
            case ContentTypes.contentOnly:
                return this.keyPressed || (!!this.file && (!this.getValidFileSize() || !this.getValidFileType())) || (this.content ?
                    this.content.title.length < 1 : false) || (!this.file && !this.content.content_file);
            case ContentTypes.linksOnly:
                return this.keyPressed || (this.content.title.length < 1) || (this.links.length < 1);
            default:
                return true;
        }
    }

    changeState(state: string) {
        const prevState = this.content.state;
        const content = this.content;
        content.state = state;
        this.contentService.updateContent(content).subscribe(res => {
            if (res) {
                this.content = res;
                // this.content.content_file = null;
            }
        }, err => {
            if (err instanceof HttpErrorResponse) {
                this.content.state = prevState;
                // this.content.content_file = null;
            }
        });
    }

    getUser(ID: number): string {
        const users = this.userNames.filter(u => u.id === ID);
        if (users.length < 1) {
            return "";
        }
        return users[0].name;
    }

    resetExpiryDate() {
        this.content.expiry = null;
        this.expiry = null;
    }

    canRestoreContent() {
        const minDate = new Date();
        minDate.setHours(0, 0, 0, 0); // setting date to midnight of current day, minimum allowed expiry date
        return !(this.content.expiry === null || new Date(this.content.expiry) > minDate);
    }

    /**
     * Function called when the EventEmitter currentFileChange is emitted from in file-upload-target.component.ts
     * Will load the pdfSrc to get the number of pages in PDF
     */
    dragAndDropFileChange() {
        this.loadPdfSrc(this.file);
    }

}
