import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {PrestartType} from "../models/prestart_type.model";
import {HttpClient} from '@angular/common/http';


@Injectable()
export class PrestartTypeService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('PrestartTypeService Ready...');
  }

  getPrestartTypes(): Observable<PrestartType[]> {
    return this.http.get<PrestartType[]>(AppSettings.API_ENDPOINT + 'prestart_type', this.options);
  }

  getPrestartTypesUnique(): Observable<PrestartType[]> {
    return this.http.get<PrestartType[]>(AppSettings.API_ENDPOINT + 'prestart_type/unique', this.options);
  }

  getPrestartType(prestartTypeID: number): Observable<PrestartType> {
    return this.http.get<PrestartType>(AppSettings.API_ENDPOINT + 'prestart_type/' + prestartTypeID, this.options);
  }

  createPrestartType(prestartType: PrestartType): Observable<PrestartType> {
    return this.http.post<PrestartType>(AppSettings.API_ENDPOINT + 'prestart_type', JSON.stringify(prestartType), this.options);
  }

  updatePrestartType(prestartType: PrestartType): Observable<PrestartType> {
    return this.http.put<PrestartType>(AppSettings.API_ENDPOINT + 'prestart_type/' + prestartType.id, JSON.stringify(prestartType), this.options);
  }

  deletePrestartType(prestartTypeID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'prestart_type/' + prestartTypeID, this.options);
  }

  checkPrestartTypeNameActive(prestartType: PrestartType): Observable<any> {
    return this.http.post<PrestartType>(AppSettings.API_ENDPOINT + 'prestart_type/check_name_active', JSON.stringify(prestartType), this.options);
  }

  checkPrestartTypeOverlap(prestartType: PrestartType): Observable<any> {
    return this.http.post<PrestartType>(AppSettings.API_ENDPOINT + 'prestart_type/check_overlap', JSON.stringify(prestartType), this.options);
  }
}
