export class ContentHidden {
  constructor(public id: any = null,
              public content_id: any = null,
              public page_idx: number = 0
              ) {}

  public static assign(ch: ContentHidden) {
    const n = Object.assign(new ContentHidden(), ch);

    return n;
  }
}
