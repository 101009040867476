import { Component, OnInit, ViewChild } from "@angular/core";
import {Router} from '@angular/router';
import {SessionService} from '../../../services/session.service';
import {ContentService} from '../../../services/content.service';
import {Content} from '../../../models/content.model';
import {AppSettings} from '../../../app.settings';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {DeliveryService} from '../../../services/delivery.service';
import {CategoryService} from '../../../services/category.service';
import {Delivery} from '../../../models/delivery.model';
import {Category} from '../../../models/category.model';
import {State, States} from '../../../models/state.model';
import {ContentFileService} from '../../../services/content_file.service';
import {ContentFile} from '../../../models/content_file.model';
import {ContentCoverage} from '../../../models/coverage.model';
import {CoverageService} from '../../../services/coverage.service';
import {AuthenticationService} from "../../../services/authentication.service";
import {User} from "../../../models/user.model";

@Component({
    selector: 'app-content-library',
    templateUrl: './content-library.component.html',
    styleUrls: ['./content-library.component.scss']
})

export class ContentLibraryComponent implements OnInit {

    contents: Content[] = [];
    deliverys: Delivery[] = [];
    categorys: Category[] = [];
    compulsorys: Compulsory[] = [];
    states: State[] = States;
    contentsCoverage: ContentCoverage[] = [];
    deliveryID: number;
    categoryID: number;
    compulsory: number;
    user: User;

    // Table parameters
    displayedColumns: string[] = ['title', 'coverage', 'delivery', 'category', 'valid_from', 'archive_date', 'actions'];
    dataSource: MatTableDataSource<Content>;
    @ViewChild(MatSort) sort: MatSort;
    filterValues: FilterValues = {
        title: '',
        stateName: '',
        deliveryID: null,
        categoryID: null,
        compulsory: null,
        showArchived: false
    };
    contentSearchTerm = '';

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    pageSizeOptions: number[] = [5, 15, 25, 50];

    constructor(private router: Router,
                private sessionService: SessionService,
                private contentService: ContentService,
                private contentFileService: ContentFileService,
                private deliveryService: DeliveryService,
                private categoryService: CategoryService,
                private coverageService: CoverageService,
                private authService: AuthenticationService,
                ) {
    }

    ngOnInit() {
        // Defaults
        this.deliveryID = -1;  // All
        this.categoryID = -1;  // All
        this.compulsory = -1;  // All
        this.compulsorys = [
            {id: 1, title: 'Compulsory'},
            {id: 0, title: 'Non-Compulsory'}
        ];

        this.deliveryService.getDeliverys().subscribe((deliverys: Delivery[]) => {
            this.deliverys = deliverys.sort((a, b) => a.ordinal - b.ordinal);

            this.categoryService.getCategorys().subscribe((categorys: Category[]) => {
                this.categorys = categorys;

                this.contentService.getContents().subscribe((contents: Content[]) => {

                    this.contents = contents;

                    this.dataSource = new MatTableDataSource(contents);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sortingDataAccessor = (item, property) => {
                        if (property === 'ctime') {
                            if (!item) {
                                return item[property];
                            }
                            return item.ctime;
                        } else {
                            return item[property];
                        }
                    };

                    this.sort.sort(({id: 'ctime', start: 'desc'}) as MatSortable);
                    this.dataSource.sort = this.sort;
                    this.dataSource.filterPredicate = (data: {
                                                           title: string, state: string, delivery_id: number,
                                                           category_id: number, is_compulsory: boolean, archive_date: any
                                                       },
                                                       filter: string) => {
                        const filters = JSON.parse(filter);

                        // Filters table based on type-ahead, archived switch, and drop-down states
                        return data.title.trim().toLowerCase().indexOf(filters.title.trim().toLowerCase()) !== -1
                            && (data.state.trim().toLowerCase().indexOf(filters.stateName.trim().toLowerCase()) !== -1)
                            && (!filters.deliveryID || data.delivery_id === filters.deliveryID)
                            && (!filters.categoryID || data.category_id === filters.categoryID)
                            && (filters.compulsory == null || !!data.is_compulsory === !!filters.compulsory)
                            && (!data.archive_date || filters.showArchived === true);
                    };
                    this.updateFilters();

                    // Get attendance numbers
                    setTimeout(() => this.coverageService.getContentsCoverage().subscribe(contentsCoverage => {
                        this.contentsCoverage = contentsCoverage;
                    }), 250);
                });
                // });
            });
        });

        this.user = this.authService.getCurrentUser();
        if (this.user === null) {
            this.authService.refreshWhoAmI().then(user => {
                this.user = user;
            })
        }
    }

    updateFilter(criteria: number) {
        if (criteria < 0) {
            return null;  // All
        }
        return criteria;
    }

    // Filtering
    updateFilters(): void {
        this.filterValues.title = this.contentSearchTerm;
        this.dataSource.filter = JSON.stringify(this.filterValues);
    }

    navigate(partial_path: string, ID: number, type: string = '') {
        const contents = this.contents.filter(c => c.id === ID);
        this.router.navigate(['/content/', partial_path, contents[0].id, type]);
    }

    // Used for content preview
    contentFirstPage(contentFile: ContentFile): string {
        if (!contentFile || contentFile.page_count < 1) {
            return null;
        }
        return (AppSettings.API_ENDPOINT + 'content_file/' + contentFile.id + '/page/' +
            1 + '/' + 'webp' + '/' + 800 + (AppSettings.INTEGRATED_AUTHENTICATION ? "" : "?token=" + this.authService.getToken()));
    }

    getFilename(ID: number): string {
        // return this.contentFiles.filter(cf => cf.content_id === ID)[0].filename;
        return this.contents.filter(c => c.id === ID)[0].content_file.filename;
    }

    contentDelivery(deliveryID: number): Delivery {
        return this.deliverys.filter(d => d.id === deliveryID)[0];
    }

    getCategory(ID: number): string {
        return (this.categorys.filter(cg => cg.id === this.contents.filter(c => c.id === ID)[0].category_id)[0].title);
    }

    getCompulsory(ID: number): boolean {
        return this.contents.filter(c => c.id === ID)[0].is_compulsory;
    }

    getValidFrom(ID: number): any {
        return this.contents.filter(c => c.id === ID)[0].valid_from;
    }

    getArchiveDate(ID: number): any {
        return this.contents.filter(c => c.id === ID)[0].archive_date;
    }

    getExpiryDate(ID: number): any {
        return this.contents.filter(c => c.id === ID)[0].expiry;
    }

    getCoverage(ID: number): string {
        const contentCoverage = this.contentsCoverage.filter(cc => cc.content_id === this.contents.filter(c => c.id === ID)[0].id);

        // Attendance not applicable to un-approved content or content flagged as anytime
        if (!contentCoverage || contentCoverage.length < 1) {
            return null;
        }
        if (contentCoverage[0].attendance_percent_by_required === 0 || contentCoverage[0].attendance_percent_by_required === null) {
            return 'Not Shown';
        }

        return contentCoverage[0].attendance_percent_by_required?.toString().concat('%');
    }

    checkCoverageApplicable(ID: number) {
        return this.contents.length > 0 && this.contents.filter(c => c.id === ID)[0].state === 'Approved'
    }

    checkCoverage(ID: number): string {
        return this.getCoverage(ID) === 'Not Shown' ? 'text' :
            (this.getCoverage(ID) === '100.0%' ? 'text-success' : 'text-danger')
    }

    getInvalidReason(ID: number): string {
        const content = this.contents.filter(c => c.id === ID);
        if (content.length < 1) {
            return 'N/A'
        }
        if (content[0].state !== 'Approved') {
            return 'Not Approved'
        } else {
            return 'N/A'
        }
    }
}

export class FilterValues {
    title: string;
    stateName: string;
    deliveryID: number;
    categoryID: number;
    compulsory: number;
    showArchived: false;
}

export class Compulsory {
    id: number;
    title: string;
}
