import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import { SessionMetric, SessionMetricContentDuration } from '../models/session_metric.model';
import {HttpClient} from '@angular/common/http';
import {SessionRun} from "../models/session.model";

@Injectable()
export class SessionMetricService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('SessionMetricService Ready...');
  }

  getSessionMetrics(): Observable<SessionMetric[]> {
    return this.http.get<SessionMetric[]>(AppSettings.API_ENDPOINT + 'session_metric', this.options);
  }

  getSessionMetric(sessionMetricID: number): Observable<SessionMetric> {
    return this.http.get<SessionMetric>(AppSettings.API_ENDPOINT + 'session_metric/' + sessionMetricID, this.options);
  }

  createSessionMetric(sessionMetric: SessionMetric): Observable<SessionMetric> {
    return this.http.post<SessionMetric>(AppSettings.API_ENDPOINT + 'session_metric', JSON.stringify(sessionMetric), this.options);
  }

  updateSessionMetric(sessionMetric: SessionMetric): Observable<SessionMetric> {
    return this.http.put<SessionMetric>(AppSettings.API_ENDPOINT + 'session_metric/' + sessionMetric.id, JSON.stringify(sessionMetric), this.options);
  }

  deleteSessionMetric(sessionMetricID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'session_metric/' + sessionMetricID, this.options);
  }

  getSessionMetricContentsDuration(sessionRun: SessionRun): Observable<SessionMetricContentDuration[]> {
    return this.http.post<SessionMetricContentDuration[]>(AppSettings.API_ENDPOINT + 'session_metric/contents_duration', JSON.stringify(sessionRun));
  }

  getSessionMetricLastRunCount(sessionID: number, group: number): Observable<any> {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'session_metric/last_run_count', JSON.stringify(new SessionRun(sessionID, group, null)));
  }

  getSessionMetricOverviewTimes(sessionRun: SessionRun): Observable<any> {
    return this.http.post<any>(AppSettings.API_ENDPOINT + 'session_metric/overview_times', JSON.stringify(sessionRun));
  }
}
