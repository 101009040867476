<div class="body-container">
    <div class="card text-center p-5">
            <h2 class="md-48">No Network Connection</h2>
        <i class="material-icons md-48">sync_problem</i>

        <p>We could not communicate with the server.</p>
        <p>Please check your internet connection and try again</p>
        <p><a [routerLink]="['login']">Click here to retry</a></p>
    </div>
</div>
