import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {RecordSessionAttendeesComponent} from "./components/pages/session-record-attendees/record-session-attendees.component";
import {DialogsService} from "./services/dialogs.service";

@Injectable({
  providedIn: 'root',
})
export class NavigateAwayGuard  {

  constructor(private dialogsService: DialogsService) {}

  canDeactivate(
    component: RecordSessionAttendeesComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    // Allow synchronous navigation (`true`) if manually press record button
    if (component.manualNavigation) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogsService.navigateAway('Navigate away with current recorded workers? Cancel and press complete recording to save notes.');
  }
}
