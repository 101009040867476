import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
})
export class DialogError {

  constructor(public dialogRef: MatDialogRef<DialogError>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }
}
