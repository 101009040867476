import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';

/*
  Core services for each page, just handles authentication checks for now but might be necessary later
 */

@Injectable()
export class CoreService {

  protected headers = {
    'Content-Type': 'application/json'
  };

  protected options = {headers: this.headers};

  constructor(protected auth: AuthenticationService) {
  }

  public isStandardUser() {
    // if (!this.authService.isAuthenticated()) {
    //   this.authService.clearUserDataAndRedirect();
    // }
  }

  public isAdminUser() {
    // if (!this.authService.isAuthenticated()) {
    //   this.authService.clearUserDataAndRedirect();
    // }
  }

}
