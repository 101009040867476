import {ContentFile} from "./content_file.model";
import {PrestartType} from "./prestart_type.model";

export class Session {
  constructor(public id: any = null,
              public shift_definition_id: any = null,
              public shift_date: any = null,
              public prestart_type_id: any = null,
              public prestart_type_name: string = '',
              public user_id: any = null,
              public title: string = '',
              public titlepage_id: any = null,
              public comment: string = '',
              public is_editable: boolean = true,
              public ctime: any = null,
              public mtime: any = null,
  ) {}

  public static assign(s: Session) {
    const n = Object.assign(new Session(), s);

    return n;
  }
}

export class SessionRun {
  constructor(public session_id: any,
              public group: any,
              public run_count: number
  ) {}
}

export class SessionShiftRequest {
  constructor(public shift_date: string =  '',
              public shift_definition_id: number = 0,
              public prestart_type_id: number = 0
  ) {}
}

