import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {HttpClient} from '@angular/common/http';
import { SessionAttendance } from '../models/attendance.model';

@Injectable()
export class AttendanceService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('AttendanceService Ready...');
  }

  getSessionsAttendance(): Observable<SessionAttendance[]> {
    return this.http.get<SessionAttendance[]>(AppSettings.API_ENDPOINT + 'session/' + 'attendance', this.options);
  }

  getSessionAttendance(sessionID: any): Observable<SessionAttendance> {
    return this.http.get<SessionAttendance>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/attendance', this.options);
  }
}
