import {Component, OnInit} from '@angular/core';
import {Titlepage} from '../../../models/titlepage.model';
import {TitlepageService} from '../../../services/titlepage.service';
import {AppSettings} from '../../../app.settings';
import {Log} from '../../../helpers/log.helper';
import {AuthenticationService} from "../../../services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {

  selectedID: any = null;
  previousID: any = null;
  titlepages: Titlepage[] = [];
  titlepagePrevActiveID: any = null;

  constructor(private titlepageService: TitlepageService,
              private authService: AuthenticationService,
              private router: Router) {
  }

  ngOnInit() {
    this.titlepageService.getTitlepages().subscribe(titlepages => {
      this.titlepages = titlepages;
      const titlepageActive = this.titlepages.filter(t => t.state === 'Active');
      if (titlepageActive.length < 1) {
        this.titlepagePrevActiveID = 1;
      } else {
        this.titlepagePrevActiveID = titlepageActive[0].id;
      }
      this.selectedID = this.titlepagePrevActiveID;
      this.previousID = this.selectedID;
    });
  }

  getPage(ID: number) {
      return (AppSettings.API_ENDPOINT + 'content_file/' + this.titlepages.filter(t => t.id === ID)[0].content_file.id + '/page/' + 0 +
          '/webp/' + 800 + (AppSettings.INTEGRATED_AUTHENTICATION ? "" : "?token=" + this.authService.getToken()));
  }

  saveContentManagement() {
    this.titlepages.forEach((item) => {
      this.titlepageService.updateTitlepage(item).subscribe(res => {
        if (res) {
          Log.i('Titlepage Updated');
          this.router.navigate(['dashboard']);
        }
      });
    });
  }

  updateState() {
    if (this.selectedID !== this.previousID && this.titlepages.length > 1) {
      this.titlepages.filter(t => t.id === this.selectedID)[0].state = 'Active';
      this.titlepages.filter(t => t.id === this.previousID)[0].state = 'Inactive';
      this.previousID = this.selectedID;
    } else if (this.titlepages.length > 0 && (!this.titlepages.filter(t => t.id === this.selectedID)[0].state ||
      this.titlepages.filter(t => t.id === this.selectedID)[0].state === 'Inactive')) {
      this.titlepages.filter(t => t.id === this.selectedID)[0].state = 'Active';
    } else if (this.titlepages.length > 0) {
      this.titlepages.filter(t => t.id === this.selectedID)[0].state = 'Inactive';
    }
  }
}
