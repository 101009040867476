import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {Delivery} from '../models/delivery.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DeliveryService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('DeliveryService Ready...');
  }

  getDeliverys(): Observable<Delivery[]> {
    return this.http.get<Delivery[]>(AppSettings.API_ENDPOINT + 'delivery', this.options);
  }

  getDelivery(deliveryID: number): Observable<Delivery> {
    return this.http.get<Delivery>(AppSettings.API_ENDPOINT + 'delivery/' + deliveryID, this.options);
  }

  createDelivery(delivery: Delivery): Observable<Delivery> {
    return this.http.post<Delivery>(AppSettings.API_ENDPOINT + 'delivery', JSON.stringify(delivery), this.options);
  }

  updateDelivery(delivery: Delivery): Observable<Delivery> {
    return this.http.put<Delivery>(AppSettings.API_ENDPOINT + 'delivery/' + delivery.id, JSON.stringify(delivery), this.options);
  }

  deleteDelivery(deliveryID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'delivery/' + deliveryID, this.options);
  }
}
