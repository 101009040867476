<div class="container">
  <div class="row d-flex align-items-center justify-content-between">
    <div>
      <h4>System Settings</h4>
    </div>
    <div class="button-row" *ngIf="!showCreatePrestart; else showPrestart">
      <button mat-stroked-button (click)="createPrestartType()">Create PreStart Type</button>
    </div>
    <ng-template #showPrestart>
      <div class="button-row">
        <button mat-stroked-button (click)="savePrestartType()">Save</button>
        <button mat-stroked-button (click)="this.prestartTypeCreate = null; showCreatePrestart = !showCreatePrestart">
          Cancel
        </button>
      </div>
    </ng-template>
  </div>
  <div class="row">
    <div class="row mt-2 d-flex align-items-center">
      <div class="pb-0 my-3">
        <h5>PreStart Types</h5>
      </div>
    </div>
    <ng-container>
      <div class="col-12 p-0">
        <table class="ctm-table" *ngIf="showCreatePrestart && prestartTypeCreate">
          <tr>
            <th>PreStart Type</th>
            <th>Shift Time</th>
            <th>Tap-in Window</th>
            <th>PreStart Time</th>
            <th>Tap-in Cut-off</th>
          </tr>
          <tr class="bg-light">
            <td>
              {{prestartTypeCreate.name}}
            </td>
            <td>
              <div *ngFor="let shiftDefinition of shiftDefinitions">
                {{shiftDefinition.start_time}}
              </div>
            </td>
            <td>
              <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                <div>
                  {{getTime(shiftDefinition.start_offset, shiftDefinition.start_time, prestartTypeCreate.start_offset - prestartTypeCreate.window_before) | date: 'HH:mm:ss'}}
                  <ng-container *ngIf="prestartTypeNoStartOffset">(Nominal)</ng-container>
                </div>
              </ng-container>
            </td>
            <td>
              <span *ngIf="shiftDefinitions && prestartTypeCreate.start_offset === null">Disabled</span>
              <ng-container *ngIf="shiftDefinitions && prestartTypeCreate.start_offset !== null">
                <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                  <div>
                    {{getTime(shiftDefinition.start_offset, shiftDefinition.start_time, prestartTypeCreate.start_offset) | date: 'HH:mm:ss'}}
                  </div>
                </ng-container>
              </ng-container>
            </td>
            <td>
              <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                <div>
                  {{getTime(shiftDefinition.start_offset, shiftDefinition.start_time, prestartTypeCreate.start_offset + prestartTypeCreate.window_after) | date: 'HH:mm:ss'}}
                  <ng-container *ngIf="prestartTypeNoStartOffset">(Nominal)</ng-container>
                </div>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field *ngIf="prestartTypeCreate" appearance="outline" class="w-75">
                <input matInput autocomplete="off" placeholder="Please enter a name" name="prestartTypeName"
                       [(ngModel)]="prestartTypeCreate.name">
              </mat-form-field>
            </td>
            <td></td>
            <td>
              <mat-form-field *ngIf="prestartTypeCreate" appearance="outline">
                <input matInput autocomplete="off" placeholder="Minutes" name="prestartTypeWindowBefore"
                       (change)="prestartTypeCreate.window_before = clamp(prestartTypeCreate.window_before, 60.0)"
                       [(ngModel)]="prestartTypeCreate.window_before" type="number" min="0" max="60">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field *ngIf="prestartTypeCreate" appearance="outline">
                <input matInput autocomplete="off"
                       [(ngModel)]="prestartTypeCreate.start_offset" type="number">
              </mat-form-field>
              <mat-checkbox class="ml-2 mr-1" (change)="updateStartOffset()"
                            [(ngModel)]="prestartTypeNoStartOffset">
              </mat-checkbox>
              <span>Disable</span>
            </td>
            <td>
              <mat-form-field *ngIf="prestartTypeCreate" appearance="outline">
                <input matInput autocomplete="off" placeholder="Minutes" name="prestartTypeWindowAfter"
                       (change)="prestartTypeCreate.window_after = clamp(prestartTypeCreate.window_after, 60.0)"
                       [(ngModel)]="prestartTypeCreate.window_after" type="number" min="0" max="60">
              </mat-form-field>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-12 p-0">
        <table class="ctm-table">
          <tr>
            <th>PreStart Type</th>
            <th>Shift</th>
            <th>Shift Time</th>
            <th>Tap-in Window</th>
            <th>PreStart Time</th>
            <th>Tap-in Cut-off</th>
            <th>Status</th>
            <th class="text-right">Action</th>
          </tr>
          <tr *ngFor="let prestartType of prestartTypes; let i = index">
            <td class="col-2 p-0">
              <div class="d-flex align-items-center">
                <div class="mr-2">
              <span (click)="prestartTypes = moveItemInList(prestartTypes,i,true)">
                <a>
                  <mat-icon>arrow_drop_up</mat-icon>
                </a>
              </span>
                  <br>
                  <span (click)="prestartTypes = moveItemInList(prestartTypes,i,false)">
                <a>
                  <mat-icon>arrow_drop_down</mat-icon>
                </a>
              </span>
                </div>
                <span>{{prestartType.name}}</span>
              </div>
            </td>
            <td>
              <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                <div class="m-1">
                  {{shiftDefinition.name}}
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                <div class="m-1">
                  {{shiftDefinition.start_time}}
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                <div class="m-1">
                  {{getTime(shiftDefinition.start_offset, shiftDefinition.start_time, prestartType.start_offset - prestartType.window_before) | date: 'HH:mm:ss'}}
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="shiftDefinitions && prestartType.start_offset !== null">
                <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                  <div class="m-1">
                    {{getTime(shiftDefinition.start_offset, shiftDefinition.start_time, prestartType.start_offset) | date: 'HH:mm:ss'}}
                  </div>
                </ng-container>
              </ng-container>
            </td>
            <td>
              <ng-container *ngFor="let shiftDefinition of shiftDefinitions">
                <div class="m-1">
                  {{getTime(shiftDefinition.start_offset, shiftDefinition.start_time, prestartType.start_offset + prestartType.window_after) | date: 'HH:mm:ss'}}
                </div>
              </ng-container>
            </td>
            <td>
              {{prestartType.is_active && 'Active' || 'Deactivated'}}
            </td>
            <td class="text-right">
              <div class="btn-group btn-dropdown">
                <ng-container *ngIf="prestartType.is_editable; else notEditable">
                  <button class="action" mat-stroked-button *ngIf="prestartType.is_editable"
                          (click)="editPrestartType(prestartType.id)">
                    Edit
                  </button>
                  <button class="dropdown" mat-stroked-button [matMenuTriggerFor]="editMenu">
                    <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <mat-menu #editMenu="matMenu">
                    <button mat-menu-item *ngIf="prestartType.is_editable"
                            (click)="deletePrestartType(prestartType.id)">
                      Delete
                    </button>
                    <button mat-menu-item *ngIf="!prestartType.is_active"
                            (click)="activatePrestartType(prestartType.id)">
                      Activate
                    </button>
                    <button mat-menu-item *ngIf="prestartType.is_active"
                            (click)="deactivatePrestartType(prestartType.id)">
                      Deactivate
                    </button>
                  </mat-menu>
                </ng-container>
                <ng-template #notEditable>
                  <button class="no-dropdown" mat-stroked-button *ngIf="!prestartType.is_active"
                          (click)="activatePrestartType(prestartType.id)">
                    Activate
                  </button>
                  <button  class="no-dropdown" mat-stroked-button *ngIf="prestartType.is_active"
                          (click)="deactivatePrestartType(prestartType.id)">
                    Deactivate
                  </button>
                </ng-template>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </ng-container>
  </div>
</div>
