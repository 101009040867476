import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppSettings} from '../app.settings';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (AppSettings.INTEGRATED_AUTHENTICATION) {
      request = request.clone({
        withCredentials: true
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `${AppSettings.AUTH_HEADER_SCHEME} ${localStorage.getItem(AppSettings.AUTH_LOCAL_STORAGE_TOKEN_KEY)}`
        }
      });
    }

    return next.handle(request);
  }

}
