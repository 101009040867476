import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../services/session.service';
import {Worker} from '../../../models/worker.model'
import {WorkerService} from '../../../services/worker.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Log} from '../../../helpers/log.helper';
import {SessionWorkerService} from '../../../services/session_worker.service';
import {SessionWorker} from '../../../models/session_worker.model';
import {SessionContentService} from "../../../services/session_content.service";
import {Crew} from '../../../models/crew.model';
import { MatInput } from '@angular/material/input';
import {Session} from '../../../models/session.model';
import {CrewService} from '../../../services/crew.service';
import {AppSettings} from "../../../app.settings";
import {DatePipe} from "@angular/common";
import {Shift} from "../../../models/shift.model";
import {ShiftDefinitionService} from "../../../services/shift_definition.service";
import {DialogsService} from "../../../services/dialogs.service";
import {IntegrationService} from "../../../services/integration.service";


@Component({
  selector: 'app-record-attendees',
  templateUrl: './record-session-attendees.component.html',
  styleUrls: ['./record-session-attendees.component.scss']
})

export class RecordSessionAttendeesComponent implements OnInit {

  attendees: Worker[] = [];
  sessionWorkers: SessionWorker[] = [];
  session: Session;
  absentees: Worker[] = [];
  workers: Worker[] = [];
  sessionID: number;
  groupID: number = null;
  next_groupID: number = null;
  workerSearchTerm = '';
  filter = '';
  crews: Crew[] = [];
  mainCrews: Crew[] = [];
  supportCrews: Crew[] = [];
  selectedCrewID = 0; // All crews selected
  @ViewChild('numberMatInput', { read: MatInput })
  crewMatInput: MatInput;
  manualNavigation = false;
  intergrationInProgess = false;
  isIntergratedSignin = AppSettings.IS_INTEGRATED_SIGNIN;
  addAllClicked = false;
  removeAllClicked = false;
  workersLoggedIn = false;
  loadedWorkers = false;

  constructor(private sessionService: SessionService,
              private workerService: WorkerService,
              private sessionWorkerService: SessionWorkerService,
              private crewService: CrewService,
              private shiftDefinitionService: ShiftDefinitionService,
              private integrationService: IntegrationService,
              private route: ActivatedRoute,
              private router: Router,
              private datePipe: DatePipe,
              private dialogsService: DialogsService,
              private sessionContentService: SessionContentService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.sessionID = params['id'];
      if (params.hasOwnProperty('group')) {
        this.groupID = params['group']
      }
      this.sessionContentService.getSessionNextContent(this.sessionID, this.groupID).subscribe(group => {
        this.next_groupID = group;
      })
      this.sessionService.getSessionAttendancePermission(this.sessionID, this.groupID === null ? 0 : this.groupID).subscribe(result => {
        if (result.attendance_permitted === 1) {
          this.workerService.getWorkers().subscribe(workers => {
            this.workers = workers.filter(w => !!w.enabled === true);
            this.absentees = this.workers;
            this.sessionService.getSession(this.sessionID, this.groupID === null ? 0 : this.groupID).subscribe(session => {
              this.session = session;

              // Add all workers according to shift and roster (prestarts only)
              if (!this.isIntergratedSignin && this.isPrestart(this.session)) {
                const shift = new Shift(null, this.session.shift_definition_id, this.session.shift_date);
                this.sessionWorkerService.createSessionWorkerByShift(shift, this.groupID).subscribe(sessionWorkers => {
                  sessionWorkers.forEach((sw) => {
                    const worker = this.workers.filter(w => w.id === sw.worker_id);
                    this.sessionWorkers.push(sw);
                    this.attendees.push(worker[0]);
                  });
                  this.absentees = this.absentees.filter(a => this.attendees.filter(at => at.id === a.id).length <= 0);
                  // Re-sort
                  this.attendees = this.attendees.sort((a, b) => a.surname.concat(' ', a.first_name)
                    .localeCompare(b.surname.concat(' ', b.first_name)));
                })
              }

              // Update attendance if integrated attendance system connected
              if (this.isIntergratedSignin && this.isPrestart(this.session)) {
                this.getIntegratedAttendance();
              } else {
                this.loadedWorkers = true;
              }
            });
          });
        } else if (result.attendance_permitted === 0) {
          // Re-direct if attendance already recorded or session not viewed
          this.router.navigate(['dashboard']);
        }
      });
    });
    this.crewService.getCrews().subscribe(crews => {
      this.crews = crews;
      this.mainCrews = crews.filter(c => c.priority === 1);
      this.supportCrews = crews.filter(c => c.priority !== 1);
    });
  }

  isPrestart(session: Session): boolean {
    return this.session.shift_date !== null;
  }

  attendeesTypeAhead(): Worker[] {
    let attendees = this.attendees;
    if (this.selectedCrewID > 0) {
      attendees = attendees.filter(a => a.crew_id === this.selectedCrewID);
    }
    return Worker.typeAhead(attendees, this.workerSearchTerm);
  }

  absenteesTypeAhead(): Worker[] {
    let absentees = this.absentees;
    if (this.selectedCrewID > 0) {
      absentees = absentees.filter(a => a.crew_id === this.selectedCrewID);
    }
    return Worker.typeAhead(absentees, this.workerSearchTerm);
  }

  addWorker(ID: number): void {
    const absentee = this.absentees.filter(a => a.id === ID);
    const sessionWorker = new SessionWorker(0, this.sessionID, absentee[0].id, 'SPS_MANUAL', this.groupID);

    this.sessionWorkerService.createSessionWorker(sessionWorker).subscribe(result => {
      if (result) {
        this.sessionWorkers.push(result);

        // Update list of absentees - Remove added worker
        this.absentees = this.absentees.filter(a => a.id !== ID);

        // Update list of attendees - Add added worker
        this.attendees.push(absentee[0]);
        this.attendees = this.attendees.sort((a, b) => a.surname.concat(' ', a.first_name)
          .localeCompare(b.surname.concat(' ', b.first_name)));
        Log.i('Session Worker Added');
      }
    });
  }

  removeWorker(ID: number): void {

    const sessionWorker = this.sessionWorkers.filter(sw => sw.worker_id === ID);

      if (Array.isArray(sessionWorker) && !sessionWorker.length) {
      return; // no users to remove
    }

    this.sessionWorkerService.deleteSessionWorker(sessionWorker[0].id).subscribe(result => {
      if (result) {
        this.sessionWorkers = this.sessionWorkers.filter(i => i.id !== sessionWorker[0].id);

        // Update list of absentees - Add removed worker
        this.absentees.push(this.attendees.filter(a => a.id === ID)[0]);
        this.absentees = this.absentees.sort((a, b) => a.surname.concat(' ', a.first_name)
          .localeCompare(b.surname.concat(' ', b.first_name)));

        // Update list of attendees - Remove removed worker
        this.attendees = this.attendees.filter(a => a.id !== ID);
        Log.i('Session Worker Removed');
      }
    });
  }

  addAll(workers: Worker[]) {
    this.addAllClicked = true;
    const absentees = workers;
    const sessionWorkers = [];

    absentees.forEach((item) => {
      const absentee = this.absentees.filter(a => a.id === item.id);
      sessionWorkers.push(new SessionWorker(0, this.sessionID, absentee[0].id, 'SPS_MANUAL', this.groupID));
    });

    this.sessionWorkerService.createSessionWorkers(sessionWorkers).subscribe(sessionWorkersResult => {
      this.sessionWorkers = sessionWorkersResult;
      // Remove all the added names for absentee list and add to the attendee list
      this.absenteesTypeAhead().forEach((worker) => {
        this.attendees.push(worker);
      });
      this.absentees = this.absentees.filter(a => this.absenteesTypeAhead().filter(at => at.id === a.id).length <= 0);

      // Re-sort
      this.attendees = this.attendees.sort((a, b) => a.surname.concat(' ', a.first_name)
        .localeCompare(b.surname.concat(' ', b.first_name)));

      this.addAllClicked = false;
    });

  }

  removeAll(workers: Worker[]) {
    this.removeAllClicked = true;
    const attendees = workers;
    const sessionWorkerDeleteIDs = [];
    attendees.forEach((item) => {
      const attendee = this.attendees.filter(a => a.id === item.id);
      if (attendee.length > 0) {
        sessionWorkerDeleteIDs.push(this.sessionWorkers.filter(sw => sw.worker_id === attendee[0].id)[0].id);
      }
    });

    this.sessionWorkerService.deleteSessionWorkers(sessionWorkerDeleteIDs).subscribe(_ => {
      attendees.forEach((worker) => {
        this.absentees.push(worker);
      });
      this.attendees = this.attendees.filter(a => attendees.filter(at => at.id === a.id).length <= 0);
      this.sessionWorkers = this.sessionWorkers.filter(sw => attendees.filter(at => at.id === sw.worker_id).length <= 0);

      // Re-sort
      this.absentees = this.absentees.sort((a, b) => a.surname.concat(' ', a.first_name)
        .localeCompare(b.surname.concat(' ', b.first_name)));

      this.removeAllClicked = false;
    });
  }

  updateFilter(crewID: number) {
    if (!crewID) {
      this.filter = null;
    } else {
      this.filter = this.crews.filter(c => c.id === crewID)[0].abbrev;
    }
    this.selectedCrewID = crewID;
  }

  completeRecording(): void {
    this.manualNavigation = true;
    // Record notes
    this.sessionService.updateSession(this.session).subscribe(result => {
      if (result) {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  completeGroupRecording(): void {
    if (this.next_groupID !== null) {
      if (this.next_groupID !== this.groupID) {
        if (this.workersLoggedIn) {
          this.workerService.getAbsentSessionWorkersBySessionID(this.sessionID, this.next_groupID).subscribe(workers => {
            const leaving = this.attendees.filter(w => !workers.filter(worker => worker.worker_id === w.id).length)
            const staying = this.attendees.filter(w => workers.filter(worker => worker.worker_id === w.id).length)
            this.dialogsService.workers("Workers Availability", staying, leaving).subscribe(res => {
              if (res) {
                this.manualNavigation = true;
                this.router.navigate(['/session', 'show', this.sessionID, this.next_groupID, 'present'])
              }
            });
          });
        } else {
          this.manualNavigation = true;
          this.router.navigate(['/session', 'show', this.sessionID, this.next_groupID, 'present'])
        }
      } else {
        this.completeRecording()
      }
    }
  }

  isMoreContent(): boolean {
    return this.next_groupID !== null;
  }

  getIntegratedAttendance() {
    if (!this.intergrationInProgess) {
      this.intergrationInProgess = true;
      this.workerService.getAbsentSessionWorkersBySessionID(this.sessionID, this.groupID === null ? 0 : this.groupID).subscribe(absent_workers => {
        this.integrationService.getAttendance(this.session.id, this.groupID === null ? 0 : this.groupID).subscribe(sessionWorkers => {
          this.sessionWorkers = sessionWorkers;
          if (sessionWorkers.length > 0) {
            this.workersLoggedIn = true;
            sessionWorkers.forEach((sw) => {
              this.attendees.push(this.workers.filter(w => w.id === sw.worker_id)[0]);
            });
            const removeWorkers = this.absentees.filter(w => !absent_workers.filter(worker => worker.worker_id === w.id).length)
            this.absentees = this.absentees.filter(a => this.attendees.filter(at => at.id === a.id).length <= 0)

            // remove workers no longer who free to leave
            if (this.groupID > 0) { // only remove workers when not the first group
              this.removeAll(removeWorkers)
            }
            this.loadedWorkers = true;
          } else {
            this.absentees = this.workers;
            this.attendees = []
            this.loadedWorkers = true;
            this.workersLoggedIn = true;
          }
        });
      });
    }
  }
}
