import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if (value < 900) {
      return value.toFixed() + " B";
    } else if (value < 900000) {
      return (value / 1000).toFixed() + " kB";
    } else if (value < 900000000) {
      return (value / 1000000).toFixed() + " MB";
    } else if (value < 900000000000) {
      return (value / 1000000000).toFixed() + " GB";
    }

    return value.toFixed() + " B";  // Fail-over
  }

}
