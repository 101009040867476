export class ReportFilter {
  constructor(public content_id: any = null,
              public compulsory: any = null,
              public category_id: any = null,
              public delivery_id: any = null,
              public session_type: any = null,
              public from_date: any = null,
              public to_date: any = null,
              public audience: any = null,
              public crew_id: any = null,
              public attendance: any = null,
              public worker_id: any = null,
              public prestart_type_name: any = null
  ) {}

  public static assign(rf: ReportFilter) {
    const n = Object.assign(new ReportFilter(), rf);

    return n;
  }
}

export class Pagination {
  constructor(public page: number,
              public count: number
  ) {}
}

export class ReportRequest {
  constructor(public report_type = '',
              public filters: ReportFilter = null,
              public pagination: Pagination = null
  ) {}
}

export class ReportResult {
  public request: ReportRequest;
  public columns: any[];
  public data: any[][];
  public length: number;
}
