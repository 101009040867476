import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {SessionWorker} from '../models/session_worker.model';
import {HttpClient} from '@angular/common/http';
import {Shift} from "../models/shift.model";
import {Log} from "../helpers/log.helper";

@Injectable()
export class SessionWorkerService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('SessionWorkerService Ready...');
  }

  getSessionWorkers(): Observable<SessionWorker[]> {
    return this.http.get<SessionWorker[]>(AppSettings.API_ENDPOINT + 'session_worker', this.options);
  }

  getSessionWorkersBySessionID(sessionID: number): Observable<SessionWorker[]> {
    return this.http.get<SessionWorker[]>(AppSettings.API_ENDPOINT + 'session/' + sessionID + '/workers', this.options);
  }

  getSessionWorker(sessionWorkerID: number): Observable<SessionWorker> {
    return this.http.get<SessionWorker>(AppSettings.API_ENDPOINT + 'session_worker/' + sessionWorkerID, this.options);
  }

  createSessionWorker(sessionWorker: SessionWorker): Observable<SessionWorker> {
    return this.http.post<SessionWorker>(AppSettings.API_ENDPOINT + 'session_worker', JSON.stringify(sessionWorker), this.options);
  }

  createSessionWorkers(sessionWorkers: SessionWorker[]) {
    return this.http.post<SessionWorker[]>(AppSettings.API_ENDPOINT + 'session_worker/bulk', JSON.stringify(sessionWorkers), this.options);
  }

    createSessionWorkerByShift(shift: Shift, groupID: number): Observable<SessionWorker[]> {
        const data: { shift_definition_id: any, shift_date: any, group: number } = {
            shift_definition_id: shift.shift_definition_id,
            shift_date: shift.shift_date,
            group: groupID
        };
        return this.http.post<SessionWorker[]>(AppSettings.API_ENDPOINT + 'session_worker/shift', JSON.stringify(data), this.options);
    }

  updateSessionWorker(sessionWorker: SessionWorker): Observable<SessionWorker> {
    return this.http.put<SessionWorker>(AppSettings.API_ENDPOINT + 'session_worker/' + sessionWorker.id, JSON.stringify(sessionWorker), this.options);
  }

  deleteSessionWorker(sessionWorkerID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'session_worker/' + sessionWorkerID, this.options);
  }

  deleteSessionWorkers(sessionWorkerDeleteIDs: number[]): Observable<any> {
    const body = JSON.stringify(sessionWorkerDeleteIDs);
    const deleteOptions = {headers: this.headers, body: body};
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'session_worker/bulk', deleteOptions);
  }
}
