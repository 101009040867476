<div class="container">
  <div class="row d-flex align-items-center">
    <div class="col-4 p-0">
      <h5>
        <ng-container *ngIf="session">
          <ng-container *ngIf="!session.title; else trainingTitle">
            <i class="mr-2">Shift Date:</i>
            {{session.shift_date | date: "dd MMMM yyyy"}}
          </ng-container>
          <ng-template #trainingTitle>
            <i class="mr-2">Title:</i>
            {{session.title}}
          </ng-template>
        </ng-container>
      </h5>
    </div>
    <div class="col-8 p-0 text-right">
      <button *ngIf="!isIntergratedSignin" mat-stroked-button class="mr-1">
        <img src="/assets/lineup_logo.png" height="25px" width="25px" class="mr-2">Import from Lineup Board</button>
      <ng-container *ngIf="!isMoreContent(); else groupAttendance">
        <button mat-stroked-button color="accent" (click)="completeRecording()">Record Attendance</button>
      </ng-container>
      <ng-template #groupAttendance>
        <button mat-stroked-button color="accent" (click)="completeGroupRecording()">Record Attendance & Present Next Content</button>
      </ng-template>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col-6 pl-0">
      <mat-form-field appearance="outline" class="w-100 no-subscript">
        <mat-label>Search Attendees</mat-label>
        <input matInput autocomplete="off" placeholder="Start typing a name.." [(ngModel)]="workerSearchTerm">
        <span matSuffix>
          <button mat-icon-button (click)="workerSearchTerm = ''">
            <mat-icon matTooltip="Clear">clear</mat-icon>
          </button>
        </span>
        <mat-icon class="mr-2" matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-6 p-0 d-flex align-items-center justify-content-around stretch-button-group">
      <mat-button-toggle-group [(ngModel)]="selectedCrewID" name="Crew" class="w-50">
        <mat-button-toggle (click)="updateFilter(0)" [value]="0">All</mat-button-toggle>
        <mat-button-toggle *ngFor="let mainCrew of mainCrews" (click)="updateFilter(mainCrew.id)" [value]="mainCrew.id">
          {{mainCrew.abbrev}}</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-form-field appearance="outline" class="w-50 no-subscript remove-left-border">
        <mat-label>Select Supporting Crews</mat-label>
        <mat-select [(ngModel)]="selectedCrewID" placeholder="Select Supporting Crews">
          <mat-option *ngFor="let supportCrew of supportCrews" (click)="updateFilter(supportCrew.id)"
            [value]="supportCrew.id">{{supportCrew.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
      <mat-form-field *ngIf="session" appearance="outline" class="w-100">
        <mat-label>Add Note</mat-label>
        <textarea matInput placeholder="Start typing.." [(ngModel)]="session.comment"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-6 pl-0">
      <div class="card pt-0 border-danger">
        <div class="card-body">
          <div class="row d-flex justify-content-between align-items-center pb-2">
            <p>Please click on name to add attendees</p>
            <div class="ml-auto">
              <button mat-button [disabled]="addAllClicked" (click)="addAll(absenteesTypeAhead())">Add All</button>
            </div>
          </div>
          <div class="p-0">
            <ng-container *ngIf="loadedWorkers; else loadingAttendees">
            <ng-container *ngIf="absenteesTypeAhead().length > 0;else noSelectedAbsentees">
              <mat-chip-listbox>
                <mat-chip *ngFor="let worker of absenteesTypeAhead()" selected (click)="addWorker(worker.id)">
                    <span>
                  <mat-icon class="text-danger">remove_circle</mat-icon>
                  {{worker.first_name}} {{worker.surname}}
                        </span>
                </mat-chip>
              </mat-chip-listbox>
            </ng-container>
            </ng-container>
            <ng-template #noSelectedAbsentees>
              No workers available for current selected filters
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 px-0">
      <div class="card pt-0 border-success">
        <div class="card-body">
          <div class="row d-flex justify-content-between align-items-center pb-2">
            <p>Please click on name to remove attendees</p>
            <div class="ml-auto">
              <button mat-button [disabled]="removeAllClicked" (click)="removeAll(attendeesTypeAhead())">Remove All</button>
            </div>
          </div>
          <div class="p-0">
            <mat-chip-listbox>
              <ng-container *ngIf="loadedWorkers; else loadingAttendees">
              <ng-container *ngIf="attendeesTypeAhead().length > 0;else noSelectedAttendees">
                <mat-chip *ngFor="let attendee of attendeesTypeAhead()" (click)="removeWorker(attendee.id)">
                    <span>
                      <mat-icon class="text-success">check_circle</mat-icon>
                      {{attendee.first_name}} {{attendee.surname}}
                    </span>
                </mat-chip>
              </ng-container>
              </ng-container>
            </mat-chip-listbox>
            <ng-template #noSelectedAttendees>
              No attendees, please add attendees from the list
            </ng-template>
            <ng-template #loadingAttendees>
              <mat-spinner color="warn"></mat-spinner>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
