import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { handleFileDrop, onFileDrop } from "../../../helpers/file.helper";
import { ComponentPortal } from "@angular/cdk/portal";
import { FileUploadHintComponent } from "../file-upload-hint/file-upload-hint.component";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { FileService } from "../../../services/file.service";
import { Router } from "@angular/router";
import { Content } from "../../../models/content.model";

@Component({
    selector: "app-file-upload-target",
    templateUrl: "./file-upload-target.component.html",
    styleUrls: ["./file-upload-target.component.scss"]
})
export class FileUploadTargetComponent implements OnInit {

    @Input() isOnContentCreate = false;
    @Input() currentContent: Content;
    @Input() currentFile: File;

    @Output('currentContentChange') contentChange = new EventEmitter<Content>();
    @Output('currentFileChange') fileChange = new EventEmitter<File>();

    currentDragTarget = null;

    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay,
                private fileService: FileService,
                private router: Router) {
    }

    ngOnInit(): void {
    }

    @HostListener("document:dragleave", ["$event"]) onDragLeave(event: any): void {
        if (this.currentDragTarget === event.target) {
            event.preventDefault();
            event.stopPropagation();
            this.hide()
        }
    }

    @HostListener("document:dragenter", ["$event"]) onDragEnter(event: any): void {
        this.currentDragTarget = event.target;
        event.preventDefault();
        event.stopPropagation();
        if (!this.overlayRef) {
            this.show();
        }
    }

    @HostListener("document:dragover", ["$event"]) onDragOver(event: any): void {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('document:drop', ['$event']) onDrop(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.hide();
        if (!this.isOnContentCreate) {
            onFileDrop(event, this.fileService);
            this.router.navigate(['content/create']).then();
        } else {
            const handleFileDropResult = handleFileDrop(event.dataTransfer.files, this.currentFile, this.currentContent);
            this.fileChange.emit(handleFileDropResult.file);
            this.contentChange.emit(handleFileDropResult.content);
            this.removeDragData(event);
        }
    }

    show() {
        this.overlayRef = this.overlay.create({
            width: "100%",
            height: "100%",
        });
        const componentPortal = new ComponentPortal(FileUploadHintComponent);
        this.overlayRef.addPanelClass("file-upload-hint-overlay");
        this.overlayRef.attach(componentPortal);
    }

    hide() {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
        this.overlayRef = null;
    }

    removeDragData(event: any) {
        if (event.dataTransfer.items) {
            event.dataTransfer.items.clear();
        } else {
            event.dataTransfer.clearData();
        }
    }

}
