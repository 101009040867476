import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileService, FileUploadStatus} from '../../../services/file.service';
import {Titlepage} from '../../../models/titlepage.model';
import {TitlepageService} from '../../../services/titlepage.service';
import {Log} from '../../../helpers/log.helper';
import {environment as env} from '../../../../environments/environment';
import {AppSettings} from '../../../app.settings';

@Component({
  selector: 'app-create-titlepage',
  templateUrl: './create-titlepage.component.html',
  styleUrls: ['./create-titlepage.component.scss'],
})
export class CreateTitlepageComponent implements OnInit {

  titlepage: Titlepage = new Titlepage();

  file: File = null;
  hasUploadStarted = false;
  totalFileSize = 0;
  currentBytesUploaded: { [fileName: string]: number } = {};

  constructor(private titlepageService: TitlepageService,
              private fileService: FileService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

  handleFileInput(files: FileList) {
    this.file = files.item(0);
  }

  getValidFileSize(): boolean {
    return(this.fileService.validateFile(this.file));
  }

  // Checks file is type PDF
  getValidFileType(): boolean {
    if (!this.file) {
      return null;
    } else if (!this.file.type.localeCompare('application/pdf')) {
      return true;
    }
    return false;
  }

  getMaxFileSizeText(): number {
    return Math.round(env.httpSettings.maxUploadSize / 1000 / 1000);
  }

  getCurrentUploadBytesCompleted(): number {

    let totalBytes = 0;

    for (const fileName in this.currentBytesUploaded) {
      totalBytes += this.currentBytesUploaded[fileName];
    }

    return totalBytes;
  }

  getCurrentUploadPercentCompleted(): number {
    const calcValue = Math.round(this.getCurrentUploadBytesCompleted() / this.totalFileSize * 100);
    // This is a hack during to the usual float rounding issues
    return calcValue > 100 ? 100 : calcValue;
  }

  saveTitlepageFile(): void {
      const fileUploadHandler = (progress: FileUploadStatus) => {
        if (progress) {
          if (!this.currentBytesUploaded.hasOwnProperty(progress.fileName)) {
            this.currentBytesUploaded[progress.fileName] = 0;
          }

          this.currentBytesUploaded[progress.fileName] = progress.bytesUploaded;

          if (this.getCurrentUploadPercentCompleted() === 100 && progress.content_file_id) {
            this.titlepage.content_file_id = progress.content_file_id;

            this.titlepageService.createTitlepage(this.titlepage).subscribe(_ => {
              setTimeout(() => {

                this.router.navigate(['/content-management'], { queryParams: { created: true } }).then(() => {

                  this.currentBytesUploaded = {};
                  this.hasUploadStarted = false;
                  this.totalFileSize = 0;
                  this.file = null;
                }, (e) => {
                  // TODO: Add proper error handling
                });
              }, 1500);
            });
          }
        }
      };

      this.hasUploadStarted = true;
      this.totalFileSize = this.file.size;
      this.fileService.upload(AppSettings.API_ENDPOINT + 'content_file', this.file, 1).subscribe(
        fileUploadHandler,
        (e) => {
          Log.error(e);
        });
  }

  onDelete() {
    this.titlepageService.deleteTitlepage(this.titlepage.id).subscribe(res => {
      if (res) {
        Log.i('Titlepage Deleted');
        this.router.navigate(['/content-management']);
      }
    })
  }

  checkCreateInvalid() {
    return (!this.file || !this.getValidFileSize() || !this.getValidFileType());
  }
}
