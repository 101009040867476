import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Session, SessionShiftRequest} from '../../../models/session.model';
import {Content, ContentSchedule, ContentShiftRequest} from '../../../models/content.model';
import {SessionContent} from '../../../models/session_content.model';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../services/session.service';
import {Log} from '../../../helpers/log.helper';
import {ContentService} from '../../../services/content.service';
import {SessionContentService} from '../../../services/session_content.service';
import {AppSettings} from '../../../app.settings';
import {ShiftDefinitionService} from '../../../services/shift_definition.service';
import {ShiftDefinition} from '../../../models/shift_definition.model';
import {DatePipe, formatDate} from '@angular/common';
import {MatSort} from '@angular/material/sort';
import {Category} from '../../../models/category.model';
import {CategoryService} from '../../../services/category.service';
import {UserService} from '../../../services/user.service';
import {UserName} from '../../../models/user.model';
import {ContentFileService} from '../../../services/content_file.service';
import {ContentFile} from '../../../models/content_file.model';
import {Delivery} from '../../../models/delivery.model';
import {DeliveryService} from 'src/app/services/delivery.service';
import {Titlepage} from '../../../models/titlepage.model';
import {TitlepageService} from '../../../services/titlepage.service';
import {AuthenticationService} from "../../../services/authentication.service";
import {PrestartTypeService} from '../../../services/prestart_type.service';
import {PrestartType} from '../../../models/prestart_type.model';
import {ContentCoverageCrew} from "../../../models/coverage.model";
import {CoverageService} from "../../../services/coverage.service";


@Component({
    selector: 'app-create-session',
    templateUrl: './create-session.component.html',
    styleUrls: ['./create-session.component.scss']
})
export class CreateSessionComponent implements OnInit, OnDestroy {

    session: Session;
    sessionContents: SessionContent[] = [];
    slides: Content[] = [];
    slideGroups: Map<number, number> = new Map()
    contents: Content[] = [];
    allContents: Content[] = [];
    validContents: Content[] = [];
    contentSchedules: ContentSchedule[] = [];
    deliverys: Delivery[] = [];
    categorys: Category[] = [];
    userNames: UserName[] = [];
    shiftDefinitions: ShiftDefinition[];
    shiftDefinitionAbbrev = '';
    sessionID: number;
    isPrestartSession = true;
    viewType: string;
    shiftDate: any;
    minDate: Date;
    validShift = '';
    titlepage: Titlepage;
    isTitlepage = false;
    contentID = null;
    contentInitialised = false;
    trainingEdit = false;
    shiftDefinitionID: number = null;
    prestartTypes: PrestartType[] = [];
    defaultPrestartType: PrestartType = null;
    checkSmartSplit = false;

    // Table parameters
    @ViewChild(MatSort) sort: MatSort;
    filterValues = {
        title: '',
        categoryID: null,
        userID: null,
        showArchived: false
    };
    contentCoverageCrews: ContentCoverageCrew[];
    slidesInitialised = false;

    constructor(public router: Router,
                private sessionService: SessionService,
                private contentService: ContentService,
                private contentFileService: ContentFileService,
                private categoryService: CategoryService,
                private userService: UserService,
                private sessionContentService: SessionContentService,
                private shiftDefinitionService: ShiftDefinitionService,
                private deliveryService: DeliveryService,
                private route: ActivatedRoute,
                private datePipe: DatePipe,
                private titlepageService: TitlepageService,
                private authService: AuthenticationService,
                private prestartTypeService: PrestartTypeService,
                private coverageService: CoverageService,
    ) {
    }

    ngOnDestroy() {

    }

    ngOnInit() {

        this.minDate = new Date(Date.now());
        this.minDate.setHours(this.minDate.getHours() - 6);

        this.shiftDate = this.datePipe.transform(new Date(this.minDate.getFullYear(),
            this.minDate.getMonth(), this.minDate.getDate() + 1), 'yyyy-MM-dd');

        this.shiftDefinitionID = null;
        this.route.queryParams.subscribe(queryParams => {
            if (queryParams.date) {
                this.shiftDate = queryParams.date;
                this.shiftDefinitionID = queryParams.shift
            }
        });

        this.deliveryService.getDeliverys().subscribe(deliverys => {
            this.deliverys = deliverys;
        });

        this.session = new Session();
        this.session.is_editable = true;

        this.userService.getUserNames().subscribe(userNames => {
            this.userNames = userNames;
            this.categoryService.getCategorys().subscribe((categorys: Category[]) => {
                this.categorys = categorys;

                this.route.params.subscribe(params => {
                    this.sessionID = params['id'];
                    this.viewType = params['type'];

                    if (this.sessionID) {
                        this.validShift = null;

                        this.prestartTypeService.getPrestartTypes().subscribe(prestartTypes => {
                            this.prestartTypes = prestartTypes.filter(pt => !!pt.is_active === true);
                        });
                        this.sessionService.getSession(this.sessionID, 0).subscribe(session => {
                            this.session = session;
                            if (this.session.titlepage_id) {
                                this.isTitlepage = true;
                                this.titlepageService.getTitlepages().subscribe(titlepages => {
                                    this.titlepage = titlepages.filter(t => t.id === this.session.titlepage_id)[0];
                                });
                            } else {
                                this.isTitlepage = false;
                            }

                            // Check if training
                            if (session.title.length < 1) {
                                this.isPrestartSession = true;
                                if (this.viewType !== 'copy') {
                                    this.shiftDate = this.datePipe.transform(this.session.shift_date, 'yyyy-MM-dd');
                                }
                                this.shiftDefinitionService.getShiftDefinitions().subscribe(shiftDefinitions => {
                                    this.shiftDefinitions = shiftDefinitions;
                                    if (shiftDefinitions.filter(sd => sd.id === session.shift_definition_id).length > 0) {
                                        this.shiftDefinitionAbbrev = shiftDefinitions.filter(sd => sd.id === session.shift_definition_id)[0].abbrev;
                                    } else {
                                        this.shiftDefinitionAbbrev = null;
                                    }
                                });
                            } else {
                                this.shiftDefinitionService.getShiftDefinitions().subscribe((shiftDefinitions: ShiftDefinition[]) => {
                                    this.shiftDefinitions = shiftDefinitions;
                                });
                                this.isPrestartSession = false;
                            }

                            this.sessionContentService.getSessionContents().subscribe(sessionContents => {
                                this.sessionContents = sessionContents.filter(sc => sc.session_id === session.id);
                                if (this.sessionContents.length > 1) {
                                    this.sessionContents.sort((a, b) => a.ordinal - b.ordinal);
                                }

                                this.contentService.getContents().subscribe((contents: Content[]) => {
                                    this.allContents = contents;
                                    this.contentService.getValidApprovedContents(new ContentShiftRequest(this.session.shift_date, this.session.shift_definition_id)).subscribe((validContents: Content[]) => {
                                        this.validContents = validContents;
                                        this.coverageService.getCoverageShift(this.session.shift_date, this.session.shift_definition_id).subscribe(contentCoverageCrews => {
                                            this.contentCoverageCrews = contentCoverageCrews;
                                        });
                                        if (validContents) {
                                            this.contentInitialised = true;
                                        }
                                        this.contents = this.validContents;
                                        this.updateSlides();
                                        this.slidesInitialised = true;
                                        if (this.isPrestartSession) {
                                            if (this.viewType === 'copy') {
                                                this.session.shift_date = formatDate(this.shiftDate, 'yyyy-MM-dd', 'en');
                                                this.session.shift_date = this.shiftDate;
                                                this.checkValidShift();
                                                this.session.id = null;
                                                this.sessionID = null;
                                            } else {
                                                this.session.shift_date = formatDate(this.shiftDate, 'yyyy-MM-dd', 'en');
                                            }
                                            this.contentService.getPresortedContents(new ContentShiftRequest(this.session.shift_date, this.session.shift_definition_id)).subscribe(contentSchedules => {
                                                this.contentSchedules = contentSchedules;
                                            });
                                        }
                                    });
                                });
                            });
                        });
                    } else {
                        this.prestartTypeService.getPrestartTypes().subscribe(prestartTypes => {
                            this.prestartTypes = prestartTypes.filter(pt => !!pt.is_active === true);
                            this.defaultPrestartType = this.prestartTypes.reduce((res, obj) => {
                                return (!!res.is_active === false || Math.abs(obj.ordinal) < Math.abs(res.ordinal)) ? obj : res;
                            });
                            this.session.prestart_type_id = this.defaultPrestartType.id;
                        });

                        this.titlepageService.getTitlepages().subscribe(titlepages => {

                            this.titlepage = titlepages.filter(t => t.state === 'Active')[0];
                            if (this.isTitlepage && this.titlepage) {
                                this.session.titlepage_id = titlepages.filter(t => t.state === 'Active')[0].id;
                            } else {
                                this.session.titlepage_id = null;
                            }

                            this.shiftDefinitionService.getShiftDefinitions().subscribe((shiftDefinitions: ShiftDefinition[]) => {
                                this.shiftDefinitions = shiftDefinitions;
                                const shiftDefinition = this.shiftDefinitions.filter(sd => sd.name.toLowerCase() === 'day')[0]; // Default to day shift
                                if (this.shiftDefinitionID == null) {
                                    this.session.shift_definition_id = shiftDefinition.id;
                                } else {
                                    this.session.shift_definition_id = this.shiftDefinitions.filter(sd => sd.id === this.shiftDefinitionID)[0].id;
                                }
                                this.shiftDefinitionAbbrev = shiftDefinition.abbrev;
                                this.session.shift_date = formatDate(this.shiftDate, 'yyyy-MM-dd', 'en');
                                this.checkValidShift();
                                this.getContent();
                            });
                        });
                    }
                });
            });
        });
    }

    updateTitlePage() {
        if (this.isTitlepage && this.titlepage) {
            this.session.titlepage_id = this.titlepage.id;
        } else {
            this.session.titlepage_id = null;
        }
    }

    getContent() {
        this.contentService.getValidApprovedContents(new ContentShiftRequest(this.session.shift_date, this.session.shift_definition_id)).subscribe((validContents: Content[]) => {
            this.validContents = validContents;
            this.contents = this.validContents;
            this.contentInitialised = true;
            this.coverageService.getCoverageShift(this.session.shift_date, this.session.shift_definition_id).subscribe(contentCoverageCrews => {
                this.contentCoverageCrews = contentCoverageCrews;
            });
            this.updateSlides();
            if (this.isPrestartSession) {
                this.contentService.getPresortedContents(new ContentShiftRequest(this.session.shift_date, this.session.shift_definition_id)).subscribe(contentSchedules => {
                    this.contentSchedules = contentSchedules;
                    if (this.viewType !== 'edit') {
                        this.slides = this.validContents.filter(c => contentSchedules.filter(cs => cs.content_id === c.id).length > 0);
                        this.contents = this.validContents.filter(c => contentSchedules.filter(cs => cs.content_id === c.id).length < 1);
                    }
                    this.slidesInitialised = true;
                });
            }
        });
    }

// Filtering
    updateFilters(): Content[] {
        let contents = this.contents;
        contents = Content.typeAhead(contents, this.filterValues.title);
        if (this.filterValues.categoryID) {
            contents = contents.filter(c => c.category_id === this.filterValues.categoryID);
        }
        if (this.filterValues.userID) {
            contents = contents.filter(c => c.user_id === this.filterValues.userID);
        }
        if (!this.filterValues.showArchived) {
            contents = contents.filter(c => c.archive_date === null);
        }

        // make sure the Content Library doesn't get populated with content that is already in the Prestart Content section
        const slideIds = this.slides.map(s => s.id); // get Ids of the slides in the Prestart Content
        contents = contents.filter(c => !slideIds.includes(c.id)); // filter out content already in Prestart Content

        return contents
    }

    getCategory(ID: number): string {
        return (this.categorys.filter(cg => cg.id === this.contents.filter(c => c.id === ID)[0].category_id)[0].title);
    }

    getUser(ID: number): string {
        return (this.userNames.filter(u => u.id === this.contents.filter(c => c.id === ID)[0].user_id)[0].name);
    }

// Moves an item by one index in an array in a selected direction
    private moveItemInList(items: Content[], fromIndex: number, dirUp: boolean): Content[] {
        const delta = dirUp ? -1 : 1;
        const from = this.clamp(fromIndex, items.length - 1);
        const to = this.clamp(from + delta, items.length - 1);

        const target = items[from];
        items[from] = items[to];
        items[to] = target;

        if (this.slideGroups.size !== this.slides.length) {
            const actual_slides = this.slides.filter(slide => slide['id'])
            this.contentService.getSmartSplitContents(actual_slides).subscribe(slides => {
                for (const slide of slides) {
                    this.slideGroups.set(slide.id, slide.group)
                    if ((to !== 0 && (this.isNewGroup(to))) || this.isNewGroup(from)) {
                        this.changeGroup([to, from]);
                    }
                }
            })
        } else if ((to !== 0 && (this.isNewGroup(to))) || this.isNewGroup(from)) {
            this.changeGroup([to, from]);
        }

        return (items);
    }

    /** Clamps a number between zero and a maximum. */
    private clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }

    addSlides(ID: number): void {
        const slide = this.contents.filter(c => c.id === ID);
        this.slides.push(slide[0]);
        this.contents = this.contents.filter(c => c.id !== ID);
        this.checkSmartSplit = false;
    }

    removeSlides(ID: number): void {
        // get content object that was removed
        const content = this.slides.find(s => s.id === ID);
        // check if the content is already in the contents array and add to the contents array if not there, this
        // cause the Content Library to update and put the removed content there
        if (!this.contents.some(c => c.id === content.id)) {
            this.contents.push(content);
        }
        this.slides = this.slides.filter(s => s.id !== ID);
        this.checkSmartSplit = false;
    }

    // Used for content preview
    slideFirstPage(contentFile: ContentFile): string {
        if (!contentFile || contentFile.page_count < 1) {
            return null;
        }
        return (AppSettings.API_ENDPOINT + 'content_file/' + contentFile.id + '/page/' +
        1 + '/' + 'webp' + '/' + 800 + (AppSettings.INTEGRATED_AUTHENTICATION ? "" : "?token=" + this.authService.getToken()));
    }

    getFilename(contentFile: ContentFile): string {
        return contentFile.filename;
    }

    getTitlepage(): string {
        return (AppSettings.API_ENDPOINT + 'content_file/' + this.titlepage.content_file.id + '/page/' + 0 + '/webp/' + 800 +
            (AppSettings.INTEGRATED_AUTHENTICATION ? "" : "?token=" + this.authService.getToken()));
    }

    saveSession(): void {
        this.save(false);
    }

    saveAndShowSession(): void {
        this.save(true);
    }

    save(show: boolean): void {
        switch (this.viewType) {
            case 'edit':
                this.sessionService.updateSession(this.session).subscribe(session => {
                    let sessionContentCount = 0;
                    if (this.sessionContents.length > 0) {
                        this.sessionContents.forEach((sc) => {
                            this.sessionContentService.deleteSessionContent(sc.id).subscribe(res => {
                                Log.i('SessionContent deleted');
                                if (res) {
                                    sessionContentCount++;
                                }
                                this.sessionContentCreation(sessionContentCount, session, show)
                            });
                        });
                    } else {
                        // creation session even if somehow the current session has no slides
                        this.sessionContentCreation(sessionContentCount, session, show)
                    }
                });
                break;
            case 'copy':
            default:
                this.sessionService.createSession(this.session).subscribe(session => {
                    for (let i = 0; i < this.slides.length; i++) {
                        const sessionContent = new SessionContent(0, session.id, this.slides[i].id, this.slides[i].group, i);
                        this.sessionContentService.createSessionContent(sessionContent).subscribe(() => {
                        });
                    }
                    if (show) {
                        this.router.navigate(['/session', 'show', session.id, 'preview']);
                    } else {
                        this.router.navigate(['/session/library']);
                    }
                });
                break;
        }
    }

    sessionContentCreation(sessionContentCount: number, session: Session, show: boolean) {
        if (sessionContentCount > this.sessionContents.length - 1) {
            let prevGroup = null
            let group = -1
            this.slides.forEach((s, idx) => {
                if (s.group === prevGroup) {
                    s.group = prevGroup;
                } else {
                    prevGroup = s.group;
                    group += 1;
                    s.group = group;
                }
                const sessionContent = new SessionContent(0, session.id, s.id, s.group, idx);
                this.sessionContentService.createSessionContent(sessionContent).subscribe(() => {
                    Log.i('SessionContent created');
                    if (show) {
                        this.router.navigate(['/session', 'show', session.id, 'preview']);
                    } else {
                        this.router.navigate(['/session/library']);
                    }
                });
            });
        }
    }

    updateSessionType() {
        // Remove shift and date if training session and title if prestart
        if (this.isPrestartSession) {
            this.session.title = '';
            this.session.shift_definition_id = this.shiftDefinitions.filter(sd => sd.name.toLowerCase() === 'day')[0].id; // Default to day shift
            // TODO remember the last prestart type id selected, instead of defaulting to one
            this.session.prestart_type_id = 1;
            this.updateShiftDate();
        } else {
            this.session.shift_date = null;
            this.session.shift_definition_id = null;
            this.session.prestart_type_id = null;
            this.validShift = null;
            this.updateContent()
        }
    }

    updateShiftDate() {
        this.session.shift_date = formatDate(this.shiftDate, 'yyyy-MM-dd', 'en');
        if (this.isPrestartSession) {
            this.checkValidShift()
            this.updateContent();
            for (const slide of this.slides) {
                if ((new Date(slide.expiry) < this.shiftDate && slide.expiry !== null)
                    || (slide.shift_definition_id !== this.session.shift_definition_id && slide.shift_definition_id !== null)) {
                    this.slides.splice(this.slides.indexOf(slide), 1); // remove from slides if content expire on past date
                }
            }
            if (this.trainingEdit) {
                this.updateSlides();
                this.trainingEdit = false;
            }
        } else {
            this.validShift = null;
            if (this.viewType === 'edit') {
                this.trainingEdit = true;
            }
        }
    }

    updateContent() {
        this.contentInitialised = false
        this.contentService.getValidApprovedContents(new ContentShiftRequest(this.session.shift_date, this.session.shift_definition_id)).subscribe((validContents: Content[]) => {
            this.validContents = validContents;
            this.contents = this.validContents;
            this.contentInitialised = true;
            this.coverageService.getCoverageShift(this.session.shift_date, this.session.shift_definition_id).subscribe(contentCoverageCrews => {
                this.contentCoverageCrews = contentCoverageCrews;
            });
        });
    }

    checkValidShift() {
        this.sessionService.checkSessionValidShift(new SessionShiftRequest(formatDate(this.session.shift_date, 'yyyy-MM-dd', 'en'),
            this.session.shift_definition_id, this.session.prestart_type_id)).subscribe(validShift => {
            if (validShift.status && validShift.status !== this.session.id) {
                this.validShift = validShift.status;
            } else {
                this.validShift = null;
            }
        });
    }

    updateSlides() {
        this.slides = [];
        this.sessionContents.forEach((sc) => {
            if (sc.content_id === null || this.allContents.filter(c => c.id === sc.content_id).length > 0) {
                let slide: Content;
                if (this.session.title.length > 1) {  // if training session
                    slide = this.allContents.filter(c => c.id === sc.content_id)[0]
                } else {
                    slide = this.allContents.filter(c => c.id === sc.content_id && (c.shift_definition_id === this.session.shift_definition_id || c.shift_definition_id === null))[0]
                }
                if (slide) {
                    slide = slide ? slide : new Content()
                    slide.group = sc.group
                    this.slides.push(slide);
                }
                this.contents = this.contents.filter(c => c.id !== sc.content_id);
            }
        });

        // If Smart Split, need to update groups if something has been presented/different people have logged in
        const uniqueGroups = this.slides.filter((slide, index, self) => index === self.findIndex((s) => (s.group === slide.group))).length
        if (uniqueGroups > 1) {
            const actual_slides = this.slides.filter(slide => slide['id'])
            this.contentService.getSmartSplitContents(actual_slides).subscribe(slides => {
                for (const slide of slides) {
                    this.slideGroups.set(slide.id, slide.group)
                    this.slides[this.slides.findIndex((slide_) => slide_.id === slide.id)].group = slide.group
                }
            });
        }
    }

    checkInvalid(): boolean {
        return ((this.isPrestartSession && (!this.session.shift_date || !this.session.shift_definition_id || this.slides.length < 1 || !(this.validShift === null))) ||
            (!this.isPrestartSession && (this.session.title.length < 1 && this.slides.length < 1)));
    }

    checkReset(): boolean {
        return this.contentSchedules.length === 0;
    }

    contentDelivery(deliveryID: number): Delivery {
        return this.deliverys.filter(d => d.id === deliveryID)[0];
    }

    isMandatoryContent(ID: number): boolean {
        if (!this.isPrestartSession) {
            return false;
        }
        if (this.contentSchedules.length < 1) {
            return false;
        }

        const contentSchedules = this.contentSchedules.filter(cs => cs.content_id === ID);
        return (contentSchedules.length > 0 && contentSchedules[0].is_mandatory);
    }

    multiplePrestartTypesActive() {
        return this.prestartTypes.filter(pt => !!pt.is_active === true).length > 1;
    }

    getCoverage(ID: number) {
        if (this.contentCoverageCrews) {
            const coverage = this.contentCoverageCrews.filter(ccc => ccc.content_id === ID)[0];
            if (!coverage) {
                return null;
            } else {
                return coverage.attendance_percent_by_required;
            }
        }
        return null;
    }

    canSmartSplit(): boolean {
        return (this.slides.length > 1)
    }

    isSmartSplit(): boolean {
        return this.slides.filter((slide, index, self) => index === self.findIndex((s) => (s.group === slide.group))).length > 1
    }

    smartSplitContent() {
        const actual_slides = this.slides.filter(slide => slide['id'])
        this.contentService.getSmartSplitContents(actual_slides).subscribe(slides => {
            // here need to check slides and move back if not needed
            const removeSlides = this.slides.filter(s => !slides.filter(ns => ns.id === s.id).length);
            for (const slide of removeSlides) {
                this.removeSlides(slide.id);
            }
            this.slides = slides;
            for (const slide of slides) {
                this.slideGroups.set(slide.id, slide.group)
            }
        })
        this.checkSmartSplit = true;
    }

    resetContent() {
        this.checkSmartSplit = false;
        for (const slide of this.slides) {
            slide.group = null;
        }
    }

    changeGroup(indexes: number[]) {
        // loop through the moved slides
        for (const index of indexes) {
            let foundGroup = false
            const groupCount = this.slides.filter(slide_ => slide_.group === this.slides[index].group).length
            const slide = this.slides[index] // slides moved

            if (typeof this.slides[index - 1] !== 'undefined') {  // has a slide above
                if (this.slideGroups.get(this.slides[index - 1].id) === this.slideGroups.get(slide.id)) {
                    this.slides[index].group = this.slides[index - 1].group // slide above belongs to same group
                    foundGroup = true
                }
            }

            if (typeof this.slides[index + 1] !== 'undefined' && !foundGroup) {  // has slide below
                if (this.slideGroups.get(this.slides[index + 1].id) === this.slideGroups.get(slide.id)) {
                    this.slides[index].group = this.slides[index + 1].group // slide below belongs to same group
                    foundGroup = false;
                }
            }

            if (!foundGroup && groupCount > 1) { // between slides, and will need a new group
                this.checkSmartSplit = false;
                slide.group = this.slides.reduce((a, b) => Math.max(a, b.group), 0) + 1
            }
        }
    }

    isNewGroup(index: number) {
        if (typeof this.slides[index - 1] !== 'undefined') {
            return this.slides[index].group !== this.slides[index - 1].group;
        }
    }

    isBestSplit() {
        let groupPosition = -1;
        let prevGroup;
        const groupIDs = {};
        const uniqueGroups = Array.from(this.slideGroups.values()).filter((group, index, array) => array.indexOf(group) === index);
        for (const [key, value] of this.slideGroups.entries()) {
            if (!groupIDs[value]) {
                groupIDs[value] = []
            }
            groupIDs[value].push(key)
        }


        for (const group of uniqueGroups) {
            groupPosition += groupIDs[group].length
            prevGroup = null;
            for (const ID of groupIDs[group]) {
                const index = this.slides.findIndex(slide => slide.id === ID)
                if (index !== -1) {
                    if (index > groupPosition) { // out of position
                        return false;
                    }

                    if (prevGroup === null) {
                        prevGroup = this.slides[index].group;
                    } else if (this.slides[index].group !== prevGroup) { // incorrect group
                        return false;
                    }
                }
            }
        }
        return true;
    }
}
