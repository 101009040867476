import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AppSettings} from '../app.settings';
import {CoreService} from './core.service';
import {AuthenticationService} from './authentication.service';
import {User, UserName} from '../models/user.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserService extends CoreService {
  constructor(protected auth: AuthenticationService, protected http: HttpClient) {
    super(auth);
    console.log('UserService Ready...');
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(AppSettings.API_ENDPOINT + 'user', this.options);
  }

  getUserNames(): Observable<UserName[]> {
    return this.http.get<UserName[]>(AppSettings.API_ENDPOINT + 'user/names', this.options);
  }

  getUser(userID: number): Observable<User> {
    return this.http.get<User>(AppSettings.API_ENDPOINT + 'user/' + userID, this.options);
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(AppSettings.API_ENDPOINT + 'user', JSON.stringify(user), this.options);
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(AppSettings.API_ENDPOINT + 'user/' + user.id, JSON.stringify(user), this.options);
  }

  deleteUser(userID: number): Observable<any> {
    return this.http.delete<any>(AppSettings.API_ENDPOINT + 'user/' + userID, this.options);
  }

  patchUser(userID: number, patches: any) {
    return this.http.patch<User>(AppSettings.API_ENDPOINT + 'user/' + userID, JSON.stringify(patches), this.options);
  }
}
